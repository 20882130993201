export default function AlertsIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
            <path
                d="M6.71163 0.942948C7.05744 0.352352 7.94256 0.35235 8.28837 0.942946L14.8825 12.205C15.2217 12.7843 14.786 13.5 14.0942 13.5H0.905846C0.214039 13.5 -0.221686 12.7843 0.11748 12.205L6.71163 0.942948Z"
                fill="#B86C49"
            />
            <path
                d="M7.01873 9.63309L6.83782 5.47942H8.10416L7.92326 9.63309H7.01873ZM7.47099 11.849C7.2539 11.849 7.07601 11.7794 6.93732 11.6404C6.79863 11.5014 6.72928 11.3305 6.72928 11.1277C6.72928 10.925 6.79863 10.7541 6.93732 10.615C7.07601 10.476 7.2539 10.4065 7.47099 10.4065C7.68808 10.4065 7.86597 10.476 8.00466 10.615C8.14336 10.7541 8.21271 10.925 8.21271 11.1277C8.21271 11.3305 8.14336 11.5014 8.00466 11.6404C7.86597 11.7794 7.68808 11.849 7.47099 11.849Z"
                fill="white"
            />
        </svg>
    );
}
