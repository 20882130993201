import React from "react";

const TrendCard = ({ title, value, percentage, icon }) => {
    return (
        <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex gap-5 justify-between items-start pt-2 pr-2.5 pb-6 pl-5 mt-8 w-full rounded-lg border border-solid border-stone-200 max-md:mt-10">
                <div className="flex justify-between items-start mt-2.5">
                    <div className="flex flex-col w-[99px]">
                        <div className="flex gap-2 items-center w-full leading-none whitespace-nowrap">
                            <div className="self-stretch my-auto text-3xl text-black">{value}</div>
                            <div className="flex gap-0.5 justify-center items-center self-stretch px-1 py-0.5 my-auto text-xs text-blue-500 border border-blue-500 border-solid bg-blue-500 bg-opacity-10 rounded-[34px]">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5caf3258c58ee79af695a413135c56b37a3b42f5559ee7e59e71df7933ee4801?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                                    className="object-contain shrink-0 self-stretch my-auto w-2 aspect-square"
                                    alt=""
                                />
                                <div className="self-stretch my-auto px-1">{parseFloat(percentage.toFixed(2))}%</div>
                            </div>
                        </div>
                        <div className="mt-8 text-sm leading-tight text-black whitespace-nowrap">{title}</div>
                    </div>
                </div>
                <img
                    loading="lazy"
                    src={icon}
                    className="object-contain shrink-0 w-6 rounded aspect-square shadow-[0px_2px_4px_rgba(0,0,0,0.08)]"
                    alt=""
                />
            </div>
        </div>
    );
};

export default TrendCard;
