import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import CustomSingleSelectAutoComplete from "../CustomSingleSelectAutoComplete";

export default function EmailSentChart() {
    const chartContainerRef = useRef(null);
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const allTimeZones = Intl.supportedValuesOf("timeZone");

    const chartData = {
        categories: ["25 Jul", "26 July", "27 July", "28 July", "29 July", "30 July"],
        series: [
            { name: "Sent", data: [6, 0, 0, 0, 13, 13], color: "#4873fa" },
            { name: "Opened", data: [1, 2, 0, 0, 0, 2], color: "#c36dcb" },
            { name: "Replied", data: [0, 0, 0, 0, 0, 0], color: "#66c1c1" },
            { name: "Clicked", data: [1, 1, 0, 0, 0, 2], color: "#ebc13b" },
            { name: "Bounced", data: [0, 1, 0, 0, 0, 0], color: "#e5899b" },
        ],
    };

    const chartOptions = {
        chart: { type: "column", height: 300 },
        title: { text: null },
        xAxis: { categories: chartData.categories, gridLineWidth: 0, lineWidth: 0 },
        yAxis: { min: 0, title: { text: null }, labels: { overflow: "justify" }, gridLineWidth: 1 },
        tooltip: { valueSuffix: " millions" },
        plotOptions: {
            column: {
                borderRadius: 0,
                groupPadding: 0.1,
                dataLabels: {
                    enabled: true,
                    inside: false,
                    style: { textOutline: "none", color: null },
                    formatter() {
                        return this.y > 0 ? `<span style="color: ${this.color}">${this.y}</span>` : "";
                    },
                },
            },
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
            shadow: false,
            symbolRadius: 0,
        },
        credits: { enabled: false },
        series: chartData.series.map((serie) => ({
            name: serie.name,
            data: serie.data.map((value) => ({ y: value, color: serie.color })),
            color: serie.color,
        })),
    };

    useEffect(() => {
        Highcharts.chart(chartContainerRef.current, chartOptions);

        return () => {
            Highcharts.charts[0]?.destroy();
        };
    }, [chartData]);

    return (
        <div className="mt-12 campaign-card">
            <div className="flex">
                <div>
                    <h2 className="font-medium">Emails Sent Time Series</h2>
                    <p className="mt-1 text-sm text-dgray-400 hidden md:block">
                        The data is displayed in Etc/GMT timezone from Thursday, Jul 25, 2024 to Tuesday, Jul 30, 2024.
                    </p>
                </div>
                <div className="ml-auto">
                    <CustomSingleSelectAutoComplete
                        id="timeZone for email sent chart"
                        placeholder="Select Time Zone"
                        options={allTimeZones}
                        onSelectionChange={(e, value) => setSelectedTimeZone(value)}
                        selectedValue={selectedTimeZone}
                        isIconVisible={false}
                        bgColor="white"
                        sx={{ width: "200px" }}
                    />
                </div>
            </div>
            <div className="mt-5">
                <div ref={chartContainerRef} />
            </div>
        </div>
    );
}
