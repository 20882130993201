import React from "react";

const DonutChart = ({ value1, value2, color1 = "red", color2 = "blue", size = "32px", className }) => {
    const total = value1 + value2;
    const percentage1 = (value1 / total) * 100;
    const percentage2 = (value2 / total) * 100;
    const sizeNumber = parseInt(size);

    // Calculate the angles for the separation cuts
    const rotationAngle1 = (percentage1 / 100) * 360;
    const rotationAngle2 = rotationAngle1 + (percentage2 / 100) * 360;

    return (
        <div className={`relative flex items-center justify-center ${className}`} style={{ height: size, width: size }}>
            <div
                className="absolute top-0 left-0 rounded-full"
                style={{
                    background: `conic-gradient(${color1} ${percentage1}%, ${color2} ${percentage1}% 100%)`,
                    height: size,
                    width: size,
                }}
            >
                {/* First cut */}
                <div
                    className="absolute"
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: `translate(-50%, -50%) rotate(${rotationAngle1}deg)`,
                        height: `${sizeNumber}px`,
                        width: `${sizeNumber}px`,
                        clipPath: "polygon(50% 0%, 52% 0%, 50% 50%, 48% 0%)",
                        backgroundColor: "white",
                        zIndex: 2,
                    }}
                ></div>

                {/* Second cut */}
                <div
                    className="absolute"
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: `translate(-50%, -50%) rotate(${rotationAngle2}deg)`,
                        height: `${sizeNumber}px`,
                        width: `${sizeNumber}px`,
                        clipPath: "polygon(50% 0%, 52% 0%, 50% 50%, 48% 0%)",
                        backgroundColor: "white",
                        zIndex: 2,
                    }}
                ></div>
            </div>
            <div className="absolute bg-white rounded-full" style={{ height: `${sizeNumber - 12}px`, width: `${sizeNumber - 12}px` }}></div>
        </div>
    );
};

export default DonutChart;
