import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import {
    Box,
    Table,
    TableBody,
    TableCell as TableCellMui,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    Chip,
    TextField,
} from "@mui/material";
import {
    MoreVert as MoreVertIcon,
    KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon,
    KeyboardArrowUpOutlined as KeyboardArrowUpOutlinedIcon,
    Save as SaveIcon,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import useTeam from "@/hooks/useTeam";
import MoveGroupIcon from "@/assets/image/team/move-group.svg";
import RemoveTeamIcon from "@/assets/image/team/remove-team.svg";
import Edit from "./Edit";
import Delete from "./Delete";
import ConfirmDelete from "./ConfirmDelete";

const TableCell = styled(TableCellMui)`
    position: relative;
`;

const SortIcon = ({ sort, direction, setSort, column }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 1,
                position: "relative",
                width: "15px",
                height: "30px",
            }}
        >
            <KeyboardArrowUpOutlinedIcon
                fontSize="small"
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    opacity: sort && direction === "asc" ? 1 : 0.5,
                }}
                onClick={() => {
                    setSort({
                        sort: 1,
                        sortBy: column,
                    });
                }}
                onHover={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            />
            <KeyboardArrowDownOutlinedIcon
                fontSize="small"
                sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    opacity: sort && direction === "desc" ? 1 : 0.5,
                }}
                onClick={() => {
                    setSort({
                        sort: -1,
                        sortBy: column,
                    });
                }}
                onHover={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            />
        </Box>
    );
};

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
        showSort: true,
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email",
        showSort: true,
    },
    {
        id: "emailCreditLimit",
        numeric: false,
        disablePadding: true,
        label: "Email Credit ",
        showSort: true,
        secondaryLabel: "Available/Limit",
    },
    {
        id: "phoneCreditLimit",
        numeric: false,
        disablePadding: true,
        label: "Phone Credit ",
        showSort: true,
        secondaryLabel: "Available/Limit",
    },
    {
        id: "validationCreditLimit",
        numeric: false,
        disablePadding: true,
        label: "Validation Credit",
        showSort: true,
        secondaryLabel: "Available/Limit",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: "Status",
        showSort: true,
    },
    {
        id: "",
        numeric: false,
        disablePadding: false,
        label: "",
        showVerticalIcon: true,
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, sort, setSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "10px 16px",
                    fontFamily: "Outfit",
                    fontSize: 16,
                    fontWeight: 700,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F3F3F3",
                    overflowY: "hidden",
                },
                "& .MuiTableHead-root": {
                    overflowY: "hidden",
                },
                "& .MuiTableSortLabel-icon": {
                    display: "none",
                },
                borderRadius: "8px",
                "& .MuiTableRow-head:last": {
                    bgcolor: "#F3F3F3",
                },
            }}
        >
            <TableRow>
                {headCells?.map((headCell) => (
                    <TableCell key={headCell.id} align={"left"} padding={"none"} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon
                            sx={{
                                position: "relative",
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                            {headCell?.showSort ? (
                                <SortIcon
                                    sort={headCell.id === sort.sortBy}
                                    direction={sort.sort === -1 ? "desc" : "asc"}
                                    setSort={setSort}
                                    column={headCell.id}
                                />
                            ) : (
                                ""
                            )}
                            {headCell?.showVerticalIcon ? <MoreVertIcon /> : ""}
                            {headCell?.secondaryLabel ? (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: "-16px",
                                        fontSize: "10px",
                                        fontWeight: 400,
                                    }}
                                >
                                    {headCell?.secondaryLabel}
                                </Box>
                            ) : (
                                ""
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>{/* <DeleteIcon /> */}</IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>{/* <FilterListIcon /> */}</IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ filterRole, searchValue, data, setData, sort, setSort, creditInfo }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    const [open, setOpen] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const { deleteMember, updateTeamMember } = useTeam();
    const [currentlySelected] = React.useState(null);
    const [edit, setEdit] = React.useState("");
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const menuOptions = [
        {
            icon: MoveGroupIcon,
            label: "Move to the group",
        },
        {
            icon: RemoveTeamIcon,
            label: "Remove team member",
            handler: async (id) => {
                await deleteMember({ id });
                setData(data.filter((item) => item._id !== id));
            },
        },
    ];

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const openMenu = () => {
        return menuAnchorEl !== null;
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Ensure data is always an array
    const rows = data || [];

    return (
        <Box sx={{ width: "100%" }}>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        sort={sort}
                        setSort={setSort}
                    />
                    <TableBody
                        sx={{
                            "& .MuiTableCell-sizeMedium": {
                                padding: "10px 16px",
                                fontFamily: "Outfit",
                                fontSize: 16,
                            },
                        }}
                    >
                        {rows.length > 0 ? (
                            rows?.map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, row.name)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row._id}
                                        selected={isItemSelected}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            padding="none"
                                            align="left"
                                            sx={{
                                                width: "200px",
                                                maxWidth: "200px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <Tooltip title={row?.email} arrow>
                                                <span>{row?.email}</span>
                                            </Tooltip>
                                            {row?.email}
                                        </TableCell>
                                        <TableCell padding="none" align="left">
                                            {edit?._id === row._id ? (
                                                <TextField
                                                    id="outlined-number"
                                                    label=""
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={edit?.emailCreditLimit}
                                                    onChange={(e) => {
                                                        if (e.target.value < 0) {
                                                            return;
                                                        }
                                                        setEdit((prev) => ({
                                                            ...prev,
                                                            emailCreditLimit: e.target.value,
                                                        }));
                                                    }}
                                                    size="small"
                                                    sx={{
                                                        width: "100px",
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    {row?.availableEmailCredit}/{row.emailCreditLimit ?? 0}
                                                    {/* <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          
                        </Box> */}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell padding="none" align="left">
                                            {edit?._id === row._id ? (
                                                <TextField
                                                    id="outlined-number"
                                                    label=""
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={edit?.phoneCreditLimit}
                                                    onChange={(e) => {
                                                        if (e.target.value < 0) {
                                                            return;
                                                        }
                                                        setEdit((prev) => ({
                                                            ...prev,
                                                            phoneCreditLimit: e.target.value,
                                                        }));
                                                    }}
                                                    size="small"
                                                    sx={{
                                                        width: "100px",
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    {row?.availablePhoneCredit}/{row.phoneCreditLimit ?? 0}
                                                    {/* <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          
                        </Box> */}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell padding="none" align="left">
                                            {edit?._id === row._id ? (
                                                <TextField
                                                    id="outlined-number"
                                                    label=""
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={edit?.validationCreditLimit}
                                                    onChange={(e) => {
                                                        if (e.target.value < 0) {
                                                            return;
                                                        }
                                                        setEdit((prev) => ({
                                                            ...prev,
                                                            validationCreditLimit: e.target.value,
                                                        }));
                                                    }}
                                                    size="small"
                                                    sx={{
                                                        width: "100px",
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    {row?.availableValidationCredit}/{row.validationCreditLimit ?? 0}
                                                    {/* <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          {row?.availableValidationCredit}
                        </Box> */}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell padding="none" align="left">
                                            <Chip
                                                label={row.status}
                                                color="primary"
                                                sx={
                                                    row.status === "ACTIVE"
                                                        ? {
                                                              color: "#2A763D",
                                                              backgroundColor: "#C9F3D4",
                                                              fontWeight: 900,
                                                          }
                                                        : {
                                                              color: "#DC6803",
                                                              backgroundColor: "#FEF0C7",
                                                              fontWeight: 900,
                                                          }
                                                }
                                            />
                                        </TableCell>
                                        <TableCell padding="none" align="left">
                                            {edit?._id === row._id ? (
                                                <IconButton
                                                    onClick={async () => {
                                                        const response = await updateTeamMember({
                                                            id: row._id,
                                                            emailCreditLimit: edit?.emailCreditLimit,
                                                            phoneCreditLimit: edit?.phoneCreditLimit,
                                                            validationCreditLimit: edit?.validationCreditLimit,
                                                        });

                                                        if (response.status === false) {
                                                            toast.error(response.data ?? response?.message, {
                                                                theme: "colored",
                                                            });
                                                            setEdit("");
                                                        } else {
                                                            setData(
                                                                data.map((item) => {
                                                                    if (item._id === row._id) {
                                                                        return {
                                                                            ...item,
                                                                            ...response.data,
                                                                        };
                                                                    }
                                                                    return item;
                                                                })
                                                            );

                                                            toast.success(
                                                                `
                              Team member ${row.name} has been updated successfully
                            `,
                                                                { theme: "colored" }
                                                            );
                                                            setEdit("");
                                                        }
                                                    }}
                                                >
                                                    <SaveIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    onClick={async () => {
                                                        setEdit(row);
                                                    }}
                                                    disabled={row.status !== "ACTIVE"}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            <IconButton
                                                onClick={async (event) => {
                                                    setOpen(row);
                                                    // handleClickOpen();
                                                    // setCurrentlySelected(row);
                                                    // setMenuAnchorEl(event.currentTarget);
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Menu
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={menuAnchorEl}
                open={openMenu()}
                onClose={handleMenuClose}
            >
                {menuOptions.map((option) => (
                    <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={async () => {
                            await option.handler(currentlySelected._id);
                            handleMenuClose();
                        }}
                    >
                        <ListItemIcon>
                            <ReactSVG src={option.icon} />
                        </ListItemIcon>
                        <Typography variant="inherit">{option.label}</Typography>
                    </MenuItem>
                ))}
            </Menu>
            <ConfirmDelete
                open={open}
                setOpen={setOpen}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                handleConfirm={async () => {
                    await deleteMember({
                        id: open._id,
                    });
                    setData(data.filter((item) => item._id !== open._id));
                    toast.success(
                        `
              Team member ${open.name} has been removed successfully
            `,
                        { theme: "colored" }
                    );
                    setOpen(null);
                }}
            />
        </Box>
    );
}
