import React, { createContext, useContext, useEffect, useState } from "react";
import useKompassSearch from "@/hooks/useKompassSearch";
import {
  continentList,
  departmentList,
  departmentSubDepartmentMap,
  genderList,
  revenueList,
  subDepartmentList,
} from "@/utils/filter";
import { toast } from "react-toastify";
import useDashboard from "@/hooks/useDashboard";
import { Country, State } from "country-state-city";

const FiltersContext = createContext();

export const KompassFiltersProvider = ({
  children,
  onSearchByFilter,
  isFiltersSubpage,
  setCurrentTab,
}) => {
  const { saveSearchFilters, getSavedSearchFilters } = useKompassSearch();
  const { getDashboard } = useDashboard();
  const [savedFilters, setSavedFilters] = useState([]);
  const [updateSearchFiltersSubpage, setUpdateSearchFiltersSubpage] =
    useState(false);
  const [isSaveSearchModalOpen, setIsSaveSearchModalOpen] = useState(false);
  const [saveSearchTitle, setSaveSearchTitle] = useState("");
  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [website, setWebsite] = useState([]);
  const [title, setTitle] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [companyLocation, setCompanyLocation] = useState([]);
  const [personalLocation, setPersonalLocation] = useState([]);
  const [dept, setDept] = useState(null);
  const [jobTitle, setJobTitle] = useState([]);
  const [subDepartment, setSubDepartment] = useState(null);
  const [level, setLevel] = useState(null);
  const [numberOfEmployees, setNumberOfEmployees] = useState(null);
  const [revenue, setRevenue] = useState("");
  const [fundingRaised, setFundingRaised] = useState({ min: "", max: "" });
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [industryName, setIndustryName] = useState(null);
  const [degree, setDegree] = useState(null);
  const [previousCompanyHqLocation, setPreviousCompanyHqLocation] = useState(
    []
  );
  const [languagesSpoken, setLanguagesSpoken] = useState(null);
  const [totalYearsOfExperience, setTotalYearsOfExperience] = useState({
    min: "",
    max: "",
  });
  const [gender, setGender] = useState(null);
  const [continent, setContinent] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [skills, setSkills] = useState([]);
  const [workEmail, setWorkEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [certification, setCertification] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState([]);
  const [companyContinent, setCompanyContinent] = useState(null);
  const [companyCountry, setCompanyCountry] = useState(null);
  const [companyListing, setCompanyListing] = useState(null);
  const [actualEmployeeRange, setActualEmployeeRange] = useState({
    min: "",
    max: "",
  });
  const [hqContinent, setHqContinent] = useState(null);
  const [hqCountry, setHqCountry] = useState(null);
  const [companyGrowthRate, setCompanyGrowthRate] = useState("");
  const [numbersOfMonthInCurrentRole, setNumbersOfMonthInCurrentRole] =
    useState("");
  const [jobLevel, setJobLevel] = useState(null);
  const [jobDepartment, setJobDepartment] = useState(null);
  const [jobSubDepartment, setJobSubDepartment] = useState(null);
  const [gitHubUrl, setGitHubUrl] = useState(null);
  const [gitHubUsername, setGitHubUsername] = useState(null);
  const [facebookUrl, setFacebookUrl] = useState(null);
  const [companyIndustry, setCompanyIndustry] = useState(null);
  const [schoolName, setSchoolName] = useState([]);
  const [isExpandAllTitles, setIsExpandAllTitles] = useState(false);
  const [pastCompanyName, setPastCompanyName] = useState([]);
  const [clearTrigger, setClearTrigger] = useState(false);
  const [isShowSuccessfullyClearedIcon, setIsShowSuccessfullyClearedIcon] =
    useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [isFromSavedFiltersPage, setIsFromSavedFiltersPage] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const [isSavingLoading, setIsSavingLoading] = useState(false);

  const filterSubDepartments = (departments, subDepartments, map) => {
    const filtered = departments.flatMap((department) => map[department]);
    return subDepartments.filter((subDept) => filtered.includes(subDept));
  };

  const countTruthyFilters = () => {
    const filters = [
      name,
      website,
      title,
      companyName,
      companyLocation,
      personalLocation,
      dept,
      jobTitle,
      subDepartment,
      level,
      numberOfEmployees,
      revenue,
      linkedinUrl,
      industryName,
      degree,
      previousCompanyHqLocation,
      languagesSpoken,
      gender,
      continent,
      country,
      state,
      skills,
      workEmail,
      mobilePhone,
      personalEmail,
      certification,
      companyWebsite,
      companyContinent,
      companyCountry,
      companyListing,
      companyGrowthRate,
      numbersOfMonthInCurrentRole,
      jobLevel,
      jobDepartment,
      jobSubDepartment,
      gitHubUrl,
      gitHubUsername,
      facebookUrl,
      companyIndustry,
      schoolName,
      pastCompanyName,
      fundingRaised.min || fundingRaised.max,
      totalYearsOfExperience.min || totalYearsOfExperience.max,
      actualEmployeeRange.min || actualEmployeeRange.max,
      hqContinent,
      hqCountry,
    ];

    return filters.filter((value) => {
      if (Array.isArray(value)) {
        return value.length > 0; // Keep only non-empty arrays
      } else if (typeof value === "string") {
        return value.trim(); // Keep only non-empty strings (trimmed to avoid whitespace-only strings)
      } else {
        return Boolean(value); // For other types, use their boolean value
      }
    }).length;
  };

  useEffect(() => {
    const count = countTruthyFilters();
    setActiveFilterCount(count);
    console.log("reset filter count");
  }, [
    name,
    website,
    state,
    title,
    companyName,
    companyLocation,
    personalLocation,
    dept,
    jobTitle,
    subDepartment,
    level,
    numberOfEmployees,
    revenue,
    fundingRaised,
    linkedinUrl,
    industryName,
    degree,
    previousCompanyHqLocation,
    languagesSpoken,
    totalYearsOfExperience,
    gender,
    continent,
    country,
    state,
    skills,
    workEmail,
    mobilePhone,
    personalEmail,
    certification,
    companyWebsite,
    companyContinent,
    companyCountry,
    companyListing,
    actualEmployeeRange,
    hqContinent,
    hqCountry,
    companyGrowthRate,
    numbersOfMonthInCurrentRole,
    jobLevel,
    jobDepartment,
    jobSubDepartment,
    gitHubUrl,
    gitHubUsername,
    facebookUrl,
    companyIndustry,
    schoolName,
    pastCompanyName,
  ]);

  useEffect(() => {
    if (subDepartment?.length && dept?.length) {
      setSubDepartment((prevSelected) =>
        filterSubDepartments(dept, prevSelected, departmentSubDepartmentMap)
      );
    }
  }, [dept, subDepartment?.length]);

  useEffect(() => {
    if (jobSubDepartment?.length && jobDepartment?.length) {
      setJobSubDepartment((prevSelected) =>
        filterSubDepartments(
          jobDepartment,
          prevSelected,
          departmentSubDepartmentMap
        )
      );
    }
  }, [jobDepartment, jobSubDepartment?.length]);

  useEffect(() => {
    const getSavedSearches = async () => {
      const res = await getSavedSearchFilters();
      if (res.status) setSavedFilters(res.data);
    };

    getSavedSearches();
  }, [isFiltersSubpage, updateSearchFiltersSubpage]);

  useEffect(() => {
    const fetchData = async (filters) => {
      const response = await getDashboard(filters);
      setPlanInfo(response?.data?.planInfo ?? null);
    };
    fetchData({
      usageAnalyticsFilter: "THIS_MONTH",
      contactsCreatedFilter: "THIS_MONTH",
      creditsDeductedFilter: "THIS_MONTH",
    });
  }, []);

  useEffect(() => {}, []);

  const emptyFiltersInfoMessage = "Please, select some filters";

  const cleanLinkedInUrl = (url) => {
    const pattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/([\w-]+)\/?$/;
    const match = url.trim().match(pattern);

    if (match) {
      return `linkedin.com/in/${match[2]}`;
    }

    return url; // Return the original URL if it doesn't match the pattern
  };

  const cleanGitHubUrl = (url) => {
    const pattern = /^https?:\/\/(www\.)?github\.com\/([\w-]+)\/?$/;
    const match = url.match(pattern);

    if (match) {
      return `github.com/${match[2]}`;
    }

    return url; // Return the original URL if it doesn't match the pattern
  };

  const getDateBeforeMonths = (months) => {
    // Get the current date
    const currentDate = new Date();

    // Subtract the number of months from the current date
    currentDate.setMonth(currentDate.getMonth() - months);

    // Format the date as YYYY-MM-DD
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");

    // Return the formatted date
    return `${year}-${month}`;
  };

  const handleSearch = () => {
    const isEmpty = (value) =>
      value === undefined ||
      value === null ||
      (typeof value === "string" && value.trim() === "") ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" && Object.keys(value).length === 0);

    const currentJob = {
      ...(title && !isEmpty(title) ? { job_title: title } : {}),
      ...(companyName && !isEmpty(companyName)
        ? { job_company_name: companyName }
        : {}),
      ...(industryName && !isEmpty(industryName)
        ? { industry: industryName }
        : {}),
      ...(level && !isEmpty(level) ? { job_title_levels: level } : {}),
      ...(dept && !isEmpty(dept) ? { job_title_role: dept } : {}),
      ...(subDepartment && !isEmpty(subDepartment)
        ? { job_title_sub_role: subDepartment }
        : {}),
      ...(numberOfEmployees && !isEmpty(numberOfEmployees)
        ? { job_company_size: numberOfEmployees }
        : {}),
      ...(hqContinent && !isEmpty(hqContinent)
        ? { job_company_location_continent: hqContinent }
        : {}),
      ...(hqCountry && !isEmpty(hqCountry)
        ? { job_company_location_country: hqCountry }
        : {}),
    };

    const pastJob = {
      ...(jobTitle && !isEmpty(jobTitle)
        ? { "experience.title.name": jobTitle }
        : {}),
      ...(jobLevel && !isEmpty(jobLevel)
        ? { "experience.title.levels": jobLevel }
        : {}),
      ...(jobDepartment && !isEmpty(jobDepartment)
        ? { "experience.title.role": jobDepartment }
        : {}),
      ...(jobSubDepartment && !isEmpty(jobSubDepartment)
        ? { "experience.title.sub_role": jobSubDepartment }
        : {}),
      ...(companyContinent && !isEmpty(companyContinent)
        ? { "experience.company.location.continent": companyContinent }
        : {}),
      ...(companyCountry && !isEmpty(companyCountry)
        ? { "experience.company.location.country": companyCountry }
        : {}),
      ...(companyListing && !isEmpty(companyListing)
        ? { "experience.company.type": companyListing }
        : {}),
      ...(pastCompanyName && !isEmpty(pastCompanyName)
        ? { "experience.company.name": pastCompanyName }
        : {}),
      ...(companyIndustry && !isEmpty(companyIndustry)
        ? { "experience.company.industry": companyIndustry }
        : {}),
      ...(previousCompanyHqLocation && !isEmpty(previousCompanyHqLocation)
        ? { "experience.company.location.name": previousCompanyHqLocation }
        : {}),
    };

    const rangeInputs = {
      ...(actualEmployeeRange &&
      (actualEmployeeRange.min || actualEmployeeRange.max)
        ? { job_company_employee_count: actualEmployeeRange }
        : {}),
      ...(totalYearsOfExperience &&
      (totalYearsOfExperience.min || totalYearsOfExperience.max)
        ? { inferred_years_experience: totalYearsOfExperience }
        : {}),
      ...(fundingRaised && (fundingRaised.min || fundingRaised.max)
        ? { job_company_total_funding_raised: fundingRaised }
        : {}),
    };

    const isEmptyOrWhitespace = (value) =>
      typeof value === "string" && value.trim() === "";

    const params = {
      ...(name && !isEmpty(name) ? { full_name: name.trim() } : {}),
      ...(personalLocation && !isEmpty(personalLocation)
        ? { location_name: personalLocation }
        : {}),
      ...(website && !isEmpty(website) ? { job_company_website: website } : {}),
      ...(Object.keys(currentJob).length ? { currentJob } : {}),
      ...(revenue && !isEmpty(revenue)
        ? { job_company_inferred_revenue: revenue.value }
        : {}),
      ...(companyLocation && !isEmpty(companyLocation)
        ? { job_company_location_name: companyLocation }
        : {}),
      ...(linkedinUrl && !isEmpty(linkedinUrl)
        ? { linkedin_url: cleanLinkedInUrl(linkedinUrl) }
        : {}),
      ...(gender && !isEmpty(gender) ? { sex: gender.value } : {}),
      ...(languagesSpoken && !isEmpty(languagesSpoken)
        ? { "languages.name": languagesSpoken }
        : {}),
      ...(skills && !isEmpty(skills) ? { skills } : {}),
      ...(workEmail && !isEmpty(workEmail)
        ? { work_email: workEmail.trim() }
        : {}),
      ...(mobilePhone && !isEmpty(mobilePhone)
        ? { mobile_phone: mobilePhone.trim() }
        : {}),
      ...(continent && !isEmpty(continent)
        ? { location_continent: continent.value }
        : {}),
      ...(country && !isEmpty(country) ? { location_country: country } : {}),
      ...(state && !isEmpty(state) ? { location_region: state } : {}),
      ...(personalEmail && !isEmpty(personalEmail)
        ? { personal_emails: [personalEmail] }
        : {}),
      ...(certification && !isEmpty(certification)
        ? { "certifications.name": certification.trim() }
        : {}),
      ...(schoolName && !isEmpty(schoolName)
        ? { "education.school.name": schoolName.trim() }
        : {}),
      ...(degree && !isEmpty(degree) ? { "education.degrees": degree } : {}),
      ...(gitHubUrl && !isEmpty(gitHubUrl)
        ? { github_url: cleanGitHubUrl(gitHubUrl.trim()) }
        : {}),
      ...(gitHubUsername && !isEmpty(gitHubUsername)
        ? { github_username: gitHubUsername }
        : {}),
      ...(facebookUrl && !isEmpty(facebookUrl)
        ? { facebook_url: facebookUrl }
        : {}),
      ...(companyWebsite && !isEmpty(companyWebsite)
        ? { "experience.company.website": companyWebsite }
        : {}),
      ...(Object.keys(rangeInputs).length ? { rangeInputs } : {}),
      ...(numbersOfMonthInCurrentRole && !isEmpty(numbersOfMonthInCurrentRole)
        ? { job_start_date: getDateBeforeMonths(numbersOfMonthInCurrentRole) }
        : {}),
      ...(Object.keys(pastJob).length ? { pastJob } : {}),
    };

    const allFiltersEmptyOrWhitespace = Object.keys(params).every((key) =>
      isEmpty(params[key])
    );

    if (!Object.keys(params).length || allFiltersEmptyOrWhitespace) {
      return toast.info(emptyFiltersInfoMessage);
    }

    onSearchByFilter({
      params: { ...params, subscriptionName: planInfo.name },
    });
  };

  const onClearFilterClicked = () => {
    setName("");
    setWebsite([]);
    setTitle([]);
    setCompanyName([]);
    setCompanyLocation([]);
    setPersonalLocation([]);
    setDept(null);
    setJobTitle([]);
    setSubDepartment(null);
    setLevel(null);
    setNumberOfEmployees(null);
    setRevenue(null);
    setFundingRaised({ min: "", max: "" });
    setLinkedinUrl("");
    setIndustryName(null);
    setDegree(null);
    setPreviousCompanyHqLocation([]);
    setLanguagesSpoken(null);
    setTotalYearsOfExperience({ min: "", max: "" });
    setGender(null);
    setContinent(null);
    setCountry(null);
    setState(null);
    setStatesList([]);
    setSkills([]);
    setWorkEmail("");
    setMobilePhone("");
    setPersonalEmail("");
    setCertification("");
    setCompanyWebsite([]);
    setCompanyContinent(null);
    setCompanyCountry(null);
    setCompanyListing(null);
    setActualEmployeeRange({ min: "", max: "" });
    setHqContinent(null);
    setHqCountry(null);
    setCompanyGrowthRate("");
    setNumbersOfMonthInCurrentRole("");
    setJobLevel(null);
    setJobDepartment(null);
    setJobSubDepartment(null);
    setGitHubUrl(null);
    setGitHubUsername(null);
    setFacebookUrl(null);
    setCompanyIndustry(null);
    setSchoolName([]);
    setPastCompanyName([]);
  };

  const handleSubDepartmentList = (departments) => {
    if (!departments?.length) {
      // If no department is selected, show all sub-departments
      return subDepartmentList;
    } else {
      // Flatten the arrays of sub-departments for each selected department
      const filtered = departments.flatMap(
        (department) => departmentSubDepartmentMap[department]
      );

      // Filter sub-departments based on the selected departments
      return subDepartmentList.filter((subDept) =>
        filtered.includes(subDept.value)
      );
    }
  };

  const getSubDepartmentFromChosenDepartments = (chosenDepartment) => {
    if (chosenDepartment) {
      return departmentList
        .filter((department) => chosenDepartment.value === department.value)
        .flatMap((department) => department.subDepartment);
    } else {
      return [];
    }
  };

  const handleSaveSearch = async () => {
    const params = {
      title: saveSearchTitle,
      filters: {
        ...(name?.trim() && { fullName: name }),
        ...(personalLocation?.length && { personalLocation: personalLocation }),
        ...(website?.length && { currentCompanyWebsite: website }),
        ...(title?.length ? { currentJobTitle: title } : {}),
        ...(companyName?.length ? { currentCompanyName: companyName } : {}),
        ...(industryName?.length && { currentIndustry: industryName }),
        ...(level?.length ? { currentJobLevels: level } : {}),
        ...(dept?.length ? { currentJobDepartments: dept } : {}),
        ...(subDepartment?.length
          ? { currentJobSubDepartments: subDepartment }
          : {}),
        ...(numberOfEmployees?.length
          ? {
              currentNumberOfEmployees: numberOfEmployees,
            }
          : {}),
        ...(hqContinent?.length ? { hqContinent } : {}),

        ...(hqCountry?.length ? { hqCountry } : {}),
        ...(revenue && { currentRevenue: revenue.value }),
        ...(companyLocation?.length && {
          currentCompanyLocation: companyLocation,
        }),
        ...(linkedinUrl?.trim() && { linkedinUrl }),
        ...(gender && { gender: gender.value }),
        ...(languagesSpoken?.length && {
          languagesSpoken,
        }),
        ...(skills?.length && {
          skills,
        }),
        ...(workEmail?.trim() && { workEmail }),
        ...(mobilePhone?.trim() && { mobilePhone }),
        ...(continent && { continent: continent.value }),
        ...(country?.length && { country }),
        ...(state?.length && { state }),
        ...(personalEmail?.trim() && { personalEmail }),
        ...(certification?.trim() && {
          certification: certification.trim(),
        }),
        ...(schoolName?.length && {
          schoolName: schoolName,
        }),
        ...(degree?.length && { degree }),
        ...(gitHubUrl?.trim() && { gitHubUrl }),
        ...(gitHubUsername?.trim() && { gitHubUsername }),
        ...(facebookUrl?.trim() && { facebookUrl }),
        ...(companyWebsite?.length
          ? { pastCompanyWebsite: companyWebsite }
          : {}),
        ...(actualEmployeeRange?.min || actualEmployeeRange?.max
          ? { actualEmployeeRange: actualEmployeeRange }
          : {}),
        ...(totalYearsOfExperience?.min || totalYearsOfExperience?.max
          ? { totalYearsOfExperience }
          : {}),
        ...(fundingRaised?.min || fundingRaised?.max ? { fundingRaised } : {}),
        ...(numbersOfMonthInCurrentRole && {
          numbersOfMonthInCurrentRole,
        }),
        ...(jobTitle?.length ? { pastJobTitle: jobTitle } : {}),
        ...(jobLevel?.length ? { pastJobLevels: jobLevel } : {}),
        ...(jobDepartment?.length ? { pastJobDepartment: jobDepartment } : {}),
        ...(jobSubDepartment?.length
          ? { pastJobSubDepartment: jobSubDepartment }
          : {}),
        ...(companyContinent?.length
          ? { pastJobCompanyContinent: companyContinent }
          : {}),
        ...(companyCountry?.length
          ? { pastJobCompanyCountry: companyCountry }
          : {}),
        ...(companyListing?.length ? { pastJobListing: companyListing } : {}),
        ...(pastCompanyName?.length ? { pastCompanyName } : {}),
        ...(companyIndustry?.length
          ? { pastCompanyIndustry: companyIndustry }
          : {}),
        ...(previousCompanyHqLocation ? { previousCompanyHqLocation } : {}),
      },
    };

    if (!Object.keys(params.filters).length) {
      return toast.info(emptyFiltersInfoMessage);
    }

    if (!isSaveSearchModalOpen) {
      return setIsSaveSearchModalOpen(true);
    }

    setIsSavingLoading(true);

    const res = await saveSearchFilters(params);

    if (!res.status) {
      toast.error(res.message);
    } else {
      toast.success("Search successfully saved");
    }

    setUpdateSearchFiltersSubpage((prev) => !prev);
    setIsSaveSearchModalOpen(false);
    setIsSavingLoading(false);
  };

  const handleSetFilters = async (filters) => {
    if (filters.fullName) setName(filters.fullName);
    if (filters.personalLocation) setPersonalLocation(filters.personalLocation);
    if (filters.currentCompanyWebsite)
      setWebsite(filters.currentCompanyWebsite);
    if (filters.currentJobTitle) setTitle(filters.currentJobTitle);
    if (filters.currentCompanyName) setCompanyName(filters.currentCompanyName);
    if (filters.currentIndustry) setIndustryName(filters.currentIndustry);
    if (filters.currentJobLevels) setLevel(filters.currentJobLevels);
    if (filters.currentJobDepartments) setDept(filters.currentJobDepartments);
    if (filters.currentJobSubDepartments)
      setSubDepartment(filters.currentJobSubDepartments);
    if (filters.currentNumberOfEmployees)
      setNumberOfEmployees(filters.currentNumberOfEmployees);
    if (filters.hqContinent) setHqContinent(filters.hqContinent);
    if (filters.hqCountry) setHqCountry(filters.hqCountry);
    if (filters.currentRevenue)
      setRevenue(
        revenueList.find(({ value }) => value === filters.currentRevenue)
      );
    if (filters.currentCompanyLocation)
      setCompanyLocation(filters.currentCompanyLocation);
    if (filters.linkedinUrl) setLinkedinUrl(filters.linkedinUrl);
    if (filters.gender)
      setGender(genderList.find(({ value }) => value === filters.gender));
    if (filters.languagesSpoken) setLanguagesSpoken(filters.languagesSpoken);
    if (filters.skills) setSkills(filters.skills);
    if (filters.workEmail) setWorkEmail(filters.workEmail);
    if (filters.mobilePhone) setMobilePhone(filters.mobilePhone);
    if (filters.continent)
      setContinent(
        continentList.find(({ value }) => value === filters.continent)
      );
    if (filters.country) setCountry(filters.country);
    if (filters.state) {
      setState(filters.state);
      const countryListForIsoCode = Country.getAllCountries().map(
        (country) => ({
          label: country.name,
          value: country.isoCode,
        })
      );

      const getIsoCode = (country) => {
        const countryObject = countryListForIsoCode.find(
          (obj) => obj.label.toLowerCase() === country.toLowerCase()
        );
        return countryObject ? countryObject.value : null;
      };

      if (filters.country.length > 0) {
        let allStates = [];

        filters.country.forEach((country) => {
          const selectedCountryIsoCode = getIsoCode(country);

          if (selectedCountryIsoCode) {
            const states = State.getStatesOfCountry(selectedCountryIsoCode).map(
              (state) => ({
                label: state.name,
                value: state.name,
              })
            );

            const uniqueStates = states.filter(
              (state) => !allStates.some((s) => s.value === state.value)
            );

            allStates = [...allStates, ...uniqueStates];
          }
        });

        setStatesList(allStates);
      } else {
        setStatesList([]);
      }
    }
    if (filters.personalEmail) setPersonalEmail(filters.personalEmail);
    if (filters.certification) setCertification(filters.certification);
    if (filters.schoolName) setSchoolName(filters.schoolName);
    if (filters.degree) setDegree(filters.degree);
    if (filters.gitHubUrl) setGitHubUrl(filters.gitHubUrl);
    if (filters.gitHubUsername) setGitHubUsername(filters.gitHubUsername);
    if (filters.facebookUrl) setFacebookUrl(filters.facebookUrl);
    if (filters.pastCompanyWebsite)
      setCompanyWebsite(filters.pastCompanyWebsite);
    if (filters.actualEmployeeRange)
      setActualEmployeeRange(filters.actualEmployeeRange);
    if (filters.totalYearsOfExperience)
      setTotalYearsOfExperience(filters.totalYearsOfExperience);
    if (filters.fundingRaised) setFundingRaised(filters.fundingRaised);
    if (filters.numbersOfMonthInCurrentRole)
      setNumbersOfMonthInCurrentRole(filters.numbersOfMonthInCurrentRole);
    if (filters.pastJobTitle) setJobTitle(filters.pastJobTitle);
    if (filters.pastJobLevels) setJobLevel(filters.pastJobLevels);
    if (filters.pastJobDepartment) setJobDepartment(filters.pastJobDepartment);
    if (filters.pastJobSubDepartment)
      setJobSubDepartment(filters.pastJobSubDepartment);
    if (filters.pastJobCompanyContinent)
      setCompanyContinent(filters.pastJobCompanyContinent);
    if (filters.pastJobCompanyCountry)
      setCompanyCountry(filters.pastJobCompanyCountry);
    if (filters.pastJobListing) setCompanyListing(filters.pastJobListing);
    if (filters.pastCompanyName) setPastCompanyName(filters.pastCompanyName);
    if (filters.pastCompanyIndustry)
      setCompanyIndustry(filters.pastCompanyIndustry);
    if (filters.previousCompanyHqLocation)
      setPreviousCompanyHqLocation(filters.previousCompanyHqLocation);

    if (Object.keys(filters).length === 44) {
      setIsExpandAllTitles(true);
    }
    setIsFromSavedFiltersPage(true);
    setCurrentTab(0);

    toast.success("Filters successfully added");
  };

  return (
    <FiltersContext.Provider
      value={{
        savedFilters,
        setSavedFilters,
        updateSearchFiltersSubpage,
        setUpdateSearchFiltersSubpage,
        isSaveSearchModalOpen,
        setIsSaveSearchModalOpen,
        saveSearchTitle,
        setSaveSearchTitle,
        isAdvancedModalOpen,
        setIsAdvancedModalOpen,
        name,
        setName,
        website,
        setWebsite,
        title,
        setTitle,
        companyName,
        setCompanyName,
        companyLocation,
        setCompanyLocation,
        personalLocation,
        setPersonalLocation,
        dept,
        setDept,
        jobTitle,
        setJobTitle,
        subDepartment,
        setSubDepartment,
        level,
        setLevel,
        numberOfEmployees,
        setNumberOfEmployees,
        revenue,
        setRevenue,
        fundingRaised,
        setFundingRaised,
        linkedinUrl,
        setLinkedinUrl,
        industryName,
        setIndustryName,
        degree,
        setDegree,
        previousCompanyHqLocation,
        setPreviousCompanyHqLocation,
        languagesSpoken,
        setLanguagesSpoken,
        totalYearsOfExperience,
        setTotalYearsOfExperience,
        gender,
        setGender,
        continent,
        setContinent,
        country,
        setCountry,
        state,
        setState,
        skills,
        setSkills,
        workEmail,
        setWorkEmail,
        mobilePhone,
        setMobilePhone,
        personalEmail,
        setPersonalEmail,
        certification,
        setCertification,
        companyWebsite,
        setCompanyWebsite,
        companyContinent,
        setCompanyContinent,
        companyCountry,
        setCompanyCountry,
        companyListing,
        setCompanyListing,
        actualEmployeeRange,
        setActualEmployeeRange,
        hqContinent,
        setHqContinent,
        hqCountry,
        setHqCountry,
        companyGrowthRate,
        setCompanyGrowthRate,
        numbersOfMonthInCurrentRole,
        setNumbersOfMonthInCurrentRole,
        jobLevel,
        setJobLevel,
        jobDepartment,
        setJobDepartment,
        jobSubDepartment,
        setJobSubDepartment,
        gitHubUrl,
        setGitHubUrl,
        gitHubUsername,
        setGitHubUsername,
        facebookUrl,
        setFacebookUrl,
        companyIndustry,
        setCompanyIndustry,
        schoolName,
        setSchoolName,
        isExpandAllTitles,
        setIsExpandAllTitles,
        pastCompanyName,
        setPastCompanyName,
        clearTrigger,
        setClearTrigger,
        isShowSuccessfullyClearedIcon,
        setIsShowSuccessfullyClearedIcon,
        handleSearch,
        onClearFilterClicked,
        handleSubDepartmentList,
        getSubDepartmentFromChosenDepartments,
        handleSaveSearch,
        handleSetFilters,
        planInfo,
        activeFilterCount,
        isFromSavedFiltersPage,
        setIsFromSavedFiltersPage,
        statesList,
        setStatesList,
        isSavingLoading,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useKompassFilters = () => {
  return useContext(FiltersContext);
};
