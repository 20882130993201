import React, { useState } from "react";
import { Country, State, City } from "country-state-city";
import classNames from "classnames";
import { Button } from "@material-tailwind/react";
import { TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AccordianComponent } from "@/components/search/AccordianComponents";
import FilterAutocomplete from "../common/FilterAutocomplete";
import AutocompleteInput from "../common/AutocompleteInput";
import { FilterInputProps } from "@/theme/typography";
import UserIcon from "@/assets/image/search/user.svg";
import HomeIcon from "@/assets/image/search/home.svg";
import LocationIcon from "@/assets/image/search/location.svg";
import DepartmentIcon from "@/assets/image/search/department.svg";
import LevelIcon from "@/assets/image/search/level.svg";
import USDIcon from "@/assets/image/search/usd.svg";
import IndustryIcon from "@/assets/image/search/industry.svg";
import BuildIcon from "@/assets/image/search/build.svg";
import GenderIcon from "@/assets/image/search/women-line.svg";
import CalendarIcon from "@/assets/image/search/calendar.svg";
import GlobesIcon from "@/assets/image/search/globus.svg";
import SkillLevel from "@/assets/image/search/skill-level.svg";
import MailIcon from "@/assets/image/search/mail-line.svg";
import EarthIcon from "@/assets/image/search/earth.svg";
import EarthDreamIcon from "@/assets/image/search/earth-dream.svg";
import CertificationIcon from "@/assets/image/search/ph_certificate.svg";
import WebsiteAdvancedIcon from "@/assets/image/search/website.svg";
import ListingIcon from "@/assets/image/search/listing.svg";
import RangeIcon from "@/assets/image/search/range.svg";
import SchoolIcon from "@/assets/image/search/school.svg";
import EditIcon from "@/assets/image/search/edit-icon.svg";
import LinkIcon from "@/assets/image/search/base-link.svg";
import EditUserIcon from "@/assets/image/search/edit.svg";
import PhoneIcon from "@/assets/image/search/call-phone.svg";
import { ReactComponent as CheckIcon } from "@/assets/image/icons/check.svg";
import { ReactComponent as SaveIcon } from "@/assets/image/search/not-selecte-bookmark.svg";
import {
    departmentList,
    levelList,
    numberOfEmployeesList,
    revenueList,
    industryList,
    degreeList,
    genderList,
    continentList,
    countryList,
    listingList,
    languageList,
} from "@/utils/filter";
import { AdvancedFilters } from "@/components/search/AdvancedFilters";
import { SavedSearchesList } from "@/components/search/SavedSearchesList";
import { SaveSearchDialog } from "@/components/search/SaveSearchDialog";
import { useKompassFilters } from "@/hooks/useKompassFilters";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

const GrayTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#E8E7E7",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#E8E7E7",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
    "& input:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        boxShadow: "inset 0 0 0px 1000px transparent",
    },
});

function ContactFilterComponent({ visible = true, isFiltersSubpage = true }) {
    const {
        savedFilters,
        setUpdateSearchFiltersSubpage,
        isSaveSearchModalOpen,
        setIsSaveSearchModalOpen,
        saveSearchTitle,
        setSaveSearchTitle,
        isAdvancedModalOpen,
        setIsAdvancedModalOpen,
        name,
        setName,
        website,
        setWebsite,
        title,
        setTitle,
        companyName,
        setCompanyName,
        companyLocation,
        setCompanyLocation,
        personalLocation,
        setPersonalLocation,
        dept,
        setDept,
        jobTitle,
        setJobTitle,
        subDepartment,
        setSubDepartment,
        level,
        setLevel,
        numberOfEmployees,
        setNumberOfEmployees,
        revenue,
        setRevenue,
        fundingRaised,
        setFundingRaised,
        linkedinUrl,
        setLinkedinUrl,
        industryName,
        setIndustryName,
        degree,
        setDegree,
        previousCompanyHqLocation,
        setPreviousCompanyHqLocation,
        languagesSpoken,
        setLanguagesSpoken,
        totalYearsOfExperience,
        setTotalYearsOfExperience,
        gender,
        setGender,
        continent,
        setContinent,
        country,
        setCountry,
        state,
        setState,
        skills,
        setSkills,
        workEmail,
        setWorkEmail,
        mobilePhone,
        setMobilePhone,
        personalEmail,
        setPersonalEmail,
        certification,
        setCertification,
        companyWebsite,
        setCompanyWebsite,
        companyContinent,
        setCompanyContinent,
        companyCountry,
        setCompanyCountry,
        companyListing,
        setCompanyListing,
        actualEmployeeRange,
        setActualEmployeeRange,
        hqContinent,
        setHqContinent,
        hqCountry,
        setHqCountry,
        numbersOfMonthInCurrentRole,
        setNumbersOfMonthInCurrentRole,
        jobLevel,
        setJobLevel,
        jobDepartment,
        setJobDepartment,
        jobSubDepartment,
        setJobSubDepartment,
        gitHubUrl,
        setGitHubUrl,
        gitHubUsername,
        setGitHubUsername,
        facebookUrl,
        setFacebookUrl,
        companyIndustry,
        setCompanyIndustry,
        schoolName,
        setSchoolName,
        isExpandAllTitles,
        setIsExpandAllTitles,
        pastCompanyName,
        setPastCompanyName,
        clearTrigger,
        handleSearch,
        onClearFilterClicked,
        handleSubDepartmentList,
        handleSaveSearch,
        handleSetFilters,
        activeFilterCount,
        isFromSavedFiltersPage,
        statesList,
        setStatesList,
        isSavingLoading,
    } = useKompassFilters();

    const countryListForIsoCode = Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));

    const getIsoCode = (country) => {
        const countryObject = countryListForIsoCode.find((obj) => obj.label.toLowerCase() === country?.toLowerCase());
        return countryObject?.value;
    };

    const handleCountryChange = (selectedCountries) => {
        setStatesList([]);
        if (country && selectedCountries.length < country.length) {
            setCountry(selectedCountries);
            const selectedCountryIsoCode = getIsoCode(selectedCountries[selectedCountries.length - 1]);
            const states = State.getStatesOfCountry(selectedCountryIsoCode).map((state) => ({
                label: state.name,
                value: state.name,
            }));

            setStatesList(states);
            setState([]);
            return;
        }

        setCountry(selectedCountries);
        if (selectedCountries.length > 0) {
            const selectedCountryIsoCode = getIsoCode(selectedCountries[selectedCountries.length - 1]);
            const states = State.getStatesOfCountry(selectedCountryIsoCode).map((state) => ({
                label: state.name,
                value: state.name,
            }));

            setStatesList(statesList.concat(states));
        } else {
            setStatesList([]);
        }
        setState([]);
    };

    return (
        <div
            className={classNames("w-full grow", {
                "flex flex-col justify-between": isFiltersSubpage,
            })}
        >
            <div className={"mb-6 flex w-full flex-col grow px-2.5 2xl:px-6 pt-6" + (visible ? "" : " hidden")}>
                <div className="w-full z-10 sticky top-0 pt-2 bg-white flex flex-col justify-end">
                    <div className="flex w-full flex-row items-center justify-between">
                        <div className="flex items-center gap-2">
                            <p className="font-Outfit text-[18px] 2xl:test-[20px] text-stone-950">Filters</p>
                            {activeFilterCount ? (
                                <div className="w-[52px] px-[2px] py-[4px] flex justify-evenly items-center border rounded-full border-black border-opacity-20">
                                    <p className="font-Outfit text-[14px] 2xl:text-[16px] font-normal leading-[1.2] text-black">
                                        {activeFilterCount}
                                    </p>
                                    <button onClick={onClearFilterClicked}>
                                        <CloseSVGIcon className="w-[19px] h-[19px] opacity-20" />
                                    </button>
                                </div>
                            ) : null}
                        </div>

                        <div className="flex flex-row items-center gap-2 2xl:gap-3">
                            {/*<div*/}
                            {/*  onClick={onClearFilterClicked}*/}
                            {/*  className="font-Outfit relative cursor-pointer select-none text-[12px] 2xl:text-[14px] text-stone-350 hover:text-stone-950 focus:text-stone-950 transition-colors"*/}
                            {/*>*/}
                            {/*  Clean filters*/}
                            {/*  <CheckIcon*/}
                            {/*    className={classNames(*/}
                            {/*      "w-[20px] h-[20px] absolute -left-[30%] transform translate-y-1/2 -top-1/2 transition-opacity",*/}
                            {/*      {*/}
                            {/*        "opacity-100": isShowSuccessfullyClearedIcon,*/}
                            {/*        "opacity-0": !isShowSuccessfullyClearedIcon,*/}
                            {/*      },*/}
                            {/*    )}*/}
                            {/*  />*/}
                            {/*</div>*/}
                            <button
                                onClick={() => {
                                    setIsAdvancedModalOpen(true);
                                }}
                                className="rounded-[8px] py-[6px] px-[16px] 2xl:py-[8px] 2xl:px-[20px] border bg-accents-blue2 font-Outfit leading-[1.4] text-white focus:text-accents-blue2 focus:bg-transparent focus:border-accents-blue2 hover:text-accents-blue2 hover:bg-transparent hover:border-accents-blue2 transition-colors"
                            >
                                Advanced Filters
                            </button>
                            <Tooltip title="Save Search" placement="right">
                                <button onClick={handleSaveSearch}>
                                    <SaveIcon className="w-[30px] h-[30px] text-transparent hover:text-black focus:text-black transition-colors" />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {isFiltersSubpage ? (
                        <button
                            onClick={() => setIsExpandAllTitles((prev) => !prev)}
                            className="font-Outfit font-normal leading-[1.2] text-[12px] 2xl:text-[14px] text-right mt-[20px] mb-[15px] 2xl:mt-[30px] 2xl:mb-[20px]"
                        >
                            {isExpandAllTitles ? "Hide all" : "Expand All"}
                        </button>
                    ) : null}
                </div>
                {isFiltersSubpage ? (
                    <div className="flex w-full flex-col items-center justify-between">
                        <AccordianComponent
                            title="Contact info"
                            isSectionComponent={true}
                            titleStyles="filters-accordion-title"
                            expand={isFromSavedFiltersPage && (name || country?.length || personalLocation)}
                            expandAll={isExpandAllTitles}
                        >
                            <AccordianComponent
                                title="Full Name"
                                icon={UserIcon}
                                expand={isFromSavedFiltersPage && name}
                                isAddBorder={false}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={name}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setName(e.target.value)}
                                        className="w-full"
                                        placeholder="First and last name"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Country"
                                icon={EarthDreamIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && country?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        value={country}
                                        options={countryList}
                                        onFilterChange={(selectedCountries) => {
                                            handleCountryChange(selectedCountries);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="State/Province"
                                icon={EarthDreamIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && state?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        value={state}
                                        options={statesList}
                                        onFilterChange={(selectedStates) => {
                                            setState(selectedStates);
                                        }}
                                        placeholder={!country?.length && "Please select a country first."}
                                        disabled={!country?.length}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Personal Location"
                                icon={LocationIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && personalLocation}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        multiple={true}
                                        value={personalLocation}
                                        clearTrigger={clearTrigger}
                                        searchBy="location"
                                        onNameFilterChange={(selectedLocations) => {
                                            setPersonalLocation(selectedLocations);
                                            console.log(personalLocation);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                        </AccordianComponent>
                        <AccordianComponent
                            title="Job info"
                            isSectionComponent={true}
                            titleStyles="filters-accordion-title"
                            expand={isFromSavedFiltersPage && (title || level?.length || dept?.length || subDepartment?.length)}
                            expandAll={isExpandAllTitles}
                        >
                            <AccordianComponent
                                title="Job Title"
                                icon={DepartmentIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && title?.length}
                            >
                                <AutocompleteInput
                                    value={title}
                                    clearTrigger={clearTrigger}
                                    multiple={true}
                                    searchBy="title"
                                    onNameFilterChange={(ids) => setTitle(ids)}
                                />
                            </AccordianComponent>
                            <AccordianComponent
                                title="Job Level"
                                icon={DepartmentIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && level?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        value={level}
                                        options={levelList}
                                        onFilterChange={(value) => {
                                            setLevel(value);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Department"
                                isAddBorder={false}
                                icon={BuildIcon}
                                expand={isFromSavedFiltersPage && dept?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        value={dept}
                                        options={departmentList}
                                        onFilterChange={(value) => {
                                            setDept(value);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Sub-Department"
                                icon={BuildIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && subDepartment?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        value={subDepartment}
                                        options={handleSubDepartmentList(dept)}
                                        onFilterChange={(value) => setSubDepartment(value)}
                                    />
                                </div>
                            </AccordianComponent>
                        </AccordianComponent>
                        <AccordianComponent
                            title="Company info"
                            isSectionComponent={true}
                            titleStyles="filters-accordion-title"
                            expand={
                                isFromSavedFiltersPage &&
                                (companyName?.length || website || industryName?.length || revenue || companyLocation)
                            }
                            expandAll={isExpandAllTitles}
                        >
                            <AccordianComponent
                                title="Company Name"
                                icon={HomeIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && companyName?.length}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={companyName}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="company"
                                        onNameFilterChange={(company) => setCompanyName(company)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Website"
                                icon={WebsiteAdvancedIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && website}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={website}
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        isWebsiteAutocomplete={true}
                                        searchBy="website"
                                        onNameFilterChange={(website) =>
                                            setWebsite(website?.name || website?.meta?.website || website || "")
                                        }
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Industry"
                                icon={LocationIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && industryName?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={industryName}
                                        options={industryList}
                                        onFilterChange={(selectedIndustry) => {
                                            setIndustryName(selectedIndustry);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Company Revenue"
                                icon={USDIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && revenue}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        value={revenue}
                                        options={revenueList}
                                        onFilterChange={(value) => setRevenue(value)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Size"
                                icon={DepartmentIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && numberOfEmployees?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={numberOfEmployees}
                                        options={numberOfEmployeesList}
                                        onFilterChange={(value) => setNumberOfEmployees(value)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Funding Raised"
                                icon={LevelIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && numberOfEmployees}
                            >
                                <div className="mt-1 w-full grid grid-cols-2 gap-2">
                                    <GrayTextField
                                        value={fundingRaised.min}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setFundingRaised((prev) => ({
                                                ...prev,
                                                min: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Min USD"
                                        InputProps={FilterInputProps}
                                    />
                                    <GrayTextField
                                        value={fundingRaised.max}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setFundingRaised((prev) => ({
                                                ...prev,
                                                max: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Max USD"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Company Location"
                                icon={LocationIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && companyLocation}
                            >
                                <div className="mt-1 flex w-full flex-col gap-1">
                                    <AutocompleteInput
                                        value={companyLocation}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="location"
                                        onNameFilterChange={(location) => setCompanyLocation(location?.name || location || "")}
                                    />
                                </div>
                            </AccordianComponent>
                        </AccordianComponent>
                        <AccordianComponent
                            title="Social Presence"
                            isSectionComponent={true}
                            titleStyles="filters-accordion-title"
                            expand={isFromSavedFiltersPage && linkedinUrl}
                            expandAll={isExpandAllTitles}
                        >
                            <AccordianComponent
                                title="Linkedin URL"
                                icon={LinkIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && linkedinUrl}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={linkedinUrl}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setLinkedinUrl(e.target.value)}
                                        className="w-full"
                                        placeholder="linkedin.com/in/somename"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                        </AccordianComponent>
                    </div>
                ) : null}
            </div>
            {isFiltersSubpage ? (
                <div
                    className={
                        "lg:sticky bottom-0 z-10 flex w-full flex-row items-center justify-between border-y border-l border-stone-250 bg-white" +
                        (visible ? "" : " hidden")
                    }
                >
                    <Button
                        onClick={handleSearch}
                        className="font-OutfitMedium m-6 mt-3 w-full rounded-md bg-stone-950 px-2 py-2 text-base font-normal capitalize text-white 2xl:px-2"
                    >
                        Search
                    </Button>
                </div>
            ) : null}
            {!isFiltersSubpage ? (
                <SavedSearchesList
                    handleSetFilters={handleSetFilters}
                    savedFilters={savedFilters}
                    setUpdateSearchFilters={setUpdateSearchFiltersSubpage}
                />
            ) : null}
            <AdvancedFilters
                open={isAdvancedModalOpen}
                onClose={() => {
                    setIsAdvancedModalOpen(false);
                }}
            >
                <div className="grid grid-cols-2 border-t border-stone-250 mt-[20px] 2xl:mt-[30px]">
                    <div className="advanced-filters-grid-item border-r border-stone-250">
                        <div className="advanced-filters-group-box">
                            <p className="advanced-search-filters-sub-title font-OutfitSemiBold">Contact info</p>
                            <AccordianComponent
                                isAddBorder={true}
                                title="Gender"
                                icon={GenderIcon}
                                expand={isFromSavedFiltersPage && gender}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        value={gender}
                                        options={genderList}
                                        onFilterChange={(selectedGenders) => {
                                            setGender(selectedGenders);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={true}
                                title="Total Years of Experience"
                                icon={CalendarIcon}
                                expand={isFromSavedFiltersPage && (totalYearsOfExperience.min || totalYearsOfExperience.max)}
                            >
                                <div className="mt-1 w-full grid grid-cols-2 gap-2">
                                    <GrayTextField
                                        value={totalYearsOfExperience.min}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setTotalYearsOfExperience((prev) => ({
                                                ...prev,
                                                min: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Min years"
                                        InputProps={FilterInputProps}
                                    />
                                    <GrayTextField
                                        value={totalYearsOfExperience.max}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setTotalYearsOfExperience((prev) => ({
                                                ...prev,
                                                max: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Max years"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={true}
                                title="Languages Spoken"
                                icon={GlobesIcon}
                                expand={isFromSavedFiltersPage && languagesSpoken?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={languagesSpoken}
                                        options={languageList}
                                        onFilterChange={(selectedLanguages) => {
                                            setLanguagesSpoken(selectedLanguages);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Skills"
                                isAddBorder={true}
                                icon={SkillLevel}
                                expand={isFromSavedFiltersPage && skills?.length}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={skills}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="skill"
                                        onNameFilterChange={(skill) => setSkills(skill)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Work Email (beta)"
                                isAddBorder={true}
                                icon={MailIcon}
                                expand={isFromSavedFiltersPage && workEmail}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={workEmail}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setWorkEmail(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Mobile Number (beta)"
                                isAddBorder={true}
                                icon={PhoneIcon}
                                expand={isFromSavedFiltersPage && mobilePhone}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={mobilePhone}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setMobilePhone(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Continent"
                                isAddBorder={true}
                                icon={EarthIcon}
                                expand={isFromSavedFiltersPage && continent}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        value={continent}
                                        options={continentList}
                                        onFilterChange={(selectedContinents) => {
                                            setContinent(selectedContinents);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Personal Email (beta)"
                                icon={MailIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && personalEmail}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={personalEmail}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setPersonalEmail(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                        <div className="advanced-filters-group-box">
                            <p className="advanced-search-filters-sub-title font-OutfitSemiBold">Education</p>
                            <AccordianComponent
                                title="Certification"
                                icon={CertificationIcon}
                                expand={isFromSavedFiltersPage && certification}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={certification}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setCertification(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="School Name"
                                icon={SchoolIcon}
                                isAddBorder={true}
                                expand={isFromSavedFiltersPage && schoolName}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={schoolName}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="school"
                                        onNameFilterChange={(school) => setSchoolName(school?.name || school || "")}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Degree Name"
                                icon={EditIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && degree?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={degree}
                                        options={degreeList}
                                        onFilterChange={(value) => {
                                            setDegree(value);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                        <div className="advanced-filters-group-box">
                            <p className="advanced-search-filters-sub-title font-OutfitSemiBold">Social Presence</p>
                            <AccordianComponent title="GitHub URL" icon={LinkIcon} expand={isFromSavedFiltersPage && gitHubUrl}>
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={gitHubUrl}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setGitHubUrl(e.target.value)}
                                        className="w-full"
                                        placeholder="github.com/some_name"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="GitHub User Name"
                                icon={EditUserIcon}
                                expand={isFromSavedFiltersPage && gitHubUsername}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={gitHubUsername}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setGitHubUsername(e.target.value)}
                                        className="w-full"
                                        placeholder="some_name"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Facebook URL"
                                icon={LinkIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && facebookUrl}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={facebookUrl}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setFacebookUrl(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                    </div>
                    <div className="advanced-filters-grid-item">
                        <div className="advanced-filters-group-box">
                            <p className="advanced-search-filters-sub-title font-OutfitSemiBold">Company info</p>
                            <AccordianComponent
                                title="Actual Employee Range"
                                icon={RangeIcon}
                                expand={isFromSavedFiltersPage && (actualEmployeeRange?.min || actualEmployeeRange.max)}
                            >
                                <div className="mt-1 w-full grid grid-cols-2 gap-2">
                                    <GrayTextField
                                        value={actualEmployeeRange.min}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setActualEmployeeRange((prev) => ({
                                                ...prev,
                                                min: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Min employees"
                                        InputProps={FilterInputProps}
                                    />
                                    <GrayTextField
                                        value={actualEmployeeRange.max}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setActualEmployeeRange((prev) => ({
                                                ...prev,
                                                max: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Max employees"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="HQ Continent"
                                icon={EarthIcon}
                                expand={isFromSavedFiltersPage && hqContinent?.length}
                            >
                                <div className="mt-1 w-full ">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={hqContinent}
                                        options={continentList}
                                        onFilterChange={(selectedContinents) => {
                                            setHqContinent(selectedContinents);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="HQ County"
                                icon={EarthDreamIcon}
                                expand={isFromSavedFiltersPage && hqCountry?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={hqCountry}
                                        options={countryList}
                                        onFilterChange={(selectedGenders) => {
                                            setHqCountry(selectedGenders);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            {/*<AccordianComponent*/}
                            {/*  title="Company Growth Rate"*/}
                            {/*  isAddBorder={false}*/}
                            {/*  icon={GrowthRate}*/}
                            {/*>*/}
                            {/*  <div className="mt-1 w-full">*/}
                            {/*    <GrayTextField*/}
                            {/*      value={companyGrowthRate}*/}
                            {/*      onKeyDown={(evt) => {*/}
                            {/*        if (evt.keyCode === 13) onSearchButtonClicked();*/}
                            {/*      }}*/}
                            {/*      onChange={(e) => setCompanyGrowthRate(e.target.value)}*/}
                            {/*      className="w-full"*/}
                            {/*      placeholder=""*/}
                            {/*      InputProps={FilterInputProps}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*</AccordianComponent>*/}
                            {/*<AccordianComponent*/}
                            {/*  title="Exclude Europe"*/}
                            {/*  isAddBorder={false}*/}
                            {/*  icon={EuIcon}*/}
                            {/*>*/}
                            {/*  <div className="mt-1 w-full">*/}
                            {/*    <GrayTextField*/}
                            {/*      value={companyGrowthRate}*/}
                            {/*      onKeyDown={(evt) => {*/}
                            {/*        if (evt.keyCode === 13) onSearchButtonClicked();*/}
                            {/*      }}*/}
                            {/*      onChange={(e) => setCompanyGrowthRate(e.target.value)}*/}
                            {/*      className="w-full"*/}
                            {/*      placeholder=""*/}
                            {/*      InputProps={FilterInputProps}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*</AccordianComponent>*/}
                            <AccordianComponent
                                title="Numbers of Months in Current Role"
                                icon={CalendarIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && numbersOfMonthInCurrentRole}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={numbersOfMonthInCurrentRole}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setNumbersOfMonthInCurrentRole(value);
                                        }}
                                        className="w-full"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                        <div className="advanced-filters-group-box">
                            <p className="advanced-search-filters-sub-title font-OutfitSemiBold">Past Job Info</p>
                            <AccordianComponent title="Job Title" icon={IndustryIcon} expand={isFromSavedFiltersPage && jobTitle?.length}>
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={jobTitle}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="title"
                                        onNameFilterChange={(title) => setJobTitle(title)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent title="Job Level" icon={IndustryIcon} expand={isFromSavedFiltersPage && jobLevel?.length}>
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={jobLevel}
                                        options={levelList}
                                        onFilterChange={(selectedJobLevels) => {
                                            setJobLevel(selectedJobLevels);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Department"
                                icon={BuildIcon}
                                expand={isFromSavedFiltersPage && jobDepartment?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={jobDepartment}
                                        options={departmentList}
                                        onFilterChange={(selectedDepartment) => {
                                            setJobDepartment(selectedDepartment);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Sub-Department"
                                icon={BuildIcon}
                                expand={isFromSavedFiltersPage && jobSubDepartment?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={jobSubDepartment}
                                        options={handleSubDepartmentList(jobDepartment)}
                                        onFilterChange={(selectedSubDepartments) => {
                                            setJobSubDepartment(selectedSubDepartments);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Website"
                                icon={WebsiteAdvancedIcon}
                                expand={isFromSavedFiltersPage && companyWebsite}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={companyWebsite}
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        isWebsiteAutocomplete={true}
                                        searchBy="website"
                                        onNameFilterChange={(website) =>
                                            setCompanyWebsite(website?.name || website?.meta?.website || website || "")
                                        }
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Continent"
                                icon={EarthIcon}
                                expand={isFromSavedFiltersPage && companyContinent?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={companyContinent}
                                        options={continentList}
                                        onFilterChange={(selectedContinents) => {
                                            setCompanyContinent(selectedContinents);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Country"
                                icon={EarthDreamIcon}
                                expand={isFromSavedFiltersPage && companyCountry?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={companyCountry}
                                        options={countryList}
                                        onFilterChange={(selectedCountries) => {
                                            setCompanyCountry(selectedCountries);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={true}
                                title="Listing"
                                icon={ListingIcon}
                                expand={isFromSavedFiltersPage && companyListing?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={companyListing}
                                        options={listingList}
                                        onFilterChange={(selectedList) => {
                                            setCompanyListing(selectedList);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>

                            <AccordianComponent
                                title="Company name"
                                icon={EditIcon}
                                expand={isFromSavedFiltersPage && pastCompanyName?.length}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={pastCompanyName}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="company"
                                        onNameFilterChange={(company) => setPastCompanyName(company)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Industry"
                                icon={IndustryIcon}
                                expand={isFromSavedFiltersPage && companyIndustry?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={companyIndustry}
                                        options={industryList}
                                        onFilterChange={(selectedIndustries) => {
                                            setCompanyIndustry(selectedIndustries);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={false}
                                title="Previous Company HQ Location"
                                icon={LocationIcon}
                                expand={isFromSavedFiltersPage && previousCompanyHqLocation}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={previousCompanyHqLocation}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="location"
                                        onNameFilterChange={(title) => setPreviousCompanyHqLocation(title?.name || title || "")}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-[500px] lg:fixed lg:mx-auto lg:left-1/2 lg:transform lg:-translate-x-1/2 flex justify-center bottom-10 bg-white">
                    <Button
                        onClick={() => {
                            handleSearch();
                            setIsAdvancedModalOpen(false);
                        }}
                        className="font-OutfitMedium m-6 w-full max-w-[500px] rounded-md bg-stone-950 px-2 py-2 text-base font-normal capitalize text-white 2xl:px-2"
                    >
                        Search
                    </Button>
                </div>
            </AdvancedFilters>
            <SaveSearchDialog
                open={isSaveSearchModalOpen}
                close={() => {
                    setIsSaveSearchModalOpen(false);
                }}
                title={saveSearchTitle}
                isSaving={isSavingLoading}
                setTitle={setSaveSearchTitle}
                onSave={handleSaveSearch}
            />
        </div>
    );
}

export { ContactFilterComponent };
