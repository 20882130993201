import React, { useEffect, useState, useRef } from "react";
import TrendDashboard from "./TrendDashboard";
import DailyCollectedProfiles from "./DailyCollectedProfiles";
import VisitorsAnalytics from "./VisitorsAnalytics";
import SelectDropdown from "@/components/common/SelectDropdown";

function Analytics({ data, allowedDomains }) {
    const [newProfiles, setNewProfiles] = useState(0);
    const [returningProfiles, setReturingProfiles] = useState(0);
    const [newProfilesByDays, setNewProfilesByDays] = useState([]);
    const [returningProfilesByDays, setReturningProfilesByDays] = useState([]);
    const [duration, setDuration] = useState(7);
    const [domain, setDomain] = useState(allowedDomains[0]);
    const [domainOptions, setDomainOptions] = useState([]);
    const newProfilesRef = useRef(0);
    const returningProfilesRef = useRef(0);

    const setProfileNumbers = () => {
        if (!data) {
            return;
        }

        const oneDurationAgo = new Date();
        oneDurationAgo.setDate(oneDurationAgo.getDate() - duration);
        const oneDurationAgoTimestamp = oneDurationAgo.getTime();
        const ipsInDateRange = [];

        data = data.filter((obj) => obj.domain === domain);

        const tempDomains = [];
        allowedDomains.map((domain) => {
            tempDomains.push({ label: domain, value: domain });
        });
        setDomainOptions(tempDomains);

        data.map((obj) => {
            if (!ipsInDateRange.includes(obj.ip) && obj.created_at >= oneDurationAgoTimestamp) {
                ipsInDateRange.push(obj.ip);
            }
        });

        const returningIPs = [];

        data.map((obj) => {
            if (ipsInDateRange.includes(obj.ip) && !returningIPs.includes(obj.ip) && obj.created_at < oneDurationAgoTimestamp) {
                returningIPs.push(obj.ip);
            }
        });

        const newIPs = ipsInDateRange.filter((ip) => !returningIPs.includes(ip));

        returningProfilesRef.current = returningIPs.length;
        newProfilesRef.current = newIPs.length;

        setNewProfiles(newProfilesRef.current);
        setReturingProfiles(returningProfilesRef.current);

        const lastDurationData = data.filter((obj) => obj.created_at >= oneDurationAgo);
        lastDurationData.sort((a, b) => a.created_at - b.created_at);

        const newDataFrequencies = new Array(duration).fill(0);
        const returningDataFrequencies = new Array(duration).fill(0);
        const newIPsTraversed = {};
        const returningIPsTraversed = {};
        const now = new Date().toISOString().split("T")[0];

        lastDurationData.map((obj) => {
            if (newIPs.includes(obj.ip)) {
                const date = new Date(obj.created_at).toISOString().split("T")[0];
                const nowDateObj = new Date(now);
                const createdAtDateObj = new Date(date);
                if (!newIPsTraversed[obj.ip]) {
                    const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                    newDataFrequencies[dayIndex]++;
                    newIPsTraversed[obj.ip] = date;
                } else if (newIPsTraversed[obj.ip] < date) {
                    const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                    returningDataFrequencies[dayIndex]++;
                    newIPsTraversed[obj.ip] = date.slice(0, 10);
                }
            } else if (returningIPs.includes(obj.ip)) {
                const date = new Date(obj.created_at).toISOString().split("T")[0];
                const nowDateObj = new Date(now);
                const createdAtDateObj = new Date(date);
                if (!returningIPsTraversed[obj.ip]) {
                    const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                    returningDataFrequencies[dayIndex]++;
                    returningIPsTraversed[obj.ip] = date;
                } else if (returningIPsTraversed[obj.ip] < date) {
                    const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                    returningDataFrequencies[dayIndex]++;
                    returningIPsTraversed[obj.ip] = date;
                }
            }
        });
        setNewProfilesByDays(newDataFrequencies);
        setReturningProfilesByDays(returningDataFrequencies);
    };

    useEffect(() => {
        setProfileNumbers();
    }, [duration, domain]);
    return (
        <>
            <div className="px-20">
                <section className="flex flex-wrap gap-10 justify-between items-center leading-tight">
                    <header className="flex flex-col self-stretch my-auto min-w-[240px] text-stone-950 w-[313px]">
                        <h1 className="text-2xl font-medium">Analytics</h1>
                        <p className="mt-2 text-base text-gray-700 md:whitespace-nowrap">
                            Analysis of your website traffic for the past {duration === 7 ? "week" : "month"} for {domain}
                        </p>
                    </header>
                    <div className="flex gap-4 flex-col md:flex-row">
                        <SelectDropdown options={domainOptions} selectedOption={domain} defaultValue={domain} onChange={setDomain} />
                        <SelectDropdown
                            options={[
                                { label: "Past 7 days", value: 7 },
                                { label: "Past 30 days", value: 30 },
                            ]}
                            selectedOption={duration}
                            defaultValue={duration}
                            onChange={setDuration}
                        />
                    </div>
                </section>
                <TrendDashboard newProfiles={newProfiles} returningProfiles={returningProfiles} />
                <DailyCollectedProfiles
                    newProfiles={newProfiles}
                    newProfilesByDays={newProfilesByDays}
                    returningProfilesByDays={returningProfilesByDays}
                    duration={duration}
                />
                <VisitorsAnalytics />
            </div>
        </>
    );
}

export default Analytics;
