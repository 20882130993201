import React from "react";
import ExportsTable from "./ExportsTable";
import ExportButton from "./ExportButton";

function ProfileExport({ data, csvs }) {
    return (
        <div>
            <section className="mb-6 flex flex-wrap gap-10 justify-between items-center">
                <header className="flex flex-col self-stretch my-auto leading-tight min-w-[240px] text-stone-950 w-[313px]">
                    <h1 className="text-2xl font-medium">Profile Export</h1>
                    <p className="text-base text-gray-700 md:whitespace-nowrap">Generate an export to be downloaded to your device.</p>
                </header>
                <div className="flex gap-4">
                    {/* <div className="flex gap-10 items-center px-4 py-2 text-base leading-tight rounded border border-solid border-black border-opacity-10 text-stone-950 text-opacity-50">
                        <div className="flex gap-2 justify-center items-center self-stretch my-auto">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/341c291c385adfac83a7137336bf4e7b5b8c0ca7c23cd0ceca6460d52cece012?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79" alt=""
                                className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                            />
                            <span>Select a date range</span>
                        </div>
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8869c21b02a8f09f51e28541e50d254aea4b87cae988e5209ee24d0e284029e?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79" alt=""
                            className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                        />
                    </div> */}
                    <ExportButton data={data} />
                </div>
            </section>
            <ExportsTable csvs={csvs} />
        </div>
    );
}

export default ProfileExport;
