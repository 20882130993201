import React from "react";

function ActionButtons({ saveICPFilter, discardChanges }) {
    return (
        <div className="flex gap-10 mb-10 justify-between items-center pt-8 mt-8 w-full border-t border-black border-opacity-10 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col self-stretch my-auto leading-tight min-w-[240px] w-[693px] max-md:max-w-full">
                <h2 className="text-base font-medium text-stone-950 max-md:max-w-full">Apply filter to Integration(s)</h2>
                <p className="mt-2 text-xs text-neutral-400 max-md:max-w-full">
                    If applied, only the leads that match your ICP will sync to the integration(s) you choose, else all profiles will sync
                    to the integration(s).
                </p>
            </div>
            <div className="flex gap-6 items-center self-stretch my-auto text-sm font-medium leading-snug min-w-[240px]">
                <button
                    className="self-stretch px-3 py-2.5 my-auto bg-white rounded-lg border border-solid border-zinc-100 min-h-[40px] text-stone-950 w-[156px]"
                    onClick={discardChanges}
                >
                    Discard Changes
                </button>
                <button
                    className="self-stretch px-3 py-2.5 my-auto text-white rounded-lg bg-stone-950 min-h-[40px] w-[156px]"
                    onClick={saveICPFilter}
                >
                    Save ICP Filter
                </button>
            </div>
        </div>
    );
}

export default ActionButtons;
