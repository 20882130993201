function PlanDetails(props) {
    const { stripePrice } = props;

    return (
        <div className="font-Outfit text-sm leading-[1.4] text-stone-950 flex flex-col gap-1 h-[76px]">
            <span>{stripePrice.credits.emails + " Email credits"}</span>
            <span>{stripePrice.credits.phones + " Phone credits"}</span>
            <span>{stripePrice.credits.validations + " Email validation credits"}</span>
        </div>
    );
}

export default PlanDetails;
