import dayjs from "dayjs";

export default function SummaryComponent({ creditsUsage, planInfo, teamsInfo, isTeamMember, isTeamOwner, teamInfo, navigate }) {
    const canBuyCredits = isTeamOwner && planInfo?.name === "Enterprise";
    return (
        <div className="flex w-full flex-col items-center justify-start gap-4 p-4 2xl:p-6 pl-0">
            <div className="font-Outfit self-stretch text-md 2xl:text-lg text-black">Summary</div>
            <div className="flex w-full flex-col items-center justify-start gap-4 ">
                <div className="flex flex-col items-center justify-start gap-2 self-stretch">
                    <div className="font-Outfit self-stretch text-sm font-normal text-stone-350">Your plan</div>
                    <div className="flex h-12 flex-col items-start justify-start gap-2 self-stretch">
                        <div className="font-Outfit leading-snug text-base font-medium text-black">
                            {isTeamMember ? (
                                <>
                                    You are part of <b>{teamInfo?.parentUser?.userName}</b>'s Team{" "}
                                </>
                            ) : (
                                `${planInfo?.name ?? "Free"}${planInfo?.isPlannedForCancellation ? " (Cancelled)" : ""}`
                            )}
                        </div>
                        {!isTeamMember && (
                            <div className="inline-flex items-start justify-start gap-1 self-stretch">
                                <div className="font-Outfit leading-none text-xs font-normal text-stone-350">
                                    {planInfo?.isPlannedForCancellation ? "Cancellation on" : "Renewal on"}
                                </div>
                                <div className="font-Outfit leading-none shrink grow basis-0 text-xs font-normal text-black">
                                    {dayjs(planInfo?.renewalDate).format("MMM D, YYYY h:mm A")}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <hr className="h-px w-full border-0 bg-stone-250" />
                {!isTeamMember && (
                    <>
                        <div className="flex flex-col items-center justify-start gap-2 self-stretch">
                            <div className="font-Outfit leading-tight self-stretch text-sm font-normal text-stone-350">Team members</div>
                            <div className="flex h-12 flex-col items-start justify-start gap-2 self-stretch">
                                <div className="font-Outfit leading-snug text-base font-medium text-black">
                                    {teamsInfo?.used} / {teamsInfo?.total}
                                </div>
                                <button
                                    className="font-Outfit cursor-pointer cursor-pointer text-sm font-normal leading-md text-blue-500 xl:text-base"
                                    onClick={() => {
                                        navigate(teamsInfo?.total - teamsInfo?.used >= 1 ? "/team" : "/upgrade");
                                    }}
                                >
                                    {teamsInfo?.total - teamsInfo?.used >= 1
                                        ? "Invite members to your team"
                                        : "Upgrade to invite more members"}
                                </button>
                            </div>
                        </div>
                        <hr className="h-px w-full border-0 bg-stone-250" />
                    </>
                )}
                <div className="flex flex-col items-center justify-start gap-2 self-stretch border-b">
                    <div className="font-Outfit  self-stretch text-sm font-normal text-stone-350">Credits</div>

                    <div className="flex h-9 flex-col items-start justify-start gap-2 self-stretch">
                        <div className="inline-flex items-center justify-between self-stretch">
                            <div className="font-Outfit text-sm 2xl:text-base font-medium text-black">Phone credits used</div>
                            <div className="font-Outfit text-right text-sm 2xl:text-base font-medium text-black">
                                {creditsUsage?.phone?.used} / {creditsUsage?.phone?.total}
                            </div>
                        </div>
                        <div className="relative h-1.5 self-stretch rounded bg-stone-250">
                            <div
                                className="absolute left-0 top-0 h-1.5 rounded bg-blue-500"
                                style={{
                                    width: `${(creditsUsage?.phone?.used / creditsUsage?.phone?.total) * 100}%`,
                                }}
                            ></div>
                        </div>
                    </div>

                    <div className="mt-2 flex h-9 flex-col items-start justify-start gap-2 self-stretch">
                        <div className="inline-flex items-center justify-between self-stretch">
                            <div className="font-Outfit text-sm 2xl:text-base font-medium text-black">Email credits used</div>
                            <div className="font-Outfit text-right text-sm 2xl:text-base font-medium text-black">
                                {creditsUsage?.email?.used} / {creditsUsage?.email?.total}
                            </div>
                        </div>
                        <div className="relative h-1.5 self-stretch rounded bg-stone-250">
                            <div
                                className="absolute left-0 top-0 h-1.5 rounded bg-accents-orange"
                                style={{
                                    width: `${(creditsUsage?.email?.used / creditsUsage?.email?.total) * 100}%`,
                                }}
                            ></div>
                        </div>
                    </div>

                    <div className="mt-2 flex h-9 flex-col items-start justify-start gap-2 self-stretch">
                        <div className="inline-flex items-center justify-between self-stretch">
                            <div className="font-Outfit text-sm 2xl:text-base font-medium text-black">Validation credits used</div>
                            <div className="font-Outfit  text-right text-sm 2xl:text-base font-medium text-black">
                                {creditsUsage?.validation?.used} / {creditsUsage?.validation?.total}
                            </div>
                        </div>
                        <div className="relative h-1.5 self-stretch rounded bg-stone-250">
                            <div
                                className="absolute left-0 top-0 h-1.5  rounded bg-blue-500"
                                style={{
                                    width: `${(creditsUsage?.validation?.used / creditsUsage?.validation?.total) * 100}%`,
                                }}
                            ></div>
                        </div>
                    </div>

                    {canBuyCredits && (
                        <button
                            className="font-Outfit leading-tight
            self-start
           text-sm font-normal text-blue-500"
                            onClick={() => {
                                navigate("/credits");
                            }}
                        >
                            Buy more credits
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
