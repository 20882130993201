import { useState, useEffect } from "react";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import useBulk from "./useBulk";
import { capitalizeString } from "@/utils/common";
const suggestions = {
    general: {
        recreated: "There was an error while updating / creating object, so it was recreated",
        reject: "There was an error while updating / creating object",
        cancelled: "Contact wasn't uploaded due to unknown issue",
    },
    Salesforce: {
        duplicate: "There is likely a same / similar object in you Salesforce that is not managed by Kompass, delete it and try again",
        update: "An entry with same data was found and updated, without changing type of SF object",
        cancelled: "Contact wasn't uploaded due to issues present in a batch, most likely there were identical contacts",
    },
    Hubspot: {
        duplicate:
            "This batch had an contact that was a duplicate of Hubspot contact, check for duplications in batch or Hubspot and try again",
        update: "A contact with the same data was found and updated",
    },
};

export function useFormatUploadResults() {
    const { uploads, targetEntity, results, profileType, target: targetService } = useCRMPushResultStore();

    const { bulkEnrichOutputs } = useBulk();
    const [result, setResult] = useState([]);

    useEffect(() => {
        const effect = async () => {
            let parsedResults = [];

            if (results) {
                let uploadsSelect = uploads;
                if (profileType === "bulk") {
                    const profiles = await getResultsBulk(uploads, bulkEnrichOutputs);
                    uploadsSelect = profiles;
                    parsedResults = parseResults(results);
                } else {
                    parsedResults = parseResults(results);
                }

                const formattedResults = constructTableRows(parsedResults, uploadsSelect, targetEntity, targetService);

                setResult(formattedResults);
            }
        };
        effect();
    }, [results]);
    return result;
}

const getResultsBulk = async (uploads, request) => {
    const ids = uploads.map((item) => {
        return item._id;
    });
    const profiles = (
        await Promise.all(
            ids.map(async (id) => {
                const bulkHistoryResults = await request(id);
                return bulkHistoryResults.data.filter((t) => !!t);
            })
        )
    ).flat(1);
    return profiles;
};

const parseResults = (result) => {
    //nulls here mean, that there was an attempt at creating, which failed, look for details in rejects or retries of errors
    const added = result.new?.created
        .map((item) => {
            return {
                linkedinUrl: item?.id_link,
                entity: item?.entity,
            };
        })
        .filter((t) => !!t);
    const updated = result.updates?.updated
        .map((item) => {
            return {
                linkedinUrl: item?.id_link,
                entity: item?.entity,
            };
        })
        .filter((t) => !!t);
    const addedErrors = result.new?.errors;
    const updatedErrors = result.updates?.errors;
    const parsedAddedErrors = parseErrors(addedErrors);
    const parsedUpdatedErrors = parseErrors(updatedErrors);
    return {
        addedErrors: parsedAddedErrors,
        updatedErrors: parsedUpdatedErrors,
        added,
        updated,
    };
};
const parseErrors = (errors) => {
    if (!errors) return { duplicates: [], rejects: [], removals: [], recreations: [], cancellations: [] };
    const { leads: leadsErrors, contacts: contactsErrors } = errors;
    let duplicates = [];
    let rejects = [];
    let removals = [];
    let recreations = [];
    let cancellations = [];

    const handleErrors = (err) => {
        if (!err) return;
        const { retries: errRetries, removals: errRemovals, rejects: errRejects } = err;
        errRejects.forEach((reject) => {
            if (reject.reason === "duplicate") {
                duplicates.push(reject.contact.linkedinUrl || reject.contact.linkedin_url);
            }
            if (reject.reason === "cancelled" || reject.reason === "duplicate_composite") {
                cancellations.push(reject.contact.linkedinUrl || reject.contact.linkedin_url);
            }
        });
        errRemovals.forEach((removal) => {
            removals.push(removal.contact.linkedin_url);
        });
        errRetries.forEach((retry) => {
            const {
                duplicates: retryD,
                rejects: retryR,
                removals: retryRE,
                recreations: retryRC,
                cancellations: retryCL,
            } = parseRetry(retry);
            duplicates = duplicates.concat(retryD);
            rejects = rejects.concat(retryR);
            removals = removals.concat(retryRE);
            recreations = recreations.concat(retryRC);
            cancellations = cancellations.concat(retryCL);
        });
    };

    handleErrors(leadsErrors);
    handleErrors(contactsErrors);
    return { duplicates, rejects, removals, recreations, cancellations };
};
const parseRetry = (retry) => {
    const { contacts, leads } = retry;
    let recreations = [...contacts.created];
    if (leads) {
        recreations.push(...leads.created);
    }
    recreations = recreations.map((item) => item?.id_link).filter((t) => !!t);
    const {
        duplicates: duplicatesContacts,
        rejects: rejectsContacts,
        removals: removalsContacts,
        cancellations: cancellationsContacts,
    } = parseErrors(contacts?.errors);
    const {
        duplicates: duplicatesLeads,
        rejects: rejectsLeads,
        removals: removalsLeads,
        cancellations: cancellationsLeads,
    } = parseErrors(leads?.errors);
    return {
        duplicates: [...duplicatesContacts, ...duplicatesLeads],
        rejects: [...rejectsContacts, ...rejectsLeads],
        removals: [...removalsContacts, ...removalsLeads],
        cancellations: [...cancellationsContacts, ...cancellationsLeads],
        recreations,
    };
};
const constructTableRows = (parseResults, uploads, targetEntity, targetService) => {
    let rows = [];
    const { addedErrors, updatedErrors, added, updated } = parseResults;
    const base = {
        uploads,
        current: targetEntity,
        target: targetEntity,
        suggestion: "",
        status: "Unknown",
    };

    rows = rows.concat(constructErrorRows(addedErrors, base, targetService));
    rows = rows.concat(constructErrorRows(updatedErrors, base, targetService));
    rows = rows.concat(
        added?.map(({ linkedinUrl, entity }) => {
            const baseCopy = { ...base };
            baseCopy.linkedinUrl = linkedinUrl;
            baseCopy.status = "Created";
            baseCopy.current = entity === "leads" ? "Lead" : "Contact";
            return constructRow(baseCopy);
        })
    );

    rows = rows.concat(
        updated?.map(({ linkedinUrl, entity }) => {
            const baseCopy = { ...base };
            baseCopy.linkedinUrl = linkedinUrl;
            baseCopy.status = "Updated";
            baseCopy.suggestion = suggestions[targetService].update;
            baseCopy.current = entity === "leads" ? "Lead" : "Contact";
            return constructRow(baseCopy);
        })
    );

    return rows;
};

const constructErrorRows = (errors, base, targetService) => {
    const { duplicates, recreations, removals, rejects, cancellations } = errors;
    const rows = [];
    duplicates.forEach((linkedinUrl) => {
        const baseCopy = { ...base };
        baseCopy.linkedinUrl = linkedinUrl;
        baseCopy.suggestion = suggestions[targetService].duplicate;
        baseCopy.status = "Rejected";
        baseCopy.current = "-";
        const row = constructRow(baseCopy);
        rows.push(row);
    });

    recreations.forEach((linkedinUrl) => {
        const baseCopy = { ...base };
        baseCopy.linkedinUrl = linkedinUrl;
        baseCopy.suggestion = suggestions[targetService]?.recreated || suggestions.general?.recreated;
        baseCopy.status = "Created";
        baseCopy.current = base.target;
        const row = constructRow(baseCopy);
        rows.push(row);
    });

    cancellations.forEach((linkedinUrl) => {
        const baseCopy = { ...base };
        baseCopy.linkedinUrl = linkedinUrl;
        baseCopy.suggestion = suggestions[targetService]?.cancelled || suggestions.general?.cancelled;
        baseCopy.status = "Rejected";
        baseCopy.current = "-";
        const row = constructRow(baseCopy);
        rows.push(row);
    });
    rejects.forEach((linkedinUrl) => {
        const baseCopy = { ...base };
        baseCopy.linkedinUrl = linkedinUrl;
        baseCopy.suggestion = suggestions[targetService]?.reject || suggestions.general.reject;
        baseCopy.status = "Rejected";
        baseCopy.current = "-";
        const row = constructRow(baseCopy);
        rows.push(row);
    });
    return rows;
};

const constructRow = (options, profileType) => {
    const { uploads, linkedinUrl, target, current, status, suggestion } = options;
    const contact = findContact(uploads, linkedinUrl);
    if (!contact) return null;

    let name = "";
    if (contact?.name) {
        name = capitalizeString(contact.name, " ");
    } else if (contact?.firstName || contact?.lastName) {
        const firstName = contact?.firstName ? capitalizeString(contact.firstName, " ") : "";
        const lastName = contact?.lastName ? capitalizeString(contact.lastName, " ") : "";
        name = `${firstName} ${lastName}`.trim();
    }

    return {
        name,
        company: contact?.companyName
            ? capitalizeString(contact?.companyName, " ")
            : contact?.company?.name
              ? capitalizeString(contact?.company.name, " ")
              : "",
        entity: target,
        currentEntity: current,
        status,
        suggestions: suggestion,
    };
};

const findContact = (uploads, linkedinUrl) => {
    return uploads.find((contact) => {
        return contact?.linkedin === linkedinUrl || contact?.linkedin_url === linkedinUrl;
    });
};
