import * as React from "react";
import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { CircularProgress, TextField } from "@mui/material";
import FilterDropdown from "@/components/common/FilterDropdown";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { toast } from "react-toastify";
import useKompassSearch from "@/hooks/useKompassSearch";
import useList from "@/hooks/useList";
import { CONTACT_LIST_TYPE } from "@/utils/constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "10px",
    },
}));

export default function SaveToListDialog({ open, close, params }) {
    const { postSaveKompassSearchContacts } = useKompassSearch();
    const { createContactList } = useList();
    const { listData, contactList } = params;
    const [listTitle, setListTitle] = React.useState(null);
    const [newListTitle, setNewListTitle] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const onSave = async () => {
        if (listTitle == null || listTitle?.length === 0) {
            toast.error("Please select a list or create a new one.", {
                theme: "colored",
            });
            return;
        }

        setSaving(true);
        const result = await postSaveKompassSearchContacts({
            listTitle,
            listData,
        });
        setSaving(false);

        if (result.status) {
            toast.success(result.message, { theme: "colored" });
            close(true);
        } else toast.error(result.message, { theme: "colored" });
    };

    const onSaveToNewList = async () => {
        if (newListTitle.trim().length === 0) {
            toast.error("Please enter the new list name.", { theme: "colored" });
            return;
        }

        setSaving(true);
        const createListResult = await createContactList({
            listTitle: newListTitle,
            listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
        });
        if (createListResult.status) {
            toast.success(`"${newListTitle}" Contact List has been created.`, {
                theme: "colored",
            });
            const saveToListResult = await postSaveKompassSearchContacts({
                listTitle: newListTitle,
                listData,
            });
            if (saveToListResult.status) {
                toast.success(saveToListResult.message, { theme: "colored" });
                close(true);
            } else {
                toast.error(saveToListResult.message, { theme: "colored" });
            }
        } else {
            toast.error(createListResult.message, { theme: "colored" });
        }
        setSaving(false);
    };

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={close}>
            <button onClick={close} className="absolute right-0 top-0 flex flex-row justify-end pr-4 pt-4">
                <CloseSVGIcon className="relative h-4 w-4" />
            </button>
            <DialogContent>
                <div className="font-Outfit p-3 text-center text-2xl font-bold text-black">Select a list</div>
                <div className="font-Outfit text-md p-1 text-left text-black">List names</div>
                <div className="min-w-[400px]">
                    <FilterDropdown
                        options={
                            contactList
                                ? contactList.map((contact) => {
                                      return {
                                          label: contact.listTitle,
                                          value: contact.listTitle,
                                      };
                                  })
                                : [{ label: "123123" }]
                        }
                        onChange={(value) => {
                            setListTitle(value);
                        }}
                    />
                </div>
                <div className="font-Outfit text-md p-1 mt-2 text-left text-black">Create new list</div>
                <div className="mt-1 min-w-[400px]">
                    <TextField
                        className="w-full"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                fontSize: "13px",
                                fontFamily: "Outfit",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "1px",
                            },
                            "& .MuiOutlinedInput-input": {
                                fontSize: "16px",
                                padding: "14px",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                            },
                        }}
                        placeholder="List name"
                        value={newListTitle}
                        onChange={(e) => {
                            setNewListTitle(e.target.value);
                        }}
                    />
                </div>
                <div className="font-Outfit mt-4 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
                    <Button
                        disabled={saving}
                        className="font-Outfit flex items-center justify-center rounded-full border-[1px] border-none bg-blue-500 py-2 text-base text-white disabled:bg-[#929292]"
                        onClick={newListTitle ? onSaveToNewList : onSave}
                    >
                        {saving ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "white" }} /> : null}
                        Save
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
