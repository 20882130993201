import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import { debounce } from "lodash";
import { Button } from "@material-tailwind/react";
import { toast } from "react-toastify";

import useStripe from "@/hooks/useStripe";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useHTTPRequestObject } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import usePlan from "@/hooks/usePlan";

import MainLayout from "@/components/MainLayout";

import ArrowLeftIcon from "../../assets/image/icons/Arrow_Left.svg";

import CustomCreditsSelector from "@/components/pricing/CustomCreditsSelector";
import useBillingStore from "@/store/useBillingStore";
import BackdropLoader from "@/components/common/BackdropLoader";
import { UserSlider } from "@/components/usersslider/UserSlider";

export default function PlanUpgradeScene() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location?.state?.redirected) {
            navigate("/upgrade", { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { userInfo } = useContext(AuthContext);

    const { selectedPrices, plan, users } = useBillingStore();

    const [loading, setLoading] = useState(false);
    const [stripeError, setStripeError] = useState("");
    const [cost, setCost] = useState(null);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if (!updated) {
            return;
        }

        const debouncedNavigate = debounce((value) => {
            navigate(value);
        }, 3000);

        debouncedNavigate("/home");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated]);

    const annual = selectedPrices.main.interval === "year";
    const customizable = plan.may_be_customized === 1;

    const lookupKeys = Object.values(selectedPrices)
        .map((t) => t?.lookup_key)
        .filter(Boolean);

    const { getPaymentLink } = useStripe();
    const { getPlanPreview } = usePlan();
    const getPaymentMethods = useHTTPRequestObject({
        link: "/billing/getPaymentMethods",
    });

    const { data: paymentMethodsData } = useQuery({
        queryKey: ["PaymentMethods"],
        queryFn: () => getPaymentMethods(),
    });

    const fetchPlanPreview = async () => {
        if (!lookupKeys) {
            return;
        }

        try {
            const requestData = {
                lookupKeys,
                userId: userInfo?._id,
                quantity: users,
            };

            setLoading(true);
            const planPreview = await getPlanPreview(requestData);

            const { dueToday, totalCost } = planPreview.data;
            setCost({ dueToday: dueToday / 100, totalCost: totalCost / 100 });
        } catch (error) {
            toast.error(`Failed. ${error.message}`, {
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!lookupKeys?.length || !users) {
            return;
        }

        fetchPlanPreview();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, selectedPrices]);

    const getCardData = () => {
        if (!paymentMethodsData) {
            return null;
        }

        const paymentData = paymentMethodsData?.data;

        if (!paymentData?.length) {
            return null;
        }

        return {
            card: paymentData[0]?.card?.last4,
            paymentSystem: paymentData[0]?.card?.brand,
        };
    };

    const handleUpgrade = async () => {
        if (!selectedPrices) {
            return;
        }

        try {
            setLoading(true);
            setStripeError("");

            if (selectedPrices) {
                const filteredPrices = Object.values(selectedPrices).filter((t) => !!t?.lookup_key);

                let res = await getPaymentLink(filteredPrices, users);

                if (!res.status) {
                    setStripeError(res.message);
                    toast.error(`Failed. ${res.message}`, {
                        theme: "colored",
                    });
                } else {
                    setUpdated(true);
                    toast.success("Plan updated successfully. You will be redirected in 3 seconds", { theme: "colored" });
                }
            }
        } catch (error) {
            toast.error(`Failed. ${error.message}`, {
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    const params = new URLSearchParams();
    params.set("mode", "customize_credits");
    params.set("product", "enrich");
    const customCreditsInfoFunction = useHTTPRequest(`/billing/getTariffs?` + params.toString(), "GET", true);
    const { data: customCreditsInfo } = useQuery({
        queryKey: ["CustomCredits"],
        queryFn: async () => await customCreditsInfoFunction(),
        enabled: !!customizable,
    });

    const cardData = getCardData();

    return (
        <MainLayout containerClassName="items-center">
            <div
                className="font-Outfit leading-loose flex w-full items-center bg-white
        px-10 pb-4 pt-4 text-2xl text-black drop-shadow-xl filter md:px-16 "
            >
                <button className="flex items-center" onClick={() => navigate(-1)}>
                    <img src={ArrowLeftIcon} className="mr-2 inline text-blue-500" alt="" />
                    <span className="text-m font-Outfit leading-tight cursor-pointer font-normal text-blue-500">Back</span>
                </button>
                <div className="ml-10">Payment</div>
            </div>

            {loading && <BackdropLoader active={true}></BackdropLoader>}

            {!customizable && (
                <div className="mt-4 gap-4 px-5 w-full">
                    <UserSlider />
                </div>
            )}

            {customizable && customCreditsInfo && (
                <div className="mt-4 grid grid-cols-2 items-start justify-center gap-4 px-4">
                    <UserSlider />

                    <CustomCreditsSelector
                        annual={annual}
                        currentPlan={plan}
                        optionsKey="customize_phone_credits"
                        priceKey="phones"
                        sliderTitle="Phone credits"
                        customCreditsInfo={customCreditsInfo?.data}
                        timeout={200}
                    />

                    <CustomCreditsSelector
                        annual={annual}
                        currentPlan={plan}
                        priceKey="emails"
                        optionsKey="customize_email_credits"
                        sliderTitle="Email credits"
                        customCreditsInfo={customCreditsInfo?.data}
                        timeout={200}
                    />

                    <CustomCreditsSelector
                        annual={annual}
                        currentPlan={plan}
                        priceKey="validations"
                        optionsKey="customize_email_validation_credits"
                        sliderTitle="Validation credits"
                        customCreditsInfo={customCreditsInfo?.data}
                        timeout={200}
                    />
                </div>
            )}

            <div className="w-full mt-8 px-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="flex flex-row items-center w-full justify-between px-10 max-[480px]:flex-col max-[480px]:gap-4">
                    <div className="flex flex-col gap-4 max-[480px]:w-full ">
                        <span className="font-Outfit select-none text-2xl text-black ">Summary</span>
                        <span className="font-Outfit select-none text-2xl text-black font-semibold  max-[480px]:text-right">
                            {plan?.name} Plan + {users} Users
                        </span>
                    </div>

                    <div className="font-Outfit select-none text-2xl text-black font-semibold max-[480px]:w-full max-[480px]:text-right">
                        {!!cost ? `${cost.totalCost}$/${selectedPrices.main?.interval}` : null}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between px-10 border-l-0 lg:border-l-1 border-stone-250 ">
                    <div className="flex flex-row md:flex-col justify-between md:justify-start gap-4 w-full">
                        <div className="font-Outfit select-none text-2xl text-black">Due Today</div>
                        <div className="font-Outfit select-none text-2xl text-black font-semibold">
                            {!!cost ? `$${cost.dueToday}` : null}
                        </div>
                    </div>
                    {!!paymentMethodsData?.length ? (
                        <Button
                            onClick={() => navigate("/settings")}
                            className={`text-md font-Outfit mt-6 flex w-[280px] items-center justify-center self-center rounded-lg bg-black p-[16px_24px] font-[700] uppercase tracking-wide text-white transition-all  `}
                        >
                            Add payment method
                        </Button>
                    ) : (
                        <div>
                            <Button
                                onClick={handleUpgrade}
                                className={`text-md font-Outfit mt-6 flex w-[280px] items-center justify-center self-center rounded-lg bg-black p-[16px_24px] font-[700] uppercase tracking-wide text-white transition-all  `}
                            >
                                Upgrade
                            </Button>
                            {!!cardData && (
                                <div className="mt-2 text-center font-Outfit text-xs 2xl:text-sm text-stone-350">
                                    Using {cardData?.paymentSystem} card ending in {cardData?.card}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {(stripeError.length || !selectedPrices) && (
                <div className="flex w-full  flex-col items-center justify-center">
                    <div className="my-24 flex w-5/12 flex-col gap-3 rounded-md border-[1px] border-[#E6E6E6] p-10">
                        <div className="font-Outfit flex flex-col self-stretch text-center text-3xl font-extrabold">
                            Sorry, we got problems
                        </div>
                        <div className="font-Outfit flex-wrap text-center text-lg">{stripeError}</div>
                        <div className="font-Outfit mt-10 flex-wrap text-center text-lg">
                            <Button
                                onClick={() => navigate(-1)}
                                className="font-Outfit h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-[20px] font-medium capitalize text-white"
                            >
                                Go back
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </MainLayout>
    );
}
