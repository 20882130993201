import LandingLayout from "@/components/LandingLayout";
import { useResizeDetector } from "react-resize-detector";
import ProfileHeader from "./components/ProfileHeader";
import MainLoader from "@/components/MainLoader";
import "./style.css";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useProfile from "@/hooks/useProfile";
const ProfileAbout = React.lazy(() => import("./components/ProfileAbout"));
const ProfileComppany = React.lazy(() => import("./components/ProfileCompany"));
const ProfileSearch = React.lazy(() => import("./components/ProfileSearch"));
const ProfileFaq = React.lazy(() => import("./components/ProfileFaq"));
const ProfileContact = React.lazy(() => import("./components/ProfileContact"));
const ProfileLinkedIn = React.lazy(() => import("./components/ProfileLinkedIn"));
const ProfileSmilarProfiles = React.lazy(() => import("./components/ProfileSmilarProfiles"));

export default function Profile() {
    const { username } = useParams();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getKompassProfile } = useProfile();
    const { ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: "debounce",
        refreshRate: 1000,
    });

    //Selecting a plan by clicking a button in a table or on a Plan card
    const canonicalUrl = "https://www.kompassai.com/profile/" + username;

    const ogData = {
        title: "KompassAI Profile",
        url: "https://www.kompassai.com/profile/" + username,
        image: "https://www.kompassai.com/images/kompassai-logo.png",
        siteName: "KompassAI",
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const res = await getKompassProfile({
                type: "url",
                userName: "",
                userId: "",
                url: username?.split("-email-phone")[0],
            });
            if (!res.status) {
                setLoading(false);
                return;
            }
            if (res.data.city === null) res.data.city = "***";
            if (res.data.state === null) res.data.state = "***";
            if (res.data.country === null) res.data.country = "***";
            if(res.data.lastName === null) res.data.lastName = "";
            setUserData(res.data);
            setLoading(false);
        };
        fetchData();
    }, [username]);
    return (
        <LandingLayout>
            <Helmet>
                <meta property="og:title" content={`${userData?.firstName} ${userData?.lastName} | KompassAI Profile`} />
                <meta
                    property="og:description"
                    content={`KompassAI profile page for ${userData?.firstName} ${userData?.lastName} | ${userData?.title}`}
                />
                <meta property="og:url" content={ogData.url} />
                <meta property="og:image" content={ogData.image} />
                <meta property="og:site_name" content={ogData.siteName} />
                <link rel="canonical" href={canonicalUrl} />
                <title>{`${userData?.firstName} ${userData?.lastName} | KompassAI Profile`}</title>
                <meta name="title" content={`${userData?.firstName} ${userData?.lastName} | KompassAI Profile`} />
                <meta
                    name="description"
                    content={`KompassAI profile page for ${userData?.firstName} ${userData?.lastName} | ${userData?.title}`}
                />
            </Helmet>
            {loading ? <MainLoader /> :
             userData ? (
                <div className="w-full flex justify-center profile-container relative bg-[#F4F4F6]">
                    <div className="absolute top-0 left-0 w-full h-[323px] md:h-[300px] bg-gradient-to-r from-[rgba(72,115,250,1)] to-[rgba(105,86,191,1)] via-[rgba(171,177,235,1)] opacity-50"></div>
                    <div
                        ref={ref}
                        className="flex flex-col items-center pt-12 md:pt-[3.75rem] pb-[4.75rem] max-w-[1280px] z-50 px-4 md:px-[3rem]"
                    >
                        <div class="w-full xl:mb-[50px] mb-[60px]">
                            <ProfileHeader {...userData} />
                        </div>
                        <div className="flex flex-col lg:flex-row w-full gap-8 ">
                            <div className="lg:w-2/3 flex flex-col gap-8">
                                <ProfileAbout {...userData} />
                                <ProfileComppany {...userData} />
                                <ProfileSearch {...userData} setLoading={setLoading} />
                                <ProfileFaq {...userData} />
                            </div>
                            <div className="lg:w-1/3 flex flex-col gap-8">
                                <ProfileContact {...userData} setLoading={setLoading} />
                                <ProfileLinkedIn {...userData} />
                                <ProfileSmilarProfiles {...userData} />
                            </div>
                        </div>
                    </div>
                </div>
            ):
            <div className="flex justify-center items-center min-h-screen">
                <p>No profile found.</p>
            </div>
            }
        </LandingLayout>
    );
}
