import { useContext } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Menu from "@mui/material/Menu";
import { AuthContext } from "@/context/AuthContext";
import { profilePopupNavList, filterNavListByPermissions } from "@/utils/common";
import { getShortName } from "@/utils/common";
import { ReactComponent as HelpIcon } from "@/assets/image/profile-popup/help.svg";
import { ReactComponent as Logout } from "@/assets/image/profile-popup/logout.svg";
import { useQuery } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import useKompassRouter from "@/hooks/useKompassRouter";

export default function ProfilePopup({ anchorEl, handleClose, handleLogout }) {
    const { userInfo, isTeamOwner } = useContext(AuthContext);

    const { kompassNavigate } = useKompassRouter();
    const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed?", "GET");

    const { data } = useQuery({
        queryKey: ["CreditsUsage"],
        queryFn: () => getUsageData(),
    });

    const subscriptionInfoFunction = useHTTPRequest(`/billing/getSubscriptionInfo`);

    const { data: subscriptionInfo } = useQuery({
        queryKey: ["Subscription"],
        queryFn: () => subscriptionInfoFunction(),
    });

    const getDividedByTotal = (data) => {
        let percentage =
            (data?.data?.emails.used + data?.data?.phones.used + data?.data?.validations.used) /
            (data?.data?.emails.total + data?.data?.phones.total + data?.data?.validations.total);

        let result = 0;

        switch (true) {
            case percentage <= 0:
                result = `0/12`;
                break;
            case percentage < 0.0833 && percentage > 0:
                result = `1/12`;
                break;
            case percentage < 0.1666 && percentage >= 0.0833:
                result = `2/12`;
                break;
            case percentage < 0.25 && percentage >= 0.1666:
                result = `3/12`;
                break;
            case percentage < 0.3333 && percentage >= 0.25:
                result = `4/12`;
                break;
            case percentage < 0.4166 && percentage >= 0.3333:
                result = `5/12`;
                break;
            case percentage < 0.5 && percentage >= 0.4166:
                result = `6/12`;
                break;
            case percentage < 0.5833 && percentage >= 0.5:
                result = `7/12`;
                break;
            case percentage < 0.6666 && percentage >= 0.5833:
                result = `8/12`;
                break;
            case percentage < 0.75 && percentage >= 0.6666:
                result = `9/12`;
                break;
            case percentage < 0.8333 && percentage >= 0.75:
                result = `10/12`;
                break;
            case percentage < 0.9166 && percentage >= 0.8333:
                result = `11/12`;
                break;
            case percentage <= 1 && percentage >= 0.9166:
                result = `full`;
                break;
            default:
                result = `0/12`;
                break;
        }

        return result;
    };

    const isEnterprise = subscriptionInfo?.data?.plan === "Enterprise";

    const canBuyCredits = isEnterprise && isTeamOwner;

    const popupNavLinks = filterNavListByPermissions({
        list: profilePopupNavList,
        permission: userInfo?.permission,
    }).filter((el) => (!canBuyCredits ? el.router !== "/credits" : true));

    const CreditLine = ({ widthClass = "full" }) => {
        return <div className={`w-${widthClass} absolute left-0 top-0 h-1.5 rounded bg-blue-500`} />;
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
                elevation: 0,
                sx: {
                    borderRadius: "20px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    "& .MuiList-root": {
                        ml: 1,
                        mr: 1,
                    },
                },
            }}
        >
            <div className="flex min-w-[202px] xl:min-w-[222px] 2xl:min-w-[282px] flex-col justify-center gap-4 bg-white p-4">
                {/* The Avatar & Name */}
                <div className="flex w-full flex-row gap-2">
                    <div className="font-Outfit flex h-10 w-10 items-center justify-center rounded-full bg-bluegrey-200 text-md 2x:text-lg font-extrabold text-blue-500">
                        {getShortName(userInfo?.userName)}
                    </div>
                    <div className="flex flex-col">
                        <div className="text-base 2xl:text-md font-Outfit font-bold">{userInfo?.userName}</div>
                        <div className="font-Outfit text-start text-xs 2xl:text-sm text-stone-350">{userInfo?.email}</div>
                    </div>
                </div>
                {/* The Credits */}
                <div className="flex w-full flex-col gap-2">
                    <div className="flex h-9 flex-col items-start justify-start gap-2 self-stretch">
                        <div className="inline-flex items-center justify-between self-stretch">
                            <div className="font-Outfit text-sm font-bold">Credits</div>
                            <div className="font-Outfit text-sm font-bold">
                                {data?.data?.emails.used + data?.data?.phones.used + data?.data?.validations.used} /{" "}
                                {data?.data?.emails.total + data?.data?.phones.total + data?.data?.validations.total}
                            </div>
                        </div>
                        <div className="relative h-1.5 self-stretch">
                            <div className="absolute left-0 top-0 h-1.5 w-full rounded bg-[#929292] opacity-10" />
                            <CreditLine widthClass={getDividedByTotal(data)} />
                        </div>
                    </div>
                </div>

                <hr className="my-2 w-full bg-stone-250" />

                <div className="flex w-full flex-col justify-start gap-0">
                    {popupNavLinks.map((item, index) => {
                        return (
                            <Link key={index} to={item.router}>
                                <div className="font-Outfit text-base 2xl:text-md flex w-full cursor-pointer flex-row items-center gap-1 rounded-full bg-none px-2 py-1 text-center transition-all duration-300 hover:bg-gray-200">
                                    <ReactSVG src={item.icon} />
                                    <p>{item.label}</p>
                                </div>
                            </Link>
                        );
                    })}
                </div>

                <hr className="my-2 w-full bg-stone-250" />

                <div className="flex w-full flex-col items-center justify-start gap-0">
                    <div
                        onClick={() => kompassNavigate("/faq")}
                        className="font-Outfit text-base 2xl:text-md flex w-full cursor-pointer flex-row items-center gap-1 rounded-full bg-none px-2 py-1 text-center transition-all duration-300 hover:bg-gray-200"
                    >
                        <HelpIcon />
                        <p>Help</p>
                    </div>
                    <div
                        className="font-Outfit text-base 2xl:text-md flex w-full cursor-pointer flex-row items-center gap-1 rounded-full bg-none px-2 py-1 text-center transition-all duration-300 hover:bg-gray-200"
                        onClick={handleLogout}
                    >
                        <Logout />
                        <p>Log out</p>
                    </div>
                </div>
            </div>
        </Menu>
    );
}
