import * as React from "react";
import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { ReactComponent as WarningIcon } from "@/assets/image/email-validation/warning.svg";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import BackdropLoader from "../common/BackdropLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
    },
}));

//TODO: Create a base modal for both this and PaymentResultModal
export default function ConfirmationModal(props) {
    const { showState, modal, loading } = props;
    const { icon, title, message, footnotes, handleConfirm } = modal;
    const [show, setShow] = showState;
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClose = () => {
        setShow(false);
    };

    return (
        <BootstrapDialog fullScreen={fullScreen} open={show} className="relative">
            <BackdropLoader active={loading}></BackdropLoader>
            <button
                onClick={() => {
                    setShow(false);
                }}
                className="absolute right-4 top-4 flex flex-row justify-end"
            >
                <CloseSVGIcon className="relative h-4 w-4" />
            </button>
            <DialogContent className="!p-8">
                <div className="flex flex-row justify-center">
                    <div className={"flex h-16 w-16 items-center justify-center rounded-[50%] bg-black p-3"}>{icon}</div>
                </div>
                <div className="flex flex-col items-center justify-start gap-2 self-stretch">
                    <div className="font-OutfitBold leading-loose self-stretch text-center text-3xl  text-stone-950">{title}</div>
                    <div className="font-OutfitMedium leading-snug mt-4 self-stretch text-center text-lg  text-stone-950">{message}</div>
                </div>
                <div className="font-Outfit mt-8 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
                    {/*TODO: Add states */}
                    <Button
                        className="font-Outfit flex  items-center justify-center rounded-[8px] border-[1px] border-[#4873FA] bg-[#4873FA] py-[12px] text-sm uppercase tracking-wide text-white"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="font-Outfit flex  items-center justify-center rounded-[8px] border-[1px] border-red-500 bg-red-500 py-[12px] text-sm uppercase tracking-wide text-white"
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                </div>
                {footnotes?.length && (
                    <div className=" mt-6 flex flex-col items-start gap-1 self-start">
                        {footnotes.map((note) => {
                            return <span className="font-Outfit flex items-center justify-center text-sm opacity-50">- {note}</span>;
                        })}
                    </div>
                )}
            </DialogContent>
        </BootstrapDialog>
    );
}
