import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

export default function AccountDetails({ userId, allowedDomains, updateDomains }) {
    const [availableCredits, setAvailableCredits] = useState(0);
    const [usedCredits, setUsedCredits] = useState(0);
    const [subscriptionInfo, setSubscriptionInfo] = useState(null);

    const getUserCredits = useHTTPRequest("/credits/getUserCreditsUsed", "GET");
    const getSubscriptionInfo = useHTTPRequest(`/billing/getSubscriptionInfo?product=ip`);

    const { data: creditsData } = useQuery({
        queryKey: ["CreditsUsage"],
        queryFn: () => getUserCredits(),
    });

    const { data: subscriptionData } = useQuery({
        queryKey: ["Subscription"],
        queryFn: () => getSubscriptionInfo(),
    });

    useEffect(() => {
        if (creditsData?.data) {
            setAvailableCredits(creditsData?.data?.ips?.available || 0);
            setUsedCredits(creditsData?.data?.ips?.used || 0);
        }
    }, [creditsData]);

    useEffect(() => {
        if (subscriptionData?.data) {
            setSubscriptionInfo(subscriptionData?.data);
        }
    }, [subscriptionData]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            domain: "",
        },
        validationSchema: Yup.object({
            domain: Yup.string()
                .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/, "Invalid domain")
                .required("Required"),
        }),
        onSubmit: async (values) => {
            if (!allowedDomains.includes(values.domain.trim())) {
                const updatedDomains = [...allowedDomains, values.domain.trim()];
                await updateDomains(updatedDomains);
            } else {
                console.log("Domain already exists");
            }
            formik.resetForm();
        },
    });

    const handleDomainDelete = async (domain) => {
        const updatedDomains = allowedDomains.filter((d) => d !== domain);
        await updateDomains(updatedDomains);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                formik.submitForm();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [formik]);

    return (
        <>
            <div className="font-Outfit text-stone-950 px-10 pb-7 border-b border-stone-250">
                <h2 className="text-[20px] xl:text-[22px] 2xl:text-[24px] leading-[1.2] font-medium mb-2">Website Visitor Report Setup</h2>
                <p className="text-[12px] xl:text-[14px] leading-[1.2] font-light opacity-70">
                    To enable the IP deanonymization feature, please copy the script from the field above and paste it into the head tag of
                    the website where IP parsing should be enabled. Additionally, to ensure security, you should add the domain of this
                    website to the list of allowed domains in the field above.
                </p>
            </div>
            <div className="font-Outfit text-stone-950 px-10 py-8 flex flex-col gap-10">
                <div className="flex flex-col gap-2">
                    <label className="text-[18px] font-normal">
                        <span className="font-semibold">Script tag </span>(copy the script and paste it into the head tag of the website
                        where IP parsing should be enabled)
                    </label>
                    <textarea
                        className="max-w-[800px] px-4 py-2 rounded-[8px] border border-stone-250 focus:outline-none opacity-90 text-[16px] font-light leading-[1.4] resize-none	"
                        value={`<script async src="${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/collect-data?userId=${userId}"></script>`}
                        readOnly
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-[18px] font-normal">
                        <span className="font-semibold">Allowed domains </span>(add domain of the website where IP parsing should be enabled
                        to the list of allowed domains)
                    </label>
                    {allowedDomains && allowedDomains.length > 0 && (
                        <div className="flex gap-3 max-w-[800px]">
                            {allowedDomains.map((domain) => (
                                <div key={domain} className="relative bg-[#f6f6f6] rounded-[4px] text-[16px] text-stone-950 px-3 py-1.5">
                                    <p>{domain}</p>
                                    <button
                                        className="absolute right-[-3px] top-[-11px]"
                                        onClick={async () => await handleDomainDelete(domain)}
                                    >
                                        x
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    <input
                        value={formik.values.domain}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        name="domain"
                        placeholder="domain.com"
                        className="max-w-[800px] px-4 py-2 rounded-[8px] border border-stone-250 focus:outline-none opacity-90 text-[16px] font-light leading-[1.4] resize-none	"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[18px] font-semibold">Remaining credits</p>
                    <p>{availableCredits}</p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[18px] font-semibold">Used credits</p>
                    <p>{usedCredits}</p>
                </div>
                {subscriptionInfo && (
                    <div className="flex flex-col gap-1">
                        <p className="text-[18px] font-semibold">Subscription details</p>
                        <p className="capitalize">Plan: {subscriptionInfo.plan}</p>
                        <p className="capitalize">Status: {subscriptionInfo.status}</p>
                        <p className="capitalize">Renewal Date: {new Date(subscriptionInfo.renewal_date).toLocaleDateString("en-US")}</p>
                    </div>
                )}
            </div>
        </>
    );
}
