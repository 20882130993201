import BaseContainer from "./BaseContainer";

const MainTitleBar = ({ children }) => {
    return (
        <div className="z-[52] w-full bg-white flex items-end border-b border-gray-960 filter font-Outfit text-2xl font-medium h-[80px] pb-4 leading-[1.2]">
            <BaseContainer>{children}</BaseContainer>
        </div>
    );
};

export default MainTitleBar;
