export default function SortIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <mask
                id="a"
                width={16}
                height={16}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: "alpha",
                }}
            >
                <path fill="#D9D9D9" d="M0 0h16v16H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1C1B1F"
                    d="m8 14-3-3 .967-.967L8 12.066l2.033-2.033L11 11l-3 3ZM5.967 6.033 5 5.066l3-3 3 3-.967.967L8 4 5.967 6.033Z"
                />
            </g>
        </svg>
    );
}
