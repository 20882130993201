import AllTabs from "@/components/ai_engagement/AllTabs";
import React from "react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import Overview from "@/components/ai_engagement/globalAnalyticsTab/Overview";
import EmailSentChart from "@/components/ai_engagement/globalAnalyticsTab/EmailSentChart";
import LeadQuality from "@/components/ai_engagement/globalAnalyticsTab/LeadQuality";
import EmailDeliverReport from "@/components/ai_engagement/globalAnalyticsTab/EmailDeliverReport";

export default function GlobalAnalytics() {
    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white mt-4 pb-10">
                <BaseContainer>
                    <AllTabs />
                    <div className="">
                        <Overview />
                        <EmailSentChart />
                        <LeadQuality />
                        <EmailDeliverReport />
                    </div>
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
