import * as React from "react";

import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";

import { BaseInputProps } from "@/theme/typography";

import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import useAIEngagement from "@/hooks/useAIEngagement";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "10px",
    },
}));

export default function UpdateCapacityDailog({ open, onClose, dataObj = {} }) {
    const [capacity, setCapacity] = React.useState(dataObj?.daily_capacity ?? "");
    const [saving, setSaving] = React.useState(false);

    const { updateCapacityData } = useAIEngagement();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const updateCapacity = async () => {
        try {
            setSaving(true);
            const { emailAddress, account: type = "gmail" } = dataObj || {};
            const response = await updateCapacityData({
                url: `mail/gmail/capacity?is_gmail=${type == "gmail" ? "true" : "false"}&emailAddress=${emailAddress}&capacity=${capacity}`,
            });
            console.log("response", response);
            if (response?.status === true) {
                onClose(true);
                toast.success(response?.message, { theme: "colored" });
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setSaving(false);
        }
    };

    React.useEffect(() => {
        if (open === true) {
            setCapacity(dataObj?.daily_capacity ?? "");
        }
    }, [open]);

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={onClose}>
            <button onClick={onClose} className="absolute right-0 top-0 flex flex-row justify-end pr-4 pt-4">
                <CloseSVGIcon className="relative h-4 w-4" />
            </button>
            <DialogContent>
                <div className="font-Outfit p-3 text-center text-2xl font-bold text-black">Update capacity</div>
                <div className="mt-1 min-w-[400px]">
                    <TextField
                        className="w-full"
                        placeholder="List name"
                        value={capacity}
                        onChange={(e) => {
                            setCapacity(e.target.value);
                        }}
                        InputProps={BaseInputProps}
                    />
                </div>
                <div className="font-Outfit mt-4 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
                    <Button
                        className="font-Outfit flex w-[100px] items-center justify-center rounded-full border-[1px] border-[#000] bg-transparent bg-white py-2 text-base font-light text-black"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={saving}
                        className="font-Outfit flex w-[100px] items-center justify-center rounded-full border-[1px] bg-blue-500 py-2 text-base font-light text-white"
                        onClick={updateCapacity}
                    >
                        {saving ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "white" }} /> : null}
                        Save
                    </Button>
                </div>
            </DialogContent>
            {/* <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Disagree
        </Button>
        <Button onClick={handleClose} autoFocus>
          Agree
        </Button>
      </DialogActions> */}
        </BootstrapDialog>
    );
}
