import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Typography } from "@material-tailwind/react";
import { ThemeProvider, styled } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BootstrapAutocompleteInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderRadius: 7,
        position: "relative",
        backgroundColor: "white",
        borderColor: "#80bdff",
        border: "none",
        fontSize: 12,
        fontFamily: "Outfit",
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 7,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
});

export default function FilterAutocomplete({
    value = null,
    options,
    multiple = false,
    onFilterChange,
    placeholder = null,
    disabled = false,
}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#3b82f6",
                contrastText: "#fff",
                contrast: "#3b82f6",
                light: "#3b82f6",
            },
        },
    });

    // const isAllValuesSelected =
    //   Array.isArray(value) && options?.length === value?.length;

    const handleChange = (event, newValue) => {
        console.log({ newValue });
        if (multiple) {
            let values;
            // const isSelectAllClick = newValue.find(
            //   item => item.value === "select all",
            // );
            // if (isSelectAllClick) {
            //   values = isAllValuesSelected
            //     ? null
            //     : options.map(item => item?.value || item);
            // }

            values = newValue?.map((item) => {
                return item?.value || item;
            });

            onFilterChange(values);
        } else {
            onFilterChange(newValue);
        }
    };

    // const isAllValuesSelected =
    //   Array.isArray(value) && options?.length === value?.length;

    return (
        <StyledAutocomplete
            multiple={multiple}
            sx={{ paddingY: 0 }}
            value={multiple ? options?.filter((v) => value?.findIndex((item) => item === v?.value || item === v) >= 0) : value}
            options={[
                // ...(multiple ? [{ label: "Select all", value: "select all" }] : []),
                ...options,
            ]}
            disableCloseOnSelect={multiple}
            getOptionLabel={(option) => option?.label ?? option}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {multiple ? <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} /> : null}
                    <Typography>{option.label ?? option}</Typography>
                </li>
            )}
            renderInput={(params) => (
                <BootstrapAutocompleteInput
                    {...params}
                    placeholder={placeholder}
                    sx={{ paddingTop: "0px !important", paddingBottom: "0px !important" }}
                />
            )}
            renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                    <ThemeProvider theme={theme} key={index}>
                        <Chip
                            label={option?.label ?? option}
                            size="small"
                            color="primary"
                            {...getTagProps({ index })}
                            sx={{
                                "& span": {
                                    fontFamily: "Outfit",
                                    fontSize: "10px",
                                },
                            }}
                        />
                    </ThemeProvider>
                ))
            }
            onChange={handleChange}
            disabled={disabled}
        />
    );
}
