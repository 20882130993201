import { useState } from "react";
import { ReactSVG } from "react-svg";
import { Button } from "@material-tailwind/react";
import cl from "classnames";
import SettingsModal from "./SettingsModal";
import { ReactComponent as SettingsSVGIcon } from "@/assets/image/integrations/settings.svg";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useHTTPRequestObject } from "@/hooks/useHTTPRequest";
import { toast } from "react-toastify";

export default function IntegrateCardZapier({ info }) {
    const [showSettings, setShowSettings] = useState(false);
    const updateSettings = useHTTPRequestObject({
        link: "/zapier/settings",
        method: "PATCH",
    });

    const getZapierSettingsFunction = useHTTPRequestObject({
        link: "/zapier/settings",
    });
    const {
        data,
        isFetching: loadingSettings,
        refetch,
    } = useQuery({
        queryFn: () => getZapierSettingsFunction(),
        queryKey: ["ZapierSettings"],
        refetchOnWindowFocus: false,
    });

    const { mutateAsync: updateSettingsTrigger, isPending: savingSettings } = useMutation({
        mutationFn: async (data) => await updateSettings(JSON.stringify(data)),
        onSuccess: () => {
            toast.success("Successfully updated settings");
        },
        onError: (error) => {
            console.log(error);
            toast.error("Something went wrong, please try again");
        },
    });

    return (
        <div className="h-full relative flex flex-col gap-4 self-stretch rounded-md border border-stone-250 bg-white p-[16px_32px_32px_32px] transition-all duration-300 hover:m-[-2px] hover:border-blue-500 hover:drop-shadow-lg">
            <SettingsModal
                showState={[showSettings, setShowSettings]}
                updateSettings={updateSettingsTrigger}
                settings={data}
                loading={loadingSettings || savingSettings}
            ></SettingsModal>
            <div className="flex flex-row justify-between">
                <div className="font-Outfit flex cursor-pointer flex-row items-center gap-2 text-left text-md font-bold text-black">
                    <ReactSVG src={info.icon} />
                    <span>{info.label}</span>
                </div>
            </div>
            <hr className="w-full rounded-full bg-stone-250" />
            <div className="font-Outfit 2xl:text-base cursor-pointer text-sm">{info.description}</div>
            <div className="w-full mt-auto gap-4 flex flex-row justify-end">
                <div className="flex justify-end">
                    <Button
                        className={cl(
                            "font-OutfitBold flex items-center justify-center gap-2 rounded-md bg-stone-950 px-4 py-2 text-sm capitalize leading-[100%] text-white"
                        )}
                        onClick={() => {
                            setShowSettings(true);
                            refetch();
                        }}
                    >
                        Settings
                        <div className="flex items-center justify-center w-4 h-4 fill-white stroke-white">
                            <SettingsSVGIcon />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}
