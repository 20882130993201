import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { ReactComponent as DownSVGIcon } from "@/assets/image/icons/down.svg";
import FilterPopup, { filterOptions } from "@/components/popup/FilterPopup";
import { useNavigate } from "react-router-dom";

const gradientColors = {
    linearGradient: [0, 0, 0, 300],
    stops: [
        [0, "rgba(59, 130, 246, 1)"],
        [1, "rgba(59, 130, 246, 0)"],
    ],
};

export default function ContactCreatedComponent({ contactsUsed = [], filter, setFilter, hideFilter = false }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const data = {
            data: contactsUsed?.map((contact) => contact.value),
            categories: contactsUsed
                ?.map((contact, i) => contact.label)
                .map((contact, i) => {
                    if (filter === "THIS_YEAR") {
                        return contact;
                    }
                    return i % 4 === 0 || i === contactsUsed.length - 1 ? contact : "";
                }),
        };

        const chartOptions = {
            chart: {
                type: "area",
            },
            title: {
                text: "",
            },
            plotOptions: {
                area: {
                    fillColor: gradientColors,
                    color: "rgb(59, 130, 246)",
                    marker: {
                        enabled: true,
                        fillColor: "rgb(59, 130, 246)",
                        lineWidth: 2,
                        lineColor: "rgba(59, 130, 246, 0.5)",
                    },
                },
            },
            xAxis: {
                categories: data?.categories,
                labels: {
                    style: {
                        color: "#929292",
                        fontSize: "0.775rem",
                        fontFamily: "Outfit",
                    },
                },
                lineColor: "#E8E7E7",
            },
            yAxis: {
                title: {
                    text: "",
                },
                gridLineColor: "#E8E7E7",
                tickColor: "#E8E7E7",
                labels: {
                    style: {
                        color: "#929292",
                        fontSize: "0.775rem",
                        fontFamily: "Outfit",
                    },
                },
                allowDecimals: false,
            },
            legend: { enabled: false },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: "",
                    data: data?.data,
                },
            ],
        };

        const chart = Highcharts.chart("contactCreatedChart", chartOptions);

        return () => {
            chart.destroy();
        };
    }, [contactsUsed, contactsUsed.length, filter]);

    return (
        <div className="flex w-full flex-col items-center justify-start gap-4 rounded-xl border border-stone-250 bg-white p-4 2xl:p-6">
            <div className="inline-flex w-full shrink basis-0 flex-col items-start justify-start gap-4">
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                    <div className="font-Outfit shrink grow basis-0 text-md 2xl:text-lg font-normal text-black">Contacts Created</div>
                    {hideFilter ? (
                        ""
                    ) : (
                        <div className="flex items-center justify-start gap-4">
                            <button className="flex items-center justify-start gap-2" onClick={(e) => setAnchorEl(e.currentTarget)}>
                                <div className="font-Outfit leading-tight text-sm 2xl:text-base font-normal text-black">
                                    {filterOptions[filter]}
                                </div>
                                <DownSVGIcon className="relative h-4 w-4" />
                            </button>
                            <button
                                className="font-Outfit text-sm 2xl:text-base font-normal text-blue-500"
                                onClick={() => {
                                    navigate("/analytics");
                                }}
                            >
                                View more
                            </button>
                        </div>
                    )}
                </div>
                <div className="font-Outfit leading-tight text-sm font-normal text-neutral-400">Total contacts created over time.</div>
                <div id="contactCreatedChart" className="w-full "></div>
            </div>
            <FilterPopup
                anchorEl={anchorEl}
                handleClose={(value) => {
                    if (value) {
                        setFilter((val) => ({
                            ...val,
                            contactsCreatedFilter: value,
                        }));
                    }
                    setAnchorEl(null);
                }}
            />
        </div>
    );
}
