import React, { useState, useEffect, useRef } from "react";
import useExportProfiles from "@/hooks/useExportProfiles";
import { toast } from "react-toastify";

function ExportButton({ data }) {
    const [dataToExport, setDataToExport] = useState(data);
    const { createExportProfile } = useExportProfiles();
    const createExportProfileRef = useRef();
    createExportProfileRef.current = createExportProfile;

    // Function to convert the array of objects to a CSV string
    function convertArrayToCSV(array) {
        const headers = Object.keys(array[0]).join(","); // Get headers
        const rows = array.map((obj) => Object.values(obj).join(",")); // Get rows
        return [headers, ...rows].join("\n");
    }

    // Function to download CSV file
    async function downloadCSV(csvContent) {
        const filename = new Date().getTime() + "_data.csv";
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        const response = await createExportProfileRef.current({
            filename,
            recordCount: data.length,
            csv_content: data,
            created_at: new Date().getTime(),
        });
        if (!response) {
            toast.error("Failed to export data");
        }
    }

    const startDownload = async () => {
        console.log(dataToExport);
        if (data && data.length) {
            const csvContent = convertArrayToCSV(dataToExport);
            await downloadCSV(csvContent);
        }
    };

    useEffect(() => {
        setDataToExport(data);
    }, [data]);
    return (
        <button
            className="flex gap-2 justify-center items-center self-stretch px-6 py-2 my-auto leading-tight text-white capitalize rounded bg-stone-950 min-h-[40px] max-md:px-5"
            onClick={startDownload}
        >
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/189df7ae9e2c82fe8ea89a0bf1cb4abd96857094fc7fbc0cd97d5e93acbeb1d1?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                alt=""
                className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
            />
            <span className="self-stretch my-auto">Export Profiles</span>
        </button>
    );
}

export default ExportButton;
