import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const THIS_MONTH = "This month";
const LAST_MONTH = "Last month";
const THIS_YEAR = "This year";

export const filterOptions = {
    THIS_MONTH,
    LAST_MONTH,
    THIS_YEAR,
};

const options = [
    {
        label: THIS_MONTH,
        value: "THIS_MONTH",
    },
    {
        label: LAST_MONTH,
        value: "LAST_MONTH",
    },
    {
        label: THIS_YEAR,
        value: "THIS_YEAR",
    },
];

export default function AnalyticCalendarPopup({ anchorEl, handleClose }) {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
            {options.map((item) => {
                return (
                    <MenuItem
                        key={item.value}
                        value={item.value || "THIS_MONTH"}
                        onClick={() => handleClose(item.value)}
                        sx={{
                            "&.MuiMenuItem-root": {
                                fontFamily: "Outfit",
                                textAlign: "start",
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "#090C05",
                            },
                        }}
                    >
                        {item.label}
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
