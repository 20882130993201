import { useMemo } from "react";
import { Slider, styled, Box } from "@mui/material";

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: "#e8e7e7 ",
    "& .MuiSlider-thumb": {
        backgroundColor: "white",
        width: "24px",
        height: "24px",
        boxShadow: "0 4px 8px -2px rgba(16, 24, 40, 0.1)",
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "0 4px 8px 4px rgba(16, 24, 40, 0.1)",
        },
    },
    ".MuiSlider-rail": {
        backgroundColor: "#E8E7E7 ",
        height: "8px",
        border: "none",
    },
    ".MuiSlider-mark": {
        display: "none",
    },
    ".MuiSlider-track": {
        backgroundColor: "#9AB2FF ",
        height: "8px",
        border: "none",
    },
}));

function PricingSlider({ currentPlan, options, name, state, annual }) {
    const [sliderValue, setSliderValue] = state;
    const sliderSubject = Object.keys(options[0].credits)[0];
    const selectedOption = options[sliderValue];

    const formatValues = useMemo(() => {
        const mappedValues = options.map((option, index) => {
            return { value: index };
        });
        return mappedValues;
    }, [options]);

    const formatCredits = (number) => {
        if (number >= 1000000) {
            return String(number / 1000000) + "M";
        } else if (number >= 1000) {
            return String(number / 1000) + "k";
        } else {
            return number;
        }
    };

    return (
        <div className="text-stone-950 font-Outfit flex h-full w-full flex-col rounded-lg border-1 border-stone-250 px-6 py-7">
            <div className="mb-2.5 text-[18px] lg:text-[20px] leading-[1.4]">{name}</div>
            <div className="mb-7 text-[14px] lg:text-[16px] leading-[1.4]">
                {name} are shared across users. Select how many {name} you would like for your team below.
            </div>
            <div className="flex w-full flex-col">
                <div className="flex w-full justify-between gap-5">
                    <div className="select-none text-[16px] text-[18px] leading-[1.4]">
                        <span>{currentPlan?.price?.credits?.[sliderSubject]}</span> (Selected Plan) +{" "}
                        <span className="mr-1">{selectedOption ? formatCredits(selectedOption.credits[sliderSubject]) : 0}</span>
                        {"Credits/" + (annual ? "yr" : "month")}
                    </div>
                    <div className="select-none text-[16px] text-[18px] leading-[1.4]">
                        ${selectedOption ? selectedOption.price / 100 : 0}/{annual ? "year" : "month"}
                    </div>
                </div>

                <StyledSlider
                    value={sliderValue}
                    step={null}
                    min={0}
                    max={options.length - 1}
                    marks={formatValues}
                    onChange={(event, value) => {
                        setSliderValue(value);
                    }}
                ></StyledSlider>

                <Box
                    sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        marginBottom: "25px",
                    }}
                >
                    {options.map((value, index) => {
                        return (
                            <span
                                className="absolute top-0 hidden cursor-pointer select-none md:block"
                                onClick={() => setSliderValue(index)}
                                style={{
                                    left: `${(index / (options.length - 1)) * 100}%`,
                                    transform: `translateX(${!index ? "0%" : index === options.length - 1 ? "-100%" : "-50%"})`,
                                    display: !index || index === options.length - 1 ? "block" : "",
                                }}
                            >
                                <span className="flex items-end gap-[2px] whitespace-nowrap font-medium">
                                    <span className="text-[10px] lg:text-[12px]">{formatCredits(value.credits[sliderSubject])}</span>
                                </span>
                            </span>
                        );
                    })}
                </Box>
            </div>
        </div>
    );
}

export default PricingSlider;
