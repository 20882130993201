import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const data = [
    { label: "Total Sent", value: 743 },
    { label: "Unique Clicked", value: 320 },
    { label: "Unique Opened", value: 160, subscript: "87.8%" },
    { label: "Total Opened", value: 201 },
    { label: "Unique Clicked", value: 61, subscript: "19.1%" },
    { label: "Unique Replied", value: 12, subscript: "19.7%" },
    { label: "Unique Positive Replied", value: 0 },
    { label: "Unique Bounced", value: 69, subscript: "9.3%" },
];

export default function Overview() {
    return (
        <>
            <div className="mt-5 rounded-xl bg-[#a3b9fc] p-3 text-center w-full">
                <h1 className="text-2xl font-bold">397</h1>
                <p className="mt-1">Total Leads Reached</p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-10 xl:gap-x-16 gap-y-5 mt-5">
                {data.map((item, index) => (
                    <div key={index} className="campaign-card">
                        <div className="flex items-center text-dgray-500 text gap-x-2">
                            <p>{item.label}</p>
                            <InfoOutlinedIcon className="!h-4 !w-4" />
                        </div>
                        <div>
                            <h1 className="text-xl font-medium mt-2">
                                {item.value} {item.subscript && <sub className="text-blue-500">({item.subscript})</sub>}
                            </h1>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
