import AllTabs from "@/components/ai_engagement/AllTabs";
import React from "react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import Inbox from "@/components/ai_engagement/inbox/Inbox";

export default function InboxScene() {
    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white mt-4 pb-10">
                <BaseContainer>
                    <AllTabs />
                    <Inbox />
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
