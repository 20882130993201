import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, styled, createTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { CircularProgress } from "@mui/material";
import useAutocomplete from "@/hooks/useAutocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Helper function to get a unique identifier for each option
const getOptionId = (option) => {
  if (typeof option === "string") {
    return option;
  } else {
    return option.meta?.id || option.name;
  }
};

const BootstrapAutocompleteInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: 7,
    position: "relative",
    backgroundColor: "white",
    borderColor: "#80bdff",
    border: "none",
    fontSize: 12,
    fontFamily: "Outfit",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 7,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E8E7E7",
    },
    "&:hover fieldset": {
      borderColor: "#E8E7E7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E8E7E7",
    },
  },
});

export default function AutocompleteInput({
  value = "",
  onNameFilterChange,
  searchBy,
  placeholder = null,
  isUseAPI = true,
  initialOptions = [],
  isWebsiteAutocomplete = false,
  clearTrigger,
  multiple = false,
}) {
  const { searchAutoComplete } = useAutocomplete();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(initialOptions);
  const [filterText, setFilterText] = React.useState(multiple ? "" : value);
  const [apiLoading, setApiLoading] = React.useState(false);
  const [lastTimerId, setLastTimerId] = React.useState(null);

  // State to manage selected values
  // Initialize selectedValues based on whether multiple selections are allowed
  const [selectedValues, setSelectedValues] = React.useState(
    multiple
      ? Array.isArray(value)
        ? value.map((item) => getOptionId(item))
        : []
      : value
        ? getOptionId(value)
        : ""
  );

  // State to manage selected options
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  console.log("selectedValues", selectedValues);
  console.log("value", value);
  console.log("value length", value?.length);

  const capitalizeWords = (input) => {
    return input
      .split(", ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(", ");
  };

  // Add this useEffect hook
  React.useEffect(() => {
    setSelectedValues(
      multiple
        ? Array.isArray(value)
          ? value.map((item) => getOptionId(item))
          : []
        : value
          ? getOptionId(value)
          : ""
    );
  }, [value, multiple]);

  React.useEffect(() => {
    const newSelectedOptions = value.map((id) => {
      // Find the option in options
      let option = options.find((opt) => getOptionId(opt) === id);
      if (option) {
        return option;
      } else {
        // Option not in current options, create a placeholder option
        return { name: id, meta: { id } };
      }
    });
    setSelectedOptions(newSelectedOptions);
  }, [value]);

  // Update selectedOptions when options change
  React.useEffect(() => {
    setSelectedOptions((prevSelectedOptions) => {
      return prevSelectedOptions.map((selectedOption) => {
        const optionInOptions = options.find(
          (opt) => getOptionId(opt) === getOptionId(selectedOption)
        );
        return optionInOptions || selectedOption;
      });
    });
  }, [options]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  React.useEffect(() => {
    setFilterText("");
  }, [clearTrigger]);

  React.useEffect(() => {
    if (!multiple) {
      setFilterText(
        value?.trim() && !isWebsiteAutocomplete ? capitalizeWords(value) : value
      );
    }
  }, [value]);

  React.useEffect(() => {
    if (filterText || (filterText?.length > 0 && isUseAPI)) {
      if (lastTimerId) clearTimeout(lastTimerId);

      const newLastTimerId = setTimeout(() => {
        loadDataByFilter();
      }, 500);
      setLastTimerId(newLastTimerId);
    }
  }, [filterText]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3b82f6",
        contrastText: "#fff",
        contrast: "#3b82f6",
        light: "#3b82f6",
      },
    },
  });

  const loadDataByFilter = async () => {
    setApiLoading(true);

    const response = await searchAutoComplete({
      params: {
        query: filterText || "",
      },
      searchParam: searchBy,
    });

    const fetchedOptions = response?.data
      ? response.data.map((item) => {
          // Set option.name based on isWebsiteAutocomplete
          const name = isWebsiteAutocomplete
            ? item.meta?.website || item.name
            : item.name;

          return {
            ...item,
            name, // Ensure option.name is set correctly
          };
        })
      : [];

    // Only add "Select All" if there are other options and multiple is true
    let newOptions = fetchedOptions;

    if (multiple && fetchedOptions.length > 0) {
      const selectAllOption = {
        name: "Select All",
        meta: {
          id: "select-all",
        },
      };
      newOptions = [selectAllOption, ...fetchedOptions];
    }

    // Merge selectedOptions into options
    // const combinedOptions = [...selectedOptions, ...newOptions];

    // // Remove duplicates based on getOptionId
    // const uniqueOptions = combinedOptions.reduce(
    //   (acc, option) => {
    //     const id = getOptionId(option);
    //     if (!acc.map.has(id)) {
    //       acc.map.set(id, option);
    //       acc.list.push(option);
    //     }
    //     return acc;
    //   },
    //   { map: new Map(), list: [] }
    // ).list;

    setOptions(newOptions);

    setApiLoading(false);
  };

  // Function to check if all options are selected
  const isAllSelected = () => {
    const selectableOptionIds = options
      .filter((option) => option.name !== "Select All")
      .map(getOptionId);

    const selectedOptionIds = selectedOptions.map(getOptionId);

    return (
      selectableOptionIds.length > 0 &&
      selectableOptionIds.every((id) => selectedOptionIds.includes(id))
    );
  };

  // Modify the handleChange function
  const handleChange = (event, newValue, reason) => {
    if (multiple) {
      if (newValue.some((option) => option.name === "Select All")) {
        if (!isAllSelected()) {
          // Select all options except "Select All"
          const allOptions = options.filter(
            (option) => option.name !== "Select All"
          );
          setSelectedOptions(allOptions);
          onNameFilterChange(allOptions.map(getOptionId));
        } else {
          // Deselect all
          setSelectedOptions([]);
          onNameFilterChange([]);
        }
      } else {
        // Remove "Select All" if present and update selected options
        const updatedOptions = newValue.filter(
          (option) => option.name !== "Select All"
        );

        setSelectedOptions(updatedOptions);
        onNameFilterChange(updatedOptions.map(getOptionId));
      }
      // Clear the input field after selection
      setFilterText("");
    } else {
      // Single selection
      const selectedOption = newValue ? newValue : null;
      setSelectedOptions(selectedOption ? [selectedOption] : []);
      onNameFilterChange(selectedOption ? getOptionId(selectedOption) : "");
      setFilterText(selectedOption ? selectedOption.name : "");
    }
  };

  return (
    <StyledAutocomplete
      multiple={multiple}
      sx={{ paddingY: 0 }}
      disableCloseOnSelect={multiple}
      value={multiple ? selectedOptions : selectedOptions[0] || null}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return `${option.name}\u200B${option.meta?.id || ""}`;
      }}
      isOptionEqualToValue={(option, value) =>
        getOptionId(option) === getOptionId(value)
      }
      renderOption={(props, option) => {
        const isOptionSelected =
          option.name === "Select All"
            ? isAllSelected()
            : selectedOptions.some(
                (selectedOption) =>
                  getOptionId(selectedOption) === getOptionId(option)
              );

        return (
          <li {...props}>
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={isOptionSelected}
              />
            )}
            <div className="flex items-center justify-between w-full">
              <div className="font-Outfit flex flex-col text-sm">
                <p className="capitalize">{option.name}</p>
                {option.meta?.website && (
                  <p className="text-xs">{option.meta.website}</p>
                )}
              </div>
              {option?.count && (
                <p>({option?.count.toLocaleString("en-US")})</p>
              )}
            </div>
          </li>
        );
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
        if (isUseAPI && options.length === 0) {
          loadDataByFilter();
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={apiLoading}
      renderInput={(params) => (
        <BootstrapAutocompleteInput
          {...params}
          sx={{ paddingTop: "0px !important", paddingBottom: "0px !important" }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {apiLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13 && filterText?.length > 3) {
              loadDataByFilter();
            }
          }}
        />
      )}
      renderTags={(value, getTagProps) => {
        console.log("selectedOptions", selectedOptions);
        selectedOptions?.map((option, index) => {
          console.log("option", option);
        });

        return selectedOptions?.map((option, index) => (
          <ThemeProvider theme={theme} key={getOptionId(option)}>
            <Chip
              label={option.name}
              size="small"
              color="primary"
              {...getTagProps({ index })}
              sx={{
                "& span": {
                  fontFamily: "Outfit",
                  fontSize: "10px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ThemeProvider>
        ));
      }}
      onChange={handleChange}
      inputValue={filterText}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "input" || reason === "clear") {
          setFilterText(newInputValue);
        }
      }}
      // Add the custom filterOptions function here
      filterOptions={(options, state) => {
        const inputValue = state.inputValue.toLowerCase();
        return options.filter((option) => {
          if (option.name === "Select All") {
            return true;
          }
          const optionLabel = option.name.toLowerCase();
          return optionLabel.includes(inputValue);
        });
      }}
    />
  );
}
