import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import Papa from "papaparse";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { CircularProgress } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "@/theme/palette";
import MainLoader from "../MainLoader";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import DeleteIcon from "@/assets/image/bulk/delete.svg";
import SuccessIcon from "@/assets/image/bulk/success.svg";
import { ReactComponent as DownloadIcon } from "@/assets/image/bulk/download.svg";
import { ReactComponent as ResultIcon } from "@/assets/image/bulk/result.svg";
import useBulk from "@/hooks/useBulk";
import CRMPushMenu from "../common/CRMElements/CRMPushMenu";

const csvDownload = async (fileName, content) => {
    const csvContent = [],
        headerItem = ["No"];
    headerItem.push("Linkedin Url");
    headerItem.push("Name", "Company", "Location");
    headerItem.push("Personal Emails");
    headerItem.push("Work Emails");
    headerItem.push("Risky Emails");
    headerItem.push("Unknown Emails");
    headerItem.push("Do not Emails");
    headerItem.push("Valid Phone Numbers");
    headerItem.push("HQ Phone Numbers");

    csvContent.push(headerItem);

    content.forEach((item, index) => {
        const record = [];

        record.push(index + 1);
        record.push(item?.linkedinUrl);
        record.push(item?.name, item?.companyName, item?.location);
        record.push(item?.["Personal Emails"]);
        record.push(item?.["Work Emails"]);
        record.push(item?.["Risky Emails"]);
        record.push(item?.["Unknown Emails"]);
        record.push(item?.["Do not Emails"]);
        record.push(
            item?.["Valid Phone Numbers"] ? item["Valid Phone Numbers"] : item?.phoneNumbers?.map((phone) => `\t${phone}`)?.join(",")
        );
        record.push(item?.["HQ Phone Numbers"]);
        csvContent.push(record);
    });

    const csv = Papa.unparse(csvContent);

    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.csv`;
    link.click();
};

const formattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")} ${date.toLocaleString("default", {
        month: "short",
    })} ${date.getFullYear()}`;
    return formattedDate;
};

const headCells = [
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Title",
    },
    {
        id: "action",
        numeric: false,
        disablePadding: true,
        label: "Action",
    },
];

const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "5px 10px",
                    fontFamily: "Outfit",
                    fontSize: 13,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "white",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell padding="checkbox"></TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={"left"} padding={"none"} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    selected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { selected, onSelectAllClick, onDownloadSelected, rowCount, tableData, isDisabled } = props;

    console.log({ tableData });
    const numSelected = selected.length;
    const selectedData = selected.map((_id) => tableData.find((item) => item._id === _id));
    return (
        <div className="mt-3 w-full border-b border-stone-250 pb-3">
            <Toolbar
                sx={{
                    paddingLeft: { xs: 0, sm: 0 },
                    paddingRight: { xs: 0, sm: 0 },
                }}
            >
                <div className="flex w-full flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-2">
                        <div className="font-Outfit flex flex-row items-center">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    "aria-label": "select all desserts",
                                }}
                                sx={{
                                    color: kompassColors.textLight,
                                    "&.Mui-checked": {
                                        color: kompassColors.blue,
                                    },
                                    "&.MuiCheckbox-indeterminate": {
                                        color: kompassColors.blue,
                                    },
                                }}
                            />
                        </div>
                        <div className="font-Outfit flex flex-row justify-items-center gap-2 text-sm">
                            <div
                                className={
                                    "flex cursor-pointer flex-row items-center gap-1 rounded-md border px-2 py-1 text-white" +
                                    (numSelected && !isDisabled > 0 ? " border-stone-950 bg-stone-950" : " border-stone-350 bg-stone-350")
                                }
                                onClick={() => {
                                    if (isDisabled) {
                                        return;
                                    }
                                    onDownloadSelected();
                                }}
                            >
                                <div className="cursor-pointer select-none capitalize">Download results</div>
                                <DownloadIcon fill="#ffffff" />
                            </div>
                            <CRMPushMenu selected={selectedData} profileType={"bulk"} isDisabled={isDisabled}></CRMPushMenu>
                        </div>
                    </div>
                    <div className="font-OutfitMedium select-none text-sm">{numSelected > 0 ? `${numSelected}  selected` : null}</div>
                </div>
            </Toolbar>
        </div>
    );
}

EnhancedTableToolbar.propTypes = {
    selected: PropTypes.object.isRequired,
};

function BulkResultRow(props) {
    const { row, isItemSelected, labelId, handleClick, handleDelete, setTableData } = props;
    const { bulkEnrichResultItem, bulkEnrichOutputs } = useBulk();
    const [rowData, setRowData] = useState(row);

    const { data: item } = useQuery({
        queryKey: ["bulkEnrichResultItem", rowData?._id],
        queryFn: () => bulkEnrichResultItem(rowData?._id),
        enabled: !!rowData?._id && !rowData?.completed && !rowData?.error,
        refetchInterval: 500,
        refetchIntervalInBackground: true,
        onSuccess: (data) => {
            setRowData(data.data);
            console.log("Query successful:", data);
        },
        onError: (error) => {
            console.error("Query error:", error);
        },
    });

    useEffect(() => {
        if (item?.data?._id) setRowData(item?.data);

        if (item?.data?.completed) {
            setTableData((prev) =>
                prev.map((tableItem) => (tableItem?._id === item?.data?._id ? { ...tableItem, completed: true } : tableItem))
            );
        }
    }, [item]);

    useEffect(() => {
        setRowData(row);
    }, [row]);

    const onDownload = async () => {
        try {
            const response = await bulkEnrichOutputs(rowData?._id);
            csvDownload(rowData?.contactTitle, response?.data);
        } catch (error) {
            toast.error(error?.message ? error?.message : "Something went wrong", {
                theme: "colored",
            });
        }
    };

    return (
        <>
            <TableRow
                hover
                // onClick={(event) => handleClick(event, row.name)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row._id}
                selected={isItemSelected}
                sx={{
                    cursor: "pointer",
                    background: "white",
                    "& .MuiTableCell-root": {
                        border: "none",
                        borderTop: 1,
                        borderColor: kompassColors.light,
                        backgroundColor: "#E8E7E7",
                        paddingY: 2,
                    },
                }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => {
                            handleClick(event, row?._id);
                        }}
                        inputProps={{
                            "aria-labelledby": labelId,
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.blue,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.blue,
                            },
                        }}
                    />
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    <div className="flex flex-row items-center gap-2">
                        <ResultIcon />
                        <div className="flex flex-col">
                            <span className="text-md font-OutfitLight font-semibold">{rowData?.contactTitle}</span>
                            <div className="flex flex-row gap-1">
                                {rowData?.error ? (
                                    <span className="font-OutfitLigh text-sm text-[#090C0590]">
                                        <span className="text-[#b00000]">{`Faild • ${rowData?.message}`}</span>
                                    </span>
                                ) : (
                                    <>
                                        <span className="font-OutfitLight text-sm text-[#090C0590]">
                                            {rowData?.numberOfContacts + ` Contacts`}
                                        </span>
                                        <span className="font-OutfitLight text-sn text-[#090C0590]">
                                            {` • ` + (rowData?.completed ? "Completed" : `Processing ${rowData?.progress} %`)}
                                        </span>
                                        <span className="font-OutfitLight text-sm text-[#090C0590]">
                                            {rowData?.completed && rowData?.completedAt
                                                ? ` • ` + formattedDate(rowData?.completedAt)
                                                : null}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </TableCell>
                <TableCell padding="none" align="right">
                    {rowData?.completed ? (
                        <>
                            <IconButton
                                onClick={() => {
                                    onDownload();
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    handleDelete(row?._id);
                                }}
                            >
                                <ReactSVG src={DeleteIcon} />
                            </IconButton>
                            <IconButton onClick={() => {}} disabled>
                                <ReactSVG src={SuccessIcon} />
                            </IconButton>
                        </>
                    ) : !rowData?.error ? (
                        <>
                            <IconButton
                                onClick={() => {
                                    onDownload();
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    handleDelete(row?._id);
                                }}
                            >
                                <ReactSVG src={DeleteIcon} />
                            </IconButton>
                            <IconButton disabled>
                                <CircularProgress size="1.2rem" className="mr-2" />
                            </IconButton>
                        </>
                    ) : null}
                </TableCell>
            </TableRow>
        </>
    );
}

let currentDeleteId = null;

export default function BulkResultTable() {
    const { bulkEnrichResults, bulkEnrichDeleteResult, bulkEnrichOutputs } = useBulk();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibleDeleteDialog, handleVisibleDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [isDisabledPushAndSave, setIsDisabledPushAndSave] = useState(false);
    // const [dense, setDense] = useState(false);
    const dense = false;
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const rowsPerPage = 5;
    const bulkEnrichResultsRef = useRef();
    bulkEnrichResultsRef.current = bulkEnrichResults;

    useEffect(() => {
        const reload = async () => {
            setLoading(true);
            const response = await bulkEnrichResultsRef.current();

            if (response?.status) {
                setTableData(response?.data);
            } else {
                toast.error(response?.message, { theme: "colored" });
            }
            setLoading(false);
        };
        reload();
    }, []);

    useEffect(() => {
        console.log({ selected, tableData });
        const isDisableButtonsForExport = () => {
            const noCompletedOrErroredData = tableData.filter((tableItem) => tableItem.error || !tableItem.completed);

            return noCompletedOrErroredData.some((tableItem) => {
                return selected.includes(tableItem._id);
            });
        };

        setIsDisabledPushAndSave(isDisableButtonsForExport());
    }, [selected.length, tableData]);

    const reload = async () => {
        setLoading(true);
        const response = await bulkEnrichResults();
        if (response?.status) {
            setTableData(response?.data);
        } else {
            toast.error(response?.message, { theme: "colored" });
        }
        setLoading(false);
    };

    const deleteContactList = async () => {};

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = tableData.map((n) => n._id);
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };

    const handleDeleteSelected = async (confirmed) => {
        if (!(confirmed === true)) {
            handleVisibleDeleteDialog(true);
        } else {
            setLoading(true);
            const response = await deleteContactList({ ids: selected });
            setLoading(false);
            if (response?.status) {
                toast.success(response?.message, { theme: "colored" });
                setSelected([]);
                reload();
            } else {
                toast.error(response?.message, { theme: "colored" });
            }
        }
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1);
    };

    const onDownloadSelected = async () => {
        for (const itemId of selected) {
            try {
                const item = tableData.find((item) => {
                    return item._id === itemId;
                });
                if (item) {
                    const content = await bulkEnrichOutputs(itemId);
                    csvDownload(item?.contactTitle, content?.data);
                }
            } catch (error) {}
        }
    };

    const handleDelete = (id) => {
        currentDeleteId = id;
        handleVisibleDeleteDialog(true);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = useMemo(
        () => tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, tableData, rowsPerPage]
    );

    return (
        <>
            {loading && <MainLoader />}
            <Box sx={{ width: "100%", mt: "10px", px: 0 }}>
                <EnhancedTableToolbar
                    selected={selected}
                    tableData={tableData}
                    onSelectAllClick={handleSelectAllClick}
                    onDownloadSelected={onDownloadSelected}
                    rowCount={tableData.length}
                    isDisabled={isDisabledPushAndSave}
                />
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: 750,
                            borderCollapse: "separate",
                            borderSpacing: "0 0.5em",
                            border: "none",
                        }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                    >
                        <TableBody
                            sx={{
                                "& .MuiTableCell-sizeMedium": {
                                    padding: "5px 8px",
                                    fontFamily: "Outfit",
                                    fontSize: 14,
                                    // font-family: Outfit;
                                },
                            }}
                        >
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row._id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <BulkResultRow
                                        isItemSelected={isItemSelected}
                                        setTableData={setTableData}
                                        row={row}
                                        labelId={labelId}
                                        handleClick={handleClick}
                                        handleDelete={handleDelete}
                                        handleItemClick={() => {}}
                                        setMenuAnchorEl={() => {}}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className="flex w-full justify-end">
                    <Pagination
                        className="font-Outfit"
                        count={Math.floor(tableData.length / rowsPerPage) + 1}
                        page={page + 1}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        sx={{
                            "& .MuiPaginationItem-text": {
                                fontFamily: "Outfit",
                                fontSize: "14px",
                            },
                        }}
                    />
                </div>
            </Box>
            <ConfirmDeleteDialog
                open={visibleDeleteDialog}
                handleClose={() => {
                    handleVisibleDeleteDialog(false);
                    currentDeleteId = null;
                }}
                handleYes={async () => {
                    handleDeleteSelected(true);
                    handleVisibleDeleteDialog(false);
                    setLoading(true);

                    const response = await bulkEnrichDeleteResult(currentDeleteId);
                    let tempSelected = selected;
                    tempSelected = tempSelected.filter((elem) => elem !== currentDeleteId);
                    setSelected(tempSelected);
                    if (response?.status) {
                        reload();
                    } else {
                        toast.error(response?.message, { theme: "colored" });
                    }

                    setLoading(false);
                    currentDeleteId = null;
                }}
            />
        </>
    );
}
