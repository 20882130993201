import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { BASE_BUTTON_STYLES } from "@/utils/common";

const DialogWindow = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "10px",
        minWidth: "400px",
    },
}));

export const WarmupModal = ({ open, onClose, onSave, children, isLoading, buttonLabel, disabled = false, title, titleClassName = "" }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <DialogWindow fullScreen={fullScreen} open={open} onClose={onClose}>
            <button onClick={onClose} className="absolute right-0 top-0 flex flex-row justify-end pr-4 pt-4">
                <CloseSVGIcon className="relative h-4 w-4" />
            </button>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <div className={`font-Outfit  p-3 text-center text-2xl font-bold text-black${titleClassName ? ` ${titleClassName}` : ""}`}>
                    {title}
                </div>
                {children}
                {title && buttonLabel ? (
                    <div className="font-Outfit mt-4 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
                        <Button
                            disabled={disabled || isLoading}
                            sx={{
                                ...BASE_BUTTON_STYLES,
                                backgroundColor: "transparent",
                                color: "#090C05",
                                ":hover": {
                                    backgroundColor: "#090C05",
                                    color: "#fff",
                                },
                            }}
                            onClick={onSave}
                        >
                            {isLoading ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "#090C05" }} /> : null}
                            {buttonLabel}
                        </Button>
                    </div>
                ) : null}
            </DialogContent>
        </DialogWindow>
    );
};
