import React from "react";

export default function EmailDeliverReport() {
    // Table data
    const data = [
        {
            account: "imogen.low@kompassai.com",
            sent: 76,
            opened: 4,
            replied: 38,
            clicked: 7,
            bounced: 2,
            unsubscribed: 0,
        },
        {
            account: "Deleted Email Account",
            sent: 76,
            opened: 4,
            replied: 38,
            clicked: 7,
            bounced: 2,
            unsubscribed: 0,
        },
    ];

    return (
        <div className="mt-12 campaign-card">
            <h1 className="text-blue-500 text-lg">Email Deliverability Report</h1>

            <div className="relative overflow-x-auto mt-5">
                <table className="w-full text-left rtl:text-right text-dgray-500">
                    <thead className="text-dgray-700 bg-dgray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3 rounded-tl-lg rounded-bl-lg">
                                Sending Account
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Sent
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opened
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Replied
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Clicked
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Bounced
                            </th>
                            <th scope="col" className="px-6 py-3 rounded-tr-lg rounded-br-lg">
                                Unsubscribed
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index} className="bg-white border-b">
                                <td className="px-6 py-4">{row.account}</td>
                                <td className="px-6 py-4">{row.sent}</td>
                                <td className="px-6 py-4">{row.opened}</td>
                                <td className="px-6 py-4">{row.replied}</td>
                                <td className="px-6 py-4">{row.clicked}</td>
                                <td className="px-6 py-4">{row.bounced}</td>
                                <td className="px-6 py-4">{row.unsubscribed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
