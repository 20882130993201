import { useContext, useState, useRef } from "react";
import { Button } from "@material-tailwind/react";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import AuthLayout from "@/components/AuthLayout";
import { ReactComponent as Logo } from "@/assets/image/icons/logo.svg";
import { ReactComponent as HidePassword } from "@/assets/image/icons/hide.svg";
import GoogleIcon from "@/assets/image/icons/google.svg";
import Text from "@/components/Text";
import { COGNITO_GOOGLE_DEFAULT_PASSWORD } from "@/utils/constants";
import useKompassRouter from "@/hooks/useKompassRouter";

export default function SignIn() {
    const { kompassNavigate } = useKompassRouter();
    const authContext = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const captchaRef = useRef(null);
    const [isLogging, setIsLogging] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);

    const handleKeyEnter = async (e) => {
        if (e.key === "Enter") {
            signIn();
        }
    };

    const forgotPassword = async () => {
        try {
            kompassNavigate("/reset");
        } catch (err) {
            toast.error(err.message, { theme: "colored" });
        }
    };

    const gotoSignUp = () => {
        kompassNavigate("/signup");
    };

    const isEmail = (input) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(input);
    };

    const signIn = async () => {
        setErrorMessage(null);
        const captchaToken = await captchaRef.current.executeAsync();
        try {
            setIsLogging(true);
            await authContext.signInWithEmail(
                email,
                password,
                captchaToken
                    ? {
                          captcha: captchaToken,
                      }
                    : null,
                rememberMe
            );
            setIsLogging(false);
        } catch (err) {
            setIsLogging(false);
            console.log(err);
            if (err instanceof Error) {
                if (err.message.includes("PreAuthentication failed with error Recaptcha verification failed.")) {
                    // toast.error('Recaptcha verification failed', { theme: "colored" })
                    setErrorMessage("Recaptcha verification failed");
                } else if (err.message.includes("Missing Captcha Value")) {
                    // toast.error('Missing Captcha Value', { theme: "colored" })
                    setErrorMessage("Missing Captcha Value");
                } else if (err.message.includes("User doesn't exist")) {
                    // toast.error("User doesn't exist", { theme: "colored" })
                    setErrorMessage("User doesn't exist");
                } else if (err.message.includes("Password attempts exceeded")) {
                    // toast.error("User doesn't exist", { theme: "colored" })
                    setErrorMessage("Password attempts exceeded. Try again later");
                } else {
                    // toast.error(err.message, { theme: "colored" })
                    setErrorMessage(err.message);
                }
                captchaRef.current.reset();
            }
        }
    };

    const googleSignin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            setErrorMessage(null);
            const captchaToken = await captchaRef.current.executeAsync();
            try {
                setIsLogging(true);
                const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer  ${codeResponse.access_token}`,
                        Accept: "application/json",
                    },
                });
                const _resData = await response.json();
                await authContext.signInWithEmail(
                    _resData.email,
                    COGNITO_GOOGLE_DEFAULT_PASSWORD,
                    captchaToken
                        ? {
                              captcha: captchaToken,
                          }
                        : null,
                    rememberMe
                );
                setIsLogging(false);

                // gotoHome();
            } catch (err) {
                setIsLogging(false);
                if (err instanceof Error) {
                    if (err.message.includes("PreAuthentication failed with error Recaptcha verification failed.")) {
                        // toast.error('Recaptcha verification failed', { theme: "colored" })
                        setErrorMessage("Recaptcha verification failed");
                    } else if (err.message.includes("Missing Captcha Value")) {
                        // toast.error('Missing Captcha Value', { theme: "colored" })
                        setErrorMessage("Missing Captcha Value");
                    } else if (err.message.includes("User doesn't exist")) {
                        // toast.error("User doesn't exist", { theme: "colored" })
                        setErrorMessage("User doesn't exist");
                    } else {
                        // toast.error(err.message, { theme: "colored" })
                        setErrorMessage(err.message);
                    }
                    captchaRef.current.reset();
                }
            }
        },
        onError: (error) => console.log("Login Failed:", error),
    });

    return (
        <AuthLayout>
            <Link to="/">
                <Logo className="mb-12 w-[185px] fill-current text-gray-900" />
            </Link>
            <Text variant="Header4" className="auth-title">
                Welcome back
            </Text>
            <div className="auth-description">Thanks for being a part of our community!</div>

            <hr className="w-full h-px my-8 bg-stone-250" />
            <div className="auth-label">Email address</div>
            <div className=" auth-input-container">
                <input
                    onChange={(e) => setEmail(e.target.value)}
                    className={`auth-input ${email && !isEmail(email) ? "border border-red-500" : ""}`}
                    placeholder="e.g. johndoe@kompass.ai"
                    disabled={isLogging ? true : false}
                    onKeyDown={handleKeyEnter}
                    autoComplete="on"
                />
            </div>
            {email && !isEmail(email) && <span className="text-xs text-red-500">Please enter a valid email address</span>}
            <div className="mt-4 auth-label">Password</div>
            <div className="auth-input-container">
                <input
                    onChange={(e) => setPassword(e.target.value)}
                    className="auth-input"
                    type={!showPassword ? "password" : "text"}
                    disabled={isLogging ? true : false}
                    onKeyDown={handleKeyEnter}
                    autoComplete="on"
                />
                <span
                    onClick={() => {
                        setShowPassword(!showPassword);
                    }}
                >
                    <HidePassword
                        fill={showPassword ? "#E7436A" : "#929292"}
                        className="absolute transform -translate-y-1/2 top-1/2 right-4"
                    />
                </span>
            </div>
            <div
                onClick={forgotPassword}
                className="flex justify-end w-full mt-2 text-blue-500 cursor-pointer select-none font-Outfit text-md"
            >
                Forgot your password?
            </div>
            <div className="relative flex flex-row items-center justify-center w-full mt-2">
                <ReCAPTCHA sitekey={process.env.REACT_APP_KOMPASSAI_RECAPTCHA_SITE_KEY} size="invisible" ref={captchaRef} />
            </div>
            {errorMessage && <p className="auth-error">{errorMessage}</p>}
            <Button
                disabled={!isEmail(email) || (isLogging ? true : false)}
                onClick={signIn}
                style={{ textTransform: "capitalize" }}
                className="submit-button"
            >
                {isLogging ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "white" }} /> : null}
                Sign in
            </Button>

            <div className="flex items-center justify-center font-Outfit gap-1 mt-4 text-[12px] md:text-[14px]">
                <input type="checkbox" id="rememberMe" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                <label htmlFor="rememberMe">Remember Me</label>
            </div>

            <div className="font-Outfit text-md mt-12 flex w-full justify-center text-[#8B9E9F]">Or sign in with your work email</div>

            <Button onClick={googleSignin} className="google-button" style={{ textTransform: "capitalize" }} disabled={isLogging}>
                <img className="h-[20px] w-[20px]" src={GoogleIcon} alt="GoogleIcon" />
                <div>Login via Google</div>
            </Button>
            <div className="font-Outfit text-md mt-8 flex w-full justify-center text-[#8B9E9F]">
                You’re new here?
                <span onClick={gotoSignUp} className="ml-1 text-blue-500 cursor-pointer select-none">
                    Create an account
                </span>
            </div>
            <div className="font-Outfit text-md mt-5 w-full text-center text-[#8B9E9F]">
                By logging in, I agree to the{" "}
                <Link to="/terms-and-conditions" className="text-blue-500 cursor-pointer select-none">
                    Terms of service
                </Link>{" "}
                and{" "}
                <Link to="/privacy-policy" className="text-blue-500 cursor-pointer select-none">
                    Privacy policy
                </Link>
                . I also agree to receive emails and communication relating to KompassAI services and offers.
            </div>
        </AuthLayout>
    );
}
