import useAuth from "@/hooks/useAuth";

const useAIEngagement = () => {
    const apiUrl = process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT;
    const { getAuthToken } = useAuth();

    const fetchWithAuth = async (endpoint, method = "POST", body = null, options = {}) => {
        const authToken = await getAuthToken();
        const response = await fetch(`${apiUrl}${endpoint}`, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: body ? JSON.stringify(body) : null,
            ...options,
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "API request failed");
        }
        return response.json();
    };

    const flexSearch = async (params) => {
        let response = null;
        let { description, campaignId } = params;

        try {
            // Initialize campaign
            if (!campaignId) {
                const campaignData = await fetchWithAuth("/campaigns/build/initialize");
                campaignId = campaignData?.data?.response?.campaignId;
            }

            // Fetch filters data
            const filtersData = await fetchWithAuth("/campaigns/build/extractFiltersPDL", "POST", { campaignId, description });

            response = {
                status: true,
                campaignId,
                icpTags: filtersData?.data?.response,
            };
        } catch (e) {
            console.error("Error in flexSearch:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }

        try {
            // preview search to get the total results
            const previewData = await fetchWithAuth("/campaigns/build/previewSearch", "POST", { campaignId });

            response.previewData = previewData?.data?.response;
            return response;
        } catch (e) {
            console.error("Error in flexSearch:", e);
            response.previewData = { totalResults: 0, data: [] };
            return response;
        }
    };

    // get contact lists data
    const getTargetList = async () => {
        try {
            const response = await fetchWithAuth(`/list?listType=kompasssearch`, "GET");
            return response.data;
        } catch (e) {
            console.error("Error in getContactLists:", e);
            return [];
        }
    };

    // update campaign data
    const updateCampaignData = async (campaignData) => {
        try {
            if (campaignData?.listType === "standard") {
                // initialize campaign
                let isNewCampaign = false;
                if (campaignData?.campaignId) {
                    await fetchWithAuth("/campaigns/build/update", "POST", campaignData);
                } else {
                    const res = await fetchWithAuth("/campaigns/build/initialize", "POST", { campaignName: campaignData.campaignName });
                    campaignData.campaignId = res.data.response.campaignId;
                    await fetchWithAuth("/campaigns/build/update", "POST", campaignData);
                    isNewCampaign = true;
                }
                return {
                    status: true,
                    message: "Campaign data updated successfully !",
                    isNewCampaign,
                    campaignId: campaignData.campaignId,
                };
            }

            await fetchWithAuth("/campaigns/build/update", "POST", campaignData);
            return {
                status: true,
                message: "Campaign data updated successfully !",
            };
        } catch (e) {
            console.error("Error in updateCampaignData:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // build product knowledge and create sequences
    const buildProductKnowledge = async (productData) => {
        try {
            if (!productData.campaignId || !productData.urls) {
                throw new Error("Invalid product data");
            }
            await fetchWithAuth("/campaigns/build/buildProductKnowledge", "POST", productData);
            // check is there already any sequence
            const res = await fetchWithAuth("/campaigns/build/getSequence", "POST", { campaignId: productData.campaignId });
            if (res.data?.response?.length > 0) {
                // delete all existing sequences
                const sequenceIds = res.data.response.map((sequence) => sequence._id);
                await Promise.all(
                    sequenceIds.map((sequenceId) => fetchWithAuth("/campaigns/build/deleteSequenceStep", "POST", { sequenceId }))
                );
            }
            // create sequences
            await fetchWithAuth("/campaigns/build/generateAiSequence", "POST", { campaignId: productData.campaignId });
            return {
                status: true,
                message: "Product knowledge added successfully !",
            };
        } catch (e) {
            console.error("Error in buildProductKnowledge:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // get ai sequences
    const getAiSequences = async (campaignId) => {
        try {
            const response = await fetchWithAuth(`/campaigns/build/getSequence`, "POST", { campaignId });
            return {
                status: true,
                data: response.data?.response || [],
            };
        } catch (e) {
            console.error("Error in getAiSequences:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // get sample mail
    const getSampleMail = async (campaignId, sequenceNumber, signal) => {
        try {
            const response = await fetchWithAuth(
                `/campaigns/build/previewSequence`,
                "POST",
                { campaignId, sequenceNumber, samples: 1 },
                { signal }
            );
            return {
                status: true,
                data: response.data?.response[0],
            };
        } catch (e) {
            console.log("sample mail aborted");
            if (e.name === "AbortError") {
                return {
                    status: false,
                    message: "Request aborted !",
                    aborted: true,
                };
            }
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // delete sequence
    const deleteSequence = async (sequenceId) => {
        try {
            await fetchWithAuth("/campaigns/build/deleteSequenceStep", "POST", { sequenceId });
            return {
                status: true,
                message: "Sequence deleted successfully !",
            };
        } catch (e) {
            console.error("Error in deleteSequence:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // add sequence
    const addSequence = async (sequenceData) => {
        try {
            const res = await fetchWithAuth("/campaigns/build/addSequenceStep", "POST", sequenceData);
            // insert sequence id in the sequence data
            sequenceData._id = res.data?.response?.sequenceId;
            sequenceData.title = res.data?.response?.title;

            return {
                status: true,
                message: "Sequence added successfully !",
                data: sequenceData,
            };
        } catch (e) {
            console.error("Error in addSequence:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    const updateAllSequenceSteps = async (sequenceData) => {
        try {
            // Create an array of promises
            const updatePromises = sequenceData.map((stepData) => fetchWithAuth("/campaigns/build/updateSequenceStep", "POST", stepData));
            // Wait for all promises to complete
            await Promise.all(updatePromises);
            return {
                status: true,
                message: "Sequence steps updated successfully!",
            };
        } catch (e) {
            console.error("Error in updateAllSequences:", e);
            return {
                status: false,
                message: e.message || "Something went wrong!",
            };
        }
    };

    // schedule campaign
    const scheduleCampaign = async (scheduleData) => {
        try {
            await fetchWithAuth("/campaigns/build/update", "POST", scheduleData);
            return {
                status: true,
                message: "Campaign scheduled successfully !",
            };
        } catch (e) {
            console.error("Error in scheduleCampaign:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // get campaigns analytics
    const getCampaignsAnalytics = async () => {
        try {
            const allCampaigns = await fetchWithAuth("/campaigns/build/getCampaigns");
            // remove campaigns which include deleted = true
            const availableCampaigns = allCampaigns.data.response.filter((campaign) => !campaign.deleted);
            const campaignsData = availableCampaigns.map((campaign) => {
                return {
                    campaignName: campaign.campaignName || "Untitled Campaign",
                    campaignStatus: campaign.campaignStatus,
                    campaignId: campaign._id,
                    ctaLink: campaign.ctaLink,
                    createdAt: campaign.created_at,
                };
            });
            const analyticsData = await fetchWithAuth("/campaigns/build/getCampaignAnalytics", "POST", {
                campaignIds: availableCampaigns.map((campaign) => campaign._id),
            });
            const campaignsAnalytics = campaignsData.map((campaign, index) => {
                return {
                    ...campaign,
                    analytics: analyticsData.data.response[index] || {},
                };
            });
            return {
                status: true,
                data: campaignsAnalytics,
            };
        } catch (e) {
            console.error("Error in getCampaignsAnalytics:", e);
            return {
                status: false,
            };
        }
    };

    // activate the campaign
    const activateCampaign = async (campaignId) => {
        try {
            await fetchWithAuth("/campaigns/build/activateCampaign", "POST", { campaignId });
            return {
                status: true,
                message: "Campaign activated successfully !",
            };
        } catch (e) {
            console.error("Error in activateCampaign:", e);
            throw new Error(e.message || "Something went wrong !");
        }
    };

    // deactivate the campaign
    const deactivateCampaign = async (campaignId, deleted = false) => {
        try {
            await fetchWithAuth("/campaigns/build/deactivateCampaign", "POST", { campaignId, deleted });
            return {
                status: true,
                message: "Campaign deactivated successfully !",
            };
        } catch (e) {
            console.error("Error in deactivateCampaign:", e);
            throw new Error(e.message || "Something went wrong !");
        }
    };

    // delete the campaign
    const deleteCampaign = async (campaignId) => {
        try {
            await fetchWithAuth("/campaigns/build/deleteCampaign", "POST", { campaignId });
            return {
                status: true,
                message: "Campaign deleted successfully !",
            };
        } catch (e) {
            console.error("Error in deleteCampaign:", e);
            throw new Error(e.message || "Something went wrong !");
        }
    };

    // get target contacts
    const getTargetContacts = async (campaignId) => {
        try {
            const res = await fetchWithAuth("/campaigns/build/getTargetContacts", "POST", { campaignId });
            const contactIds = res.data?.response?.map((contact) => contact._id);
            const activitiesPromises = contactIds.map((contactId) =>
                fetchWithAuth("/campaigns/build/getSequenceProgress", "POST", { campaignId, targetContactId: contactId })
            );
            const activitiesRes = await Promise.all(activitiesPromises);
            const activitiesData = activitiesRes.map((activity) => activity.data?.response.filter((activity) => true));

            const targetContacts = res.data?.response.map((contact, index) => {
                return {
                    ...contact,
                    activities: activitiesData[index] || [],
                };
            });

            console.log("activitiesData", targetContacts);
            return {
                status: true,
                data: targetContacts || [],
            };
        } catch (e) {
            console.error("Error in getTargetContacts:", e);
            return {
                status: false,
                data: [],
            };
        }
    };

    // get contact activities
    const getContactActivities = async (contactId) => {
        try {
            const res = await fetchWithAuth("/campaigns/build/getSequenceProgress", "POST", { targetContactId: contactId });
            const activities = res?.data?.response?.filter((activity) => activity.status); // filter out only which has status field
            return {
                status: true,
                data: activities || [],
            };
        } catch (e) {
            console.error("Error in getContactActivity:", e);
            return {
                status: false,
                data: [],
            };
        }
    };

    // update single sequence step
    const modifyScheduledEmail = async (emailData) => {
        try {
            // changing sequenceId to _id and removing sequeceId
            const res = await fetchWithAuth("/campaigns/build/modifyScheduledEmail", "POST", emailData);
            return {
                status: true,
                scheduledEmailId: res.data.response.scheduledEmailId,
            };
        } catch (e) {
            console.error("Error in updateSequenceStep:", e);
            throw new Error(e.message || "Something went wrong !");
        }
    };

    // generate target contacts list
    const generateTargetContacts = async (campaignId) => {
        try {
            await fetchWithAuth("/campaigns/build/generateTargetList", "POST", { campaignId });
            return {
                status: true,
                message: "Target contacts generated successfully !",
            };
        } catch (e) {
            console.error("Error in generateTargetContacts:", e);
        }
    };

    // confirm filters by updating the campaign
    const confirmFilters = async (campaignData) => {
        let response = null;
        try {
            await fetchWithAuth("/campaigns/build/update", "POST", campaignData);
            const res = await fetchWithAuth("/campaigns/build/previewSearch", "POST", { campaignId: campaignData.campaignId });
            response = {
                status: true,
                data: res.data.response,
            };
        } catch (e) {
            response = {
                status: false,
                data: [],
            };
        }
        return response;
    };
    // get campaign by id
    const getCampaignById = async (campaignId) => {
        let response = null;
        let campaignData = null;

        try {
            const res = await fetchWithAuth(`/campaigns/build/getCampaignById`, "POST", { campaignId });
            campaignData = res.data.response;
            campaignData.campaignId = campaignData._id;

            if (campaignData.listType === "standard") {
                const listRes = await fetchWithAuth(`/list?listType=kompasssearch`, "GET");
                campaignData.listRes = listRes.data;
            }

            response = {
                status: true,
                data: campaignData,
            };
        } catch (e) {
            console.error("Error in getCampaign:", e);
            return {
                status: false,
                data: {},
            };
        }
        if (campaignData?.listType !== "standard") {
            try {
                // preview search to get the total results
                const previewData = await fetchWithAuth("/campaigns/build/previewSearch", "POST", { campaignId });

                campaignData.previewData = previewData?.data?.response;
                response.data = campaignData;
                return response;
            } catch (e) {
                console.error("Error in flexSearch:", e);
                campaignData.previewData = { totalResults: 0, data: [] };
                response.data = campaignData;
                return response;
            }
        } else {
            return response;
        }
    };

    // get single campaign by id
    const getCampaignData = async (campaignId) => {
        try {
            const res = await fetchWithAuth(`/campaigns/build/getCampaignById`, "POST", { campaignId });
            return {
                status: true,
                data: res.data.response,
            };
        } catch (e) {
            console.error("Error in getCampaignData:", e);
            return {
                status: false,
                data: {},
            };
        }
    };

    // update single sequence step
    const updateSingleStep = async (stepData) => {
        try {
            const res = await fetchWithAuth("/campaigns/build/updateSequenceStep", "POST", stepData);
            return {
                status: true,
                title: res.data.response.title,
            };
        } catch (e) {
            console.error("Error in updateCampaignData:", e);
            return {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // check email health
    const getEmailHealth = async (email) => {
        try {
            const res = await fetchWithAuth("/email-health", "POST", { email });
            return {
                status: true,
                data: res.data,
            };
        } catch (e) {
            console.error("Error in getEmailhealth:", e);
            return {
                status: false,
            };
        }
    };

    const getEmailsData = async () => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/mail/gmail/accounts`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const connectWithThirdPartyService = async (params) => {
        const { authType } = params || {};
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/mail/${authType}/auth`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const fetchDataFromCallBack = async (params) => {
        let response = null;
        const authToken = await getAuthToken();
        const { url } = params || {};
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}${url}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const reFetchAllEmail = async () => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/mail/gmail/reload`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };
    const updateMailBoxStatus = async (params) => {
        let response = null;
        const authToken = await getAuthToken();
        const { url } = params || {};
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}${url}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const removeEmailConnection = async (params) => {
        let response = null;
        const authToken = await getAuthToken();
        const { url } = params || {};
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}${url}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };
    const getEmailListAccordingToType = async (params) => {
        let response = null;
        const authToken = await getAuthToken();
        const { queryString, endPoint } = params || {};
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/mail/gmail/${endPoint}${queryString}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };
    const updateCapacityData = async (params) => {
        let response = null;
        const authToken = await getAuthToken();
        const { url } = params || {};
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}${url}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const sendEmail = async (body) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/mail/gmail/send`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: body ? JSON.stringify(body) : null,
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };
    const getEmailCampain = async () => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/campaigns/build/getCampaigns`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };
    const updateClickData = async (trackingId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/campaigns/build/click/${trackingId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "API request failed");
            }
            return {
                status: true,
            };
        } catch (error) {
            console.error("Error in updateClickData:", error);
            return {
                status: false,
                message: error.message || "Something went wrong !",
            };
        }
    };
    return {
        flexSearch,
        getTargetList,
        updateCampaignData,
        buildProductKnowledge,
        getAiSequences,
        getSampleMail,
        deleteSequence,
        addSequence,
        updateAllSequenceSteps,
        getCampaignsAnalytics,
        scheduleCampaign,
        deleteCampaign,
        activateCampaign,
        deactivateCampaign,
        getEmailsData,
        connectWithThirdPartyService,
        fetchDataFromCallBack,
        getEmailListAccordingToType,
        getTargetContacts,
        getContactActivities,
        modifyScheduledEmail,
        generateTargetContacts,
        confirmFilters,
        getCampaignById,
        updateMailBoxStatus,
        removeEmailConnection,
        updateCapacityData,
        sendEmail,
        getCampaignData,
        updateSingleStep,
        getEmailCampain,
        reFetchAllEmail,
        getEmailHealth,
        updateClickData,
    };
};

export default useAIEngagement;
