import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import ContactCreatedComponent from "@/components/home/ContactCreatedComponent";
import ContactDeductedComponent from "@/components/home/CreditDeductedComponent";
import ProspectSearchComponent from "@/components/home/ProspectSearchComponent";
import SuggestedComponent from "@/components/home/SuggestedComponent";
import SummaryComponent from "@/components/home/SummaryComponent";
import UsageAnalyticComponent from "@/components/home/UsageAnalyticComponent";
import useBillingStore from "@/store/useBillingStore";
import useDashboard from "@/hooks/useDashboard";
import MainTitleBar from "@/components/MainTitleBar";

export default function HomeScene() {
    const { userInfo, isTeamMember, isTeamOwner } = useContext(AuthContext);
    const { getDashboard, getMyTeamInfo } = useDashboard();
    const { selectedPrices, setRedirect, redirect } = useBillingStore();
    const [data, setData] = useState(null);
    const [teamInfo, setTeamInfo] = useState({});
    const [filters, setFilter] = useState({
        usageAnalyticsFilter: "THIS_MONTH",
        contactsCreatedFilter: "THIS_MONTH",
        creditsDeductedFilter: "THIS_MONTH",
    });
    const getMyTeamInfoRef = useRef();
    getMyTeamInfoRef.current = getMyTeamInfo;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async (filters) => {
            const response = await getDashboard(filters);
            setData(response.data);
        };
        fetchData(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.usageAnalyticsFilter, filters.contactsCreatedFilter, filters.creditsDeductedFilter]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect, {
                state: Object.values(selectedPrices).filter((val) => val !== null),
            });
            setRedirect("");
        }
    }, [navigate, redirect, selectedPrices, setRedirect]);

    useEffect(() => {
        const fetchTeamData = async () => {
            const response = await getMyTeamInfoRef.current();
            setTeamInfo(response.data);
        };
        fetchTeamData();
    }, []);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>Welcome to KompassAI, {userInfo?.userName}!</p>
            </MainTitleBar>

            <div className="w-full bg-white">
                <BaseContainer>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-9 2xl:grid-cols-4">
                        <div className="mb-4 h-full border-r border-stone-250 bg-white pt-4 sm:mb-1 lg:col-span-2 2xl:col-span-1">
                            <div className="flex flex-col items-start justify-start">
                                <SummaryComponent
                                    creditsUsage={data?.creditsUsage ?? {}}
                                    planInfo={data?.planInfo ?? {}}
                                    teamsInfo={data?.teamsInfo ?? {}}
                                    isTeamMember={isTeamMember}
                                    isTeamOwner={isTeamOwner}
                                    teamInfo={teamInfo}
                                    navigate={navigate}
                                />
                                <hr className="mb-11 mt-6 h-px w-full border-0 bg-stone-250" />
                                <UsageAnalyticComponent
                                    usageAnalytics={data?.usageAnalytics ?? {}}
                                    setFilter={setFilter}
                                    filter={filters.usageAnalyticsFilter}
                                />
                            </div>
                        </div>
                        <div className="bg-white lg:col-span-5 2xl:col-span-2 lg:mb-4 lg:pt-4 ">
                            <div className="flex flex-col items-start justify-start gap-y-4">
                                <ContactCreatedComponent
                                    contactsUsed={data?.contactsUsed ?? []}
                                    setFilter={setFilter}
                                    filter={filters.contactsCreatedFilter}
                                />
                                <ContactDeductedComponent
                                    setFilter={setFilter}
                                    filter={filters.creditsDeductedFilter}
                                    creditsUsed={data?.creditsUsed ?? []}
                                />
                            </div>
                        </div>
                        <div className="mb-4 bg-white lg:col-span-2 2xl:col-span-1 lg:pt-4">
                            <div className="flex flex-col items-start justify-start gap-y-4 md:gap-4">
                                <ProspectSearchComponent />

                                <SuggestedComponent isTeamMember={isTeamMember} teamsInfo={data?.teamsInfo ?? {}} navigate={navigate} />
                            </div>
                        </div>
                    </div>
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
