import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Box, Table, TableCell, TableBody, TableContainer, Pagination, TableRow } from "@mui/material";

import CRMUploadTableHeader from "./CRMUploadTableHeader";
import CRMUploadTableRow from "./CRMUploadTableRow";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { useFormatUploadResults } from "@/hooks/useFormatUploadResults";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

const headCells = [
    {
        id: "name",
        disablePadding: true,
        label: "Name",
        align: "center",
    },
    {
        id: "entity",
        disablePadding: true,
        label: "Target entity",
        align: "center",
    },
    {
        id: "currentEntity",
        disablePadding: true,
        label: "Current entity",
        align: "center",
    },
    {
        id: "status",
        disablePadding: true,
        label: "Status",
        align: "center",
    },
    {
        id: "suggestions",
        disablePadding: true,
        label: "Suggestions",
        align: "center",
    },
];

export default function CRMUploadTable() {
    const formattedResult = useFormatUploadResults();
    const { toggleShow, target, targetEntity } = useCRMPushResultStore();
    const [totalCount, setTotalCount] = useState(formattedResult.length);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("status");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPageResults, setCurrentPageResults] = useState(formattedResult);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const updateSorting = (column) => {
        if (column.id === orderBy) {
            setOrder(order === "asc" ? "desc" : "asc");
            return;
        }
        setOrderBy(column.id);
        setOrder("asc");
    };
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
        toggleShow();
    });

    useEffect(() => {
        const currentSlice = (page - 1) * rowsPerPage;
        const nextSlice = currentSlice + rowsPerPage;
        const copy = [...formattedResult];

        copy.sort((a, b) => {
            const rule = a?.[orderBy] > b?.[orderBy] ? 1 : -1;
            const ruleInv = a?.[orderBy] < b?.[orderBy] ? 1 : -1;
            const equal = a?.[orderBy] === b?.[orderBy] ? 0 : order === "asc" ? rule : ruleInv;
            return equal;
        });
        setCurrentPageResults(copy.slice(currentSlice, nextSlice));
    }, [page, rowsPerPage, order, orderBy, formattedResult]);
    useEffect(() => {
        setTotalCount(formattedResult.length);
    }, [formattedResult]);
    return (
        <div ref={wrapperRef} className="w-[70%]">
            <div className="relative flex w-full flex-col items-center justify-center  ">
                <div className="align-center flex w-full items-center justify-between  px-[25px]">
                    <div className="align-center relative flex w-full items-center justify-center rounded-t-[8px]  bg-white pb-4 pt-10">
                        <div className="flex w-full text-left ">
                            <div className=" flex flex-col gap-1  pl-4">
                                <div className="font-OutfitBold flex  flex-col  text-4xl font-extrabold">Upload successful!</div>
                                <div className="font-OutfitLight flex w-fit flex-col text-lg">View results of the upload</div>
                            </div>
                        </div>
                        <div className="flex w-full justify-end ">
                            <div className=" flex w-fit flex-col gap-1  pl-4">
                                <div className="font-OutfitBold flex    flex-col text-end  text-4xl font-extrabold">{target}</div>
                                <div className="font-OutfitLight flex flex-col text-end text-lg">{targetEntity}</div>
                            </div>
                        </div>
                        <button
                            onClick={() => {
                                toggleShow();
                            }}
                            className="flex flex-row justify-end pr-4 pt-4"
                        >
                            <CloseSVGIcon className="absolute right-4 top-4 h-6 w-6" />
                        </button>
                    </div>
                </div>
                <Box sx={{ width: "100%", px: "25px" }}>
                    {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
                    <TableContainer className="bg-white px-4">
                        <Table
                            sx={{
                                minWidth: 750,
                                minHeight: 150,
                                borderCollapse: "separate",
                                border: "none",
                                backgroundColor: "white",
                            }}
                            aria-labelledby="tableTitle"
                        >
                            <CRMUploadTableHeader
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                onChangeSorting={updateSorting}
                            ></CRMUploadTableHeader>

                            <TableBody
                                className="relative"
                                sx={{
                                    "& .MuiTableCell-sizeMedium": {
                                        padding: "5px 8px",
                                        fontFamily: "Outfit",
                                        height: "fit-content",
                                        fontSize: 14,
                                        // font-family: Outfit;
                                    },
                                }}
                            >
                                {!formattedResult?.length && (
                                    <TableRow className="w-full ">
                                        <TableCell colSpan={5}>
                                            <Box className="flex w-full flex-col items-center justify-center">
                                                <span className="font-OutfitBold text-2xl">No uploads</span>
                                                <span className="font-Outfit text-md">
                                                    Select different profiles / bulk enrichment results
                                                </span>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {currentPageResults?.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <CRMUploadTableRow key={JSON.stringify(row) + index} row={row} labelId={labelId} tableWidth={5} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className="flex w-full justify-end rounded-b-[8px] bg-white py-2">
                        <Pagination
                            className="font-Outfit mt-2 "
                            count={Math.ceil(totalCount / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                            sx={{
                                "& .MuiPaginationItem-text": {
                                    fontFamily: "Outfit",
                                    fontSize: "14px",
                                },
                            }}
                        />
                    </div>
                </Box>
            </div>
        </div>
    );
}

export { CRMUploadTable };
