import React, { useEffect, useState } from "react";
import ProductURLInput from "../CustomInput";
import useAIEngagement from "@/hooks/useAIEngagement";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { isValidUrl } from "@/utils/common";

export default function CampaignPitch({ setSelectedStep, campaignId, editCampaignData }) {
    const [productUrls, setProductUrls] = useState([]);
    const [error, setError] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [loading, setLoading] = useState(false);
    const [forwarded, setForwarded] = useState(false);
    const [savedData, setSavedData] = useState({ productUrls: [], additionalInfo: "" });

    const { buildProductKnowledge } = useAIEngagement();

    // If the campaign is in edit mode, set the existing data
    useEffect(() => {
        if (editCampaignData) {
            // setProductUrls(editCampaignData.productUrls);
            setAdditionalInfo(editCampaignData.additionalInfo);
            setProductUrls(editCampaignData.websiteUrls || []);

            // check if urls and info are empty
            if (editCampaignData.websiteUrls?.length > 0) {
                setForwarded(true);
                setSavedData({ productUrls: editCampaignData.websiteUrls, additionalInfo: editCampaignData.additionalInfo });
            }
        }
    }, [editCampaignData]);

    const handleAddUrl = (newUrl) => {
        if (!isValidUrl(newUrl)) {
            setError("Invalid URL. Please enter a valid URL.");
            return;
        }
        // Check if the new URL is a duplicate
        if (productUrls.length >= 5) {
            setError("You can add up to 5 URLs only.");
        } else if (productUrls.includes(newUrl)) {
            setError("Duplicate URL is not allowed.");
        } else {
            setProductUrls([...productUrls, newUrl]);
            setError("");
        }
    };

    const handleRemoveUrl = (urlToRemove) => {
        setProductUrls(productUrls.filter((url) => url !== urlToRemove));
        setError("");
    };

    // send product data to the server
    const handleSubmit = async () => {
        if (productUrls.length == 0) {
            setError("Please add at least one product URL.");
            return;
        }

        // if no url and additional changed on coming back
        if (forwarded) {
            if (JSON.stringify(savedData.productUrls) === JSON.stringify(productUrls) && savedData.additionalInfo === additionalInfo) {
                setSelectedStep("3. Sequence");
                return;
            }
        }

        const productData = {
            campaignId: campaignId, // campaignId from the previous step
            urls: productUrls,
            additionalInfo,
        };
        setLoading(true);
        const response = await buildProductKnowledge(productData);

        if (response.status) {
            toast.success(response.message);
            setForwarded(true);
            setSavedData({ productUrls, additionalInfo });
            setSelectedStep("3. Sequence");
        } else {
            toast.error("Something went wrong. Please try with different links.");
        }
        setLoading(false);
    };

    return (
        <>
            <div className="campaign-card">
                <h2 className="font-medium">Tell us about the product you are selling</h2>

                <h2 className="font-medium mt-5">Product URLs</h2>
                <p className="text-sm mt-0.5">
                    Up to 5 URLs linking to information about the products, including product landing pages, media announcements, and key
                    blog posts.
                </p>

                <div className="campaign-card mt-3">
                    <ProductURLInput
                        id="product-urls"
                        selectedValues={productUrls}
                        onSelectionChange={(newUrl) => handleAddUrl(newUrl)}
                        onRemove={handleRemoveUrl}
                        placeholder="Enter product URL"
                        sx={{ width: "100%" }}
                    />
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                </div>

                <h2 className="font-medium mt-5">Additional Information</h2>
                <p className="text-sm mt-0.5">
                    Additional information not outlined by the the product URLs, such as specific pricing information and frequently asked
                    questions.
                </p>
                <textarea
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    value={additionalInfo}
                    className="campaign-card-input mt-3 resize-none"
                    rows="10"
                ></textarea>
            </div>

            <div className="flex justify-end">
                <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="mt-5 bg-blue-500 cursor-pointer hover:bg-blue-600 text-white text-sm py-2 px-16 rounded-md font-medium"
                >
                    {loading ? (
                        <div className="flex items-center gap-3">
                            <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Saving...</span>
                        </div>
                    ) : (
                        <span className="whitespace-nowrap">Next</span>
                    )}
                </button>
            </div>
        </>
    );
}
