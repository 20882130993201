import React, { useEffect, useContext } from "react";
import { AuthContext } from "@/context/AuthContext";
// import { ReactComponent as IsoGraphic } from "@/assets/image/isometric_login.svg";
import useKompassRouter from "@/hooks/useKompassRouter";
import isographic from "@/assets/image/isometric_login.png";
import logo1 from "@/assets/image/logos/costbo.png";
import logo2 from "@/assets/image/logos/union.png";
import logo3 from "@/assets/image/logos/contemplate.png";
import logo4 from "@/assets/image/logos/config.png";

function AuthLayout({ children }) {
    const authContext = useContext(AuthContext);
    const { kompassNavigate } = useKompassRouter();

    useEffect(() => {
        const func = async () => {
            if (await authContext.isSignIn()) {
                kompassNavigate("/home");
            }
        };

        func();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className="flex flex-col items-center md:grid md:grid-cols-2 md:items-start md:flex-row min-h-screen w-full text-gray-900">
            <div className="w-full bg-white">
                <div className="w-full mb-20 flex items-center justify-center px-[16px] md:px-[24px] lg:px-[36px] xl:px-[48px] 2xl:px-[72px]">
                    <div className="mt-20 w-full lg:max-w-[535px]">{children}</div>
                </div>
            </div>
            <div className="h-full w-full  bg-bluegrey-100">
                <div className="flex min-h-screen items-center justify-center">
                    <div className="w-8/12">
                        {/* <IsoGraphic className="mb-9 w-full" /> */}
                        <img className="mb-9 w-full" src={isographic} alt="IsoGraphic" />
                        <div className="font-OutfitLight text-center text-xl text-stone-600 mb-20">
                            Enabling sales <span className="font-OutfitMedium">to focus on selling </span>
                            instead of cleaning inaccurate data.
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="font-Outfit text-base font-semibold uppercase text-[#bbb] text-center mb-5">
                                Trusted by teams at
                            </p>
                            <div className="flex items-center justify-center gap-5">
                                <div className="shrink">
                                    <img src={logo1} alt="Costbo logo" width={100} />
                                </div>
                                <div className="shrink">
                                    <img src={logo2} alt="Union Digital logo" width={80} />
                                </div>
                                <div className="shrink">
                                    <img src={logo3} alt="Contemplate logo" width={180} />
                                </div>
                                <div className="shrink">
                                    <img src={logo4} alt="Config GPT logo" width={60} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default AuthLayout;
